const SITES_FOR_AGINOVA_MONITORS = (state, sitesForAginovaMonitors) => {
  state.sitesForAginovaMonitors = sitesForAginovaMonitors;
};

const SELECTED_SITE_AGINOVA_MONITORS = (
  state,
  selectedSiteWithAginovaMonitors
) => {
  state.selectedSiteWithAginovaMonitors = selectedSiteWithAginovaMonitors;
};

const AGINOVA_MONITORS_CURRENT_ROUTE = (state, aginovaMonitorsCurrentRoute) => {
  state.aginovaMonitorsCurrentRoute = aginovaMonitorsCurrentRoute;
};

const SELECTED_SITE_AGINOVA_MONITORS_IS_LOADING = (
  state,
  selectedSiteWithAginovaMonitorsIsLoading
) => {
  state.selectedSiteWithAginovaMonitorsIsLoading = selectedSiteWithAginovaMonitorsIsLoading;
};

const AGINOVA_MONITORS_SEARCH_TERM = (state, aginovaMonitorsSearchTerm) => {
  state.aginovaMonitorsSearchTerm = aginovaMonitorsSearchTerm;
};

const SELECTED_AGINOVA_MONITOR = (state, selectedAginovaMonitor) => {
  state.selectedAginovaMonitor = selectedAginovaMonitor;
};

const SELECTED_AGINOVA_MONITOR_IS_LOADING = (
  state,
  selectedAginovaMonitorIsLoading
) => {
  state.selectedAginovaMonitorIsLoading = selectedAginovaMonitorIsLoading;
};

const SITE_LOCATIONS_FOR_AGINOVA_MONITOR = (
  state,
  siteLocationsForAginovaMonitor
) => {
  state.siteLocationsForAginovaMonitor = siteLocationsForAginovaMonitor;
};

export default {
  SITES_FOR_AGINOVA_MONITORS,
  SELECTED_SITE_AGINOVA_MONITORS,
  AGINOVA_MONITORS_CURRENT_ROUTE,
  SELECTED_SITE_AGINOVA_MONITORS_IS_LOADING,
  AGINOVA_MONITORS_SEARCH_TERM,
  SELECTED_AGINOVA_MONITOR,
  SELECTED_AGINOVA_MONITOR_IS_LOADING,
  SITE_LOCATIONS_FOR_AGINOVA_MONITOR,
};
