const SITES_WITH_NON_ROUTINE_EVENTS = (
  state,
  sitesWithAnalyticsNonRoutineEvents
) => {
  state.sitesWithAnalyticsNonRoutineEvents = sitesWithAnalyticsNonRoutineEvents;
};

const SELECTED_SITE_NON_ROUTINE_EVENTS = (
  state,
  selectedSiteWithAnalyticsNonRoutineEvents
) => {
  state.selectedSiteWithAnalyticsNonRoutineEvents = selectedSiteWithAnalyticsNonRoutineEvents;
};

const NON_ROUTINE_EVENTS_CURRENT_ROUTE = (
  state,
  nonRoutineEventCurrentRoute
) => {
  state.nonRoutineEventCurrentRoute = nonRoutineEventCurrentRoute;
};

const SELECTED_SITE_NON_ROUTINE_EVENTS_IS_LOADING = (
  state,
  selectedSiteWithAnalyticsNonRoutineEventsIsLoading
) => {
  state.selectedSiteWithAnalyticsNonRoutineEventsIsLoading = selectedSiteWithAnalyticsNonRoutineEventsIsLoading;
};

const ANALYTICS_NON_ROUTINE_EVENTS_SEARCH_TERM = (
  state,
  analyticsNREsSearchTerm
) => {
  state.analyticsNREsSearchTerm = analyticsNREsSearchTerm;
};

export default {
  SITES_WITH_NON_ROUTINE_EVENTS,
  SELECTED_SITE_NON_ROUTINE_EVENTS,
  NON_ROUTINE_EVENTS_CURRENT_ROUTE,
  SELECTED_SITE_NON_ROUTINE_EVENTS_IS_LOADING,
  ANALYTICS_NON_ROUTINE_EVENTS_SEARCH_TERM,
};
