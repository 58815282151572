const apiVersion = 'v1'

const getSitesWithROIPredictions = async () => {
  try {
    // Using this endpoint for now until a new one is created for ROIPredictions
    const result = await window.axios.get(
      `/${apiVersion}/viewsupport/portfolio/sitedata`
    )
    const { data } = result.data
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

const getROIPredictionsBySite = async ({ siteId }) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/viewsupport/RoiPrediction/${siteId}`
    )
    const { data } = result
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

const getROIPredictionBySiteBySummaryId = async (summaryId) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/viewsupport/RoiPrediction/details/${summaryId}`
    )
    const { data } = result
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export default {
  getSitesWithROIPredictions,
  getROIPredictionsBySite,
  getROIPredictionBySiteBySummaryId,
}
