import API from '@/Core.Service.Domain/MessageCenter/Users/API';

const setUnreadMessagesCount = async ({ commit }) => {
  try {
    const unreadMessagesCount = await API.getMessageCenterUnreadCount();
    commit('SET_UNREAD_MESSAGES_COUNT', unreadMessagesCount);
  } catch (err) {
    return Promise.reject(err);
  }
};

const setMessagesByUser = async ({ commit }) => {
  try {
    const messages = await API.getMessagesByUserFromMessageCenter();
    commit('SET_MESSAGES_BY_USER', messages);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  setUnreadMessagesCount,
  setMessagesByUser,
};
