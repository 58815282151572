import { createSiteContactMethodResourceModel } from "./createSiteContactMethodResourceModel";

export class createSiteContactResourceModel {
  constructor(siteContactName = '', siteContactTitle = '', siteContactNotificationFrequencyId = 1, createSiteContactMethodResourceModels = [new createSiteContactMethodResourceModel]) {
    this.siteContactName = siteContactName;
    this.siteContactTitle = siteContactTitle;
    this.siteContactNotificationFrequencyId = siteContactNotificationFrequencyId
    this.createSiteContactMethodResourceModels = createSiteContactMethodResourceModels;
  }
}

