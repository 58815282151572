<template>
  <v-card>
    <BannerAlertVue v-if="!messagesList.length" :message="bannerMessage" />
    <div v-else>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Messages"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="messagesList" :search="search">
        <!--Headers slot-->
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="(header, i) in props.headers"
              :key="i"
              :class="header.class"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <!--Items slot-->
        <template v-slot:item="{ item }">
          <tr>
            <!--Type slot-->
            <td>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2" color="primary">
                    {{ getMessageTypeIcon(item) }}
                  </v-icon>
                </template>
                <span>
                  {{ getMessageTypeLabel(item) }} -
                  {{ item.messageCenterMessageId }}
                </span>
              </v-tooltip>
            </td>
            <!--Users slot-->
            <td>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2" color="primary">
                    {{
                      item.userAssociationCount > 0
                        ? `mdi-account-group`
                        : `mdi-account-off`
                    }}
                  </v-icon>
                </template>
                <span>{{
                  item.userAssociationCount > 0
                    ? `Users assigned - ${item.userAssociationCount}`
                    : 'No users assigned'
                }}</span>
              </v-tooltip>
            </td>
            <!--Title slot-->
            <td>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="isCurrentRouteEdit"
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="editMessage(item)"
                  >
                    <div class="crop">
                      <span class="primary--text text-subtitle-2 text-left">
                        {{ item.messageCenterMessageLabel || '-' }}
                      </span>
                    </div>
                  </v-btn>
                </template>
                <span> Edit this message </span>
              </v-tooltip>
            </td>
            <td>
              <span class="primary--text text-subtitle-2 text-left">
                {{
                  dateTimeToUSFormatFactory(item.lastModifiedDateTime, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                }}
              </span>
            </td>
            <td>
              <ActionButtonsVue
                :buttonsList="actionButtons"
                :item="item"
                :outlined="item.isRead"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert';

import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';

import API from '@/Core.Service.Domain/MessageCenter/Management/API';

export default {
  name: 'MessageCenterSentMessagesList',

  computed: {
    ...mapGetters({
      messagesList: 'MessageCenterManagementStore/getMessagesList',
      getMessageToEdit: 'MessageCenterManagementStore/getMessageToEdit',
      messagesTypesList: 'MessageCenterManagementStore/getMessagesTypesList',
    }),

    isCurrentRouteEdit() {
      return this.$route.name === 'MessageCenterEdit';
    },
  },

  components: {
    BannerAlertVue,
    ActionButtonsVue,
  },

  data(instance) {
    return {
      search: '',
      headers: [
        {
          text: 'Type',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          filterable: true,
          value: 'messageCenterMessageTypeId',
        },
        {
          text: 'Users',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          filterable: true,
          value: 'userAssociationCount',
        },
        {
          text: 'Title',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold pl-8',
          filterable: true,
          value: 'messageCenterMessageLabel',
        },
        {
          text: 'Created At',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          filterable: true,
          value: 'lastModifiedDateTime',
        },
        {
          text: 'Delete',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          filterable: false,
          value: '',
        },
      ],
      bannerMessage: 'No messages found',

      actionButtons: [
        {
          icon: () => 'trash-can-outline',
          toolTip: () => 'Delete message',
          action: async (item) => {
            instance.itemIsLoading = item.id;
            await instance.deleteMessage(item);
            instance.itemIsLoading = null;
          },
          loading: (item) =>
            item.userMessageCenterMessageId === instance.itemIsLoading,
          disabled: (item) => item.userAssociationCount > 0,
        },
      ],
      itemIsLoading: null,
    };
  },

  methods: {
    ...mapActions({
      editMessageFromMessageCenter:
        'MessageCenterManagementStore/editMessageFromMessageCenter',
      getAllMessagesFromMessageCenter:
        'MessageCenterManagementStore/getAllMessagesFromMessageCenter',
    }),

    dateTimeToUSFormatFactory,

    async editMessage(message) {
      const id = message.messageCenterMessageId;
      try {
        await this.editMessageFromMessageCenter(id);
        this.$router.push({
          name: 'MessageCenterEdit',
          params: { id },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async deleteMessage(message) {
      const id = message.messageCenterMessageId;
      try {
        await API.deleteMessage(id);
        await this.getAllMessagesFromMessageCenter();
      } catch (error) {
        console.error(error);
      }
    },

    getMessageTypeIcon(message) {
      const iconsList = {
        1: 'mdi-text-box-outline',
        2: 'mdi-window-restore',
        3: 'mdi-image-area',
      };
      return iconsList[message.messageCenterMessageTypeId];
    },

    getMessageTypeLabel(message) {
      const label = this.messagesTypesList.find(
        (type) =>
          type.messageCenterMessageTypeId === message.messageCenterMessageTypeId
      );

      return label ? label.messageCenterMessageTypeName : '-';
    },
  },
};
</script>
