<template>
  <PopUpMessageOverlay
    v-if="getNonExpiredAndUnreadMessages.length"
    :messagesByUserList="getNonExpiredAndUnreadMessages"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PopUpMessageOverlay from '@/Core.Service.Domain/MessageCenter/Users/Components/PopUpMessageOverlay.vue';

export default {
  name: 'MessageCenterEntryPoint',

  components: {
    PopUpMessageOverlay,
  },

  computed: {
    ...mapGetters({
      messagesByUserList: 'MessageCenterUsersStore/getMessagesByUser',
      isAuthenticated: 'session/isAuthenticated',
    }),

    getNonExpiredAndUnreadMessages() {
      return this.messagesByUserList.filter(
        (item) => !item.isExpired && !item.isRead
      );
    },
  },

  async mounted() {
    if (this.isAuthenticated) {
      setInterval(() => {
        this.setUnreadMessagesCount();
      }, 60000);
      await this.setUnreadMessagesCount();
      await this.getMessagesByUser();
    }
  },

  watch: {
    $route(v) {
      if (v.name !== 'MessageCenterMyMessages') {
        this.getMessagesByUser();
      }
    },
    messagesByUserList() {
      this.setUnreadMessagesCount();
    },
    messagesCount() {
      this.setUnreadMessagesCount();
    },
  },

  methods: {
    ...mapActions({
      setUnreadMessagesCount: 'MessageCenterUsersStore/setUnreadMessagesCount',
      getMessagesByUser: 'MessageCenterUsersStore/setMessagesByUser',
    }),
  },
};
</script>
