import Portfolio from '@/Core.Service.Domain/ViewSupport/Portfolio/Views'

const routesList = []

const PortfolioRoute = {
  path: '/portfolio',
  name: 'Portfolio',
  component: Portfolio,
  children: [
    {
      path: 'view-site-details/:siteId',
      name: 'ViewSiteDetails',
    },
    {
      path: 'view-site-notifications/:siteId',
      name: 'ViewSiteNotifications',
    },
  ],
  meta: { permission: 'portfolios:read' },
}

routesList.push(PortfolioRoute)

export default routesList
