<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="strategies-bar d-flex flex-row"
    class="strategies-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <v-btn
        v-if="userCanUpdate"
        class="rounded-pill mr-3"
        color="primary"
        depressed
        large
        @click="handleAddStrategy"
      >
        <div class="subtitle-2 white--text text-truncate font-weight-bold">
          New Strategy
        </div>
      </v-btn>
      <v-toolbar dense :width="toolBarWidth">
        <v-autocomplete
          dense
          :items="sitesWithStrategies"
          :item-text="item => item.name"
          v-model="selectedSite"
          class="mt-6 mr-3"
          return-object
          @change="setDefaultSiteFromStrategiesDomain"
        >
          <template v-slot:label>
            <span class="primary--text text-caption font-weight-bold">
              Site
            </span>
          </template>
        </v-autocomplete>
        <v-text-field
          v-model="searchTerm"
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "StrategiesBar",

  data() {
    return {
      searchTerm: "",
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null
    };
  },

  async created() {
    await this.getSitesWithStrategies();
    this.setDefaultSiteFromStrategiesDomain(this.defaultSite);
  },

  beforeDestroy() {
    this.setStrategyCurrentRoute("");
  },

  computed: {
    ...mapGetters({
      sitesWithStrategies:
        "StrategiesStore/StrategiesStore/sitesWithStrategies",
      defaultSite: "session/defaultSite"
    }),

    placeholder() {
      return this.$route.meta.placeholder || "Search for a Strategy";
    },

    userCanUpdate() {
      return this.$can("update", "controls");
    }
  },

  watch: {
    $route() {
      this.setDefaultSiteFromStrategiesDomain(this.defaultSite);
      this.clearSearchTerm();
    }
  },

  methods: {
    ...mapActions({
      getSitesWithStrategies:
        "StrategiesStore/StrategiesStore/getSitesWithStrategies",
      getStrategiesBySiteId:
        "StrategiesStore/StrategiesStore/getStrategiesBySiteId",
      setStrategyCurrentRoute:
        "StrategiesStore/StrategiesStore/setStrategyCurrentRoute",
      setDefaultSite: "session/setDefaultSite"
    }),

    ...mapMutations({
      setStrategySearchTerm:
        "StrategiesStore/StrategiesStore/STRATEGY_SEARCH_TERM"
    }),

    setDefaultSiteFromStrategiesDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesWithStrategies[0];

      this.getStrategiesBySiteId({
        selectedSite: this.selectedSite
      }).then(() => {
        this.setStrategyCurrentRoute(this.$route.name);
        this.setDefaultSite(this.selectedSite);
        this.clearSearchTerm();
      });
    },

    handleAddStrategy() {
      if (!this.userCanUpdate) return;
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({
        name: "StrategiesCreate",
        params: { siteId: this.selectedSite.siteId || this.selectedSite.id }
      });
    },

    handleSearchInput(value) {
      this.setStrategySearchTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = "";
      this.setStrategySearchTerm("");
    }
  }
};
</script>
<style lang="sass" scoped>
.strategies-bar
  position: fixed
  top: 100px
  z-index: 1
</style>