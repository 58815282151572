import embed from '../embedReports/IAQEmbed.js';
import slicer from '../slicerReports/IAQSlicer.js';

//Dashboard
//Site Slicer - vwSite - SiteName
//Monitor Name Slicer - vwSensorNames - MonitorFriendlyName
//Overtime
//Date Slicer - vwReportingDateTime - Date

const embedAndSlice = async function(container, embedConfiguration) {
    var pbiEmbed = await embed.getEmbed(container, embedConfiguration);
    try {
      var visuals = await getVisuals(pbiEmbed);
      // Retrieve the target visuals.
      await slicer.setSlicers(embedConfiguration.reportParameters, visuals);
    } catch (error) {
      console.error(error);
    }
  },
  getVisuals = async function(pbiEmbed) {
    var pages = await pbiEmbed.getPages();
    // Retrieve the active page.
    var page = pages.filter(function(page) {
      return page.isActive;
    })[0];

    return await page.getVisuals();
  };

export default {
  embedAndSlice,
};
