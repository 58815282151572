<template>
  <div>
    <BannerAlertVue
      v-if="!strategyTemplatesList.length"
      :message="bannerMessage"
    />
    <StrategyTemplatesListVue
      v-else
      :StrategyTemplatesList="strategyTemplatesList"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StrategyTemplatesListVue from "@/Core.Service.Domain/Strategies/StrategyTemplates/Components/StrategyTemplatesList";
import BannerAlertVue from "@/Core.UI.Domain/Components/BannerAlert.vue";

export default {
  name: "StrategiesTemplatesPortfolio",
  components: {
    StrategyTemplatesListVue,
    BannerAlertVue
  },
  computed: {
    ...mapGetters({
      strategyTemplatesList:
        "StrategiesStore/StrategyTemplatesStore/strategyTemplatesList"
    }),
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    }
  }
};
</script>