<template>
  <v-app class="app">
    <LateralMenuVue />
    <AppBarVue />
    <MessageCenterEntryPoint />
    <v-main class="mt-6" :class="{ 'ml-14': !smallBreakpoint }">
      <v-container fluid class="main-container">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LateralMenuVue from '@/Core.UI.Domain/Components/LateralMenu.vue';
import AppBarVue from '@/Core.UI.Domain/Components/AppBar.vue';

import MessageCenterEntryPoint from '@/Core.Service.Domain/MessageCenter/MessageCenter.Common/Components/MessageCenterEntryPoint.vue';

export default {
  name: 'E360App',

  components: {
    AppBarVue,
    LateralMenuVue,
    MessageCenterEntryPoint,
  },

  watch: {
    $route() {
      localStorage.removeItem('controlAnchorPosition');
    },
  },
};
</script>
<style lang="sass">
.app
  background-color: $aliceBlue !important

.main-container
  height: 99%
  width: 100%
</style>
