const sitesWithStrategies = (state) => state.sitesWithStrategies;
const selectedSiteStrategies = (state) => state.selectedSiteStrategies;
const strategyCurrentRoute = (state) => state.strategyCurrentRoute;
const selectedSiteStrategiesIsLoading = (state) =>
  state.selectedSiteStrategiesIsLoading;

const getStrategySearchTerm = (state) => state.strategySearchTerm;

export default {
  sitesWithStrategies,
  selectedSiteStrategies,
  strategyCurrentRoute,
  selectedSiteStrategiesIsLoading,
  getStrategySearchTerm,
};
