<template>
  <validation-observer ref="obs" v-slot="{ invalid }">
    <v-container grid-list-xl>
      <h1>Create Project</h1>
      <form @submit.prevent="submit">
        <v-sheet elevation="2" class="pa-4">
          <!-- <validation-summary :messages="server_errors" /> -->
          <validated-text-field
            rules="required|duplicate_name"
            v-model="project.projectName"
            label="Name"
          ></validated-text-field>
          <validated-text-field
            rules="required"
            v-model="project.description"
            label="Description"
          ></validated-text-field>
          <validated-select-field
            rules="required"
            :items="analyticModels"
            item-text="modelName"
            v-model="selectedModel"
            label="Associated Model"
            hint="Select an analysis model for this project"
            persistent-hint
            return-object
            @change-value="setDefaultDateRange"
          ></validated-select-field>
          <v-row>
            <v-col cols="auto" md="6" sm="12">
              <v-autocomplete
                :items="measureOptions"
                item-text="name"
                item-value="id"
                label="Select Measure(s)"
                multiple
                persistent-hint
                hint="You can start typing to search for Measure(s)"
                ref="measureSelect"
                return-object
                @input="handleSetMeasures"
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
          <start-end-date-picker
            name="date range"
            v-model="dateRange"
            date-format="yyyy-MM-dd"
            rules="required|valid_date_range"
          ></start-end-date-picker>
          <v-row>
            <label class="pa-3">Comments</label>
            <v-textarea
              rows="2"
              clearable
              clear-icon="mdi-close-circle"
              outlined
              auto-grow
              v-model="project.comment"
              class="pr-3"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="invalid"
                >Save</v-btn
              >
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </form>
      <span class="px-4 text-caption primary--text">
        {{ $appOldVersion }}
      </span>
    </v-container>
  </validation-observer>
</template>

<script>
import StartEndDatePicker from "@/components/Fields/StartEndDatePicker";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import { ValidationObserver, Validator } from "vee-validate";
import api from "../../projects/_api";

export default {
  components: {
    "start-end-date-picker": StartEndDatePicker,
    "validation-observer": ValidationObserver,
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField
  },

  data() {
    return {
      currentMode: {},
      dateRange: {},
      project: {
        projectName: "",
        description: "",
        analyticModel: {},
        startDateTime: "",
        endDateTime: "",
        site: {},
        measures: []
      },
      projectName: "",
      analyticModels: [],
      measureOptions: [],

      siteId: null,
      selectedModel: {},
      loading: false
    };
  },

  created() {
    this.siteId = this.$route.params.siteId;
    // this.getProjects();
    this.getModels();
    this.getMeasures();
    this.registerCustomValidators();
  },

  async mounted() {
    this.dateRange = {
      startDateTime: new Date().toISOString().substring(0, 10),
      endDateTime: new Date().toISOString().substring(0, 10)
    };
    this.clearErrors();
  },

  methods: {
    clearErrors() {
      requestAnimationFrame(() => {
        this.$refs.obs.reset();
      });
    },

    //MODEL - START
    async getModels() {
      let self = this;
      //let siteId = self.selectedSite.siteId || self.selectedSite; //don't ask...
      api
        .getAnalyticModels(self.siteId)
        .then(response => {
          self.analyticModels = response;
        })
        .catch(error => {
          console.error(error);
        });
    },

    mapModelLookup(models) {
      const makeOptionItems = item => {
        return {
          value: item.id,
          text: item.name
        };
      };
      let ml = models.map(makeOptionItems);
      return ml;
    },

    setSelectedModel(id) {
      let cm = {};
      try {
        cm = this.analyticModels.find(m => {
          return m.id === id;
        });
      } catch (error) {
        console.error(error);
      }
      if (cm) {
        cm.startDateTime = new Date(cm.startDateTime)
          .toISOString()
          .substring(0, 10);
        cm.endDateTime = new Date(cm.endDateTime)
          .toISOString()
          .substring(0, 10);
        this.selectedModel = cm;
      }
    },
    //MODEL - END

    //PROJECT - START
    async getProjects() {
      try {
        let response = await api.getProjects(this.siteId);
        this.projects = response;
        console.log(this.projects);
        this.clearErrors();
      } catch (error) {
        console.log("here =>", error);
      }
    },

    mapProject(proj) {
      return {
        id: proj.id,
        projectName: proj.projectName,
        description: proj.description,
        analyticModel: proj.analyticModel,
        startDateTime: new Date(proj.startDateTime)
          .toISOString()
          .substring(0, 10),
        endDateTime: new Date(proj.endDateTime).toISOString().substring(0, 10),
        comment: proj.comment,
        siteId: proj.site.id
      };
    },
    //PROJECT - END

    //MEASURES - START
    async getMeasures() {
      let self = this;
      api
        .getMeasures()
        .then(response => {
          self.measureOptions = this.mapMeasures(response);
        })
        .catch(error => {
          console.error(error);
        });
    },

    mapMeasures(measures) {
      return measures.map(item => {
        return {
          id: item.id,
          name: item.name
        };
      });
    },

    handleSetMeasures(selectedMeasures) {
      let self = this;
      self.project.measures = selectedMeasures.map(x => {
        return { ...x, selected: true };
      });
    },
    //MEASURES - END

    async submit() {
      this.project.projectStartDateTime = this.dateRange.startDateTime;
      this.project.projectEndDateTime = this.dateRange.endDateTime;
      this.project.siteId = this.siteId;
      this.project.modelName = this.selectedModel.modelName;
      this.project.modelId = this.selectedModel.id;

      this.create();
    },

    async create() {
      let self = this;
      let valid = await this.$refs.obs.validate();
      if (valid) {
        await api.createAnalyticProject(self.project);
        this.$toast.show("Project created.", null, "success");
        this.$router.push({ name: "AnalyticsMandVProjectIndex" });
      }
    },

    registerCustomValidators() {
      let self = this;
      Validator.extend("duplicate_name", {
        // Custom validation message
        getMessage: field =>
          `${field} is already used by another project.  Enter a different name.`,
        // Custom validation rule
        validate: value =>
          new Promise(resolve => {
            let valid = true;
            if (self.projects) {
              let existingNames = self.projects.map(a => a.projectName);
              valid = existingNames.indexOf(value) === -1;
            }

            resolve({
              valid: valid
            });
          })
      });
    },

    setDefaultDateRange(id) {
      if (this.loading !== true) {
        this.setSelectedModel(id);
      }
    },

    handleCancelCrud() {
      this.$router.push({ name: "AnalyticsMandVProjectIndex" });
    }
  }
};
</script>