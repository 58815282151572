import actions from '@/Core.Service.Domain/Analytics/Projects/Store/actions.js';
import getters from '@/Core.Service.Domain/Analytics/Projects/Store/getters.js';
import mutations from '@/Core.Service.Domain/Analytics/Projects/Store/mutations.js';

const state = {
  analyticsProjectsCurrentRoute: '',
  sitesWithAnalyticsProjects: [],
  selectedSiteAnalyticsProjects: [],
  selectedSiteAnalyticsProjectsIsLoading: true,
  analyticsProjectSearchTerm: '',
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
