import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
import API from '@/Core.Service.Domain/Monitors/Piera/API';

const getPieraMonitorsBySiteId = async (context, { selectedSite }) => {
  const { id } = RemapObjectKeysAdapter(selectedSite, ['siteId'], ['id']);

  context.commit('SELECTED_SITE_PIERA_MONITORS_IS_LOADING', true);

  const selectedSitePieraMonitors = await API.getPieraMonitorsBySiteId(id);

  context.commit('SELECTED_SITE_PIERA_MONITORS', selectedSitePieraMonitors);
  context.commit('SELECTED_SITE_PIERA_MONITORS_IS_LOADING', false);
};

const getSitesForPiera = async (context) => {
  const sitesForPieraMonitors = await API.getSitesForPiera();
  context.commit('SITES_FOR_PIERA_MONITORS', sitesForPieraMonitors);
};

const pieraMonitorByDeviceId = async (context, deviceId) => {
  context.commit('SELECTED_PIERA_MONITOR_IS_LOADING', true);

  try {
    const pieraMonitor = await API.getPieraMonitorByDeviceId(deviceId);
    context.commit('SELECTED_PIERA_MONITOR', pieraMonitor);
    return pieraMonitor;
  } finally {
    context.commit('SELECTED_PIERA_MONITOR_IS_LOADING', false);
  }
};

const getSiteLocationsForPieraMonitor = async (context, siteId) => {
  context.commit('SELECTED_PIERA_MONITOR_IS_LOADING', true);

  const siteLocationsForPieraMonitor = await API.getSiteLocationsForPieraMonitor(
    siteId
  );
  context.commit(
    'SITE_LOCATIONS_FOR_PIERA_MONITOR',
    siteLocationsForPieraMonitor
  );
  context.commit('SELECTED_PIERA_MONITOR_IS_LOADING', false);
};

export default {
  getPieraMonitorsBySiteId,
  getSitesForPiera,
  pieraMonitorByDeviceId,
  getSiteLocationsForPieraMonitor,
};
