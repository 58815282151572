<template>
  <v-card>
    <v-col v-if="isLoading">
      <ProgressLinearLoaderVue :isLoading="isLoading" />
    </v-col>
    <v-data-table
      :headers="headers"
      :items="notificationsList"
      :search="searchTerm"
      v-else
    >
      <!--Headers slot-->
      <template v-slot:headers="props">
        <tr>
          <th
            v-for="(header, i) in props.headers"
            :key="i"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr :style="`opacity:${item.isRead ? '0.5' : '1'}`">
          <!--Type slot-->
          <td>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  style="cursor: pointer"
                  :color="handleNotificationType(item).color"
                >
                  {{ handleNotificationType(item).icon }}
                </v-icon>
              </template>
              <span>
                Notification type: {{ handleNotificationType(item).type }}
              </span>
            </v-tooltip>
          </td>
          <!--Notification slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.notificationValue || '-' }}
            </span>
          </td>
          <!--Created At slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.createdDateTimeOffset || '-' }}
            </span>
          </td>
          <!--Read slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :item="item"
              :outlined="item.isRead"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons'
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue'

import API from '@/Core.Service.Domain/ViewSupport/Notifications/API'

export default {
  name: 'NotificationsList',

  components: {
    ActionButtonsVue,
    ProgressLinearLoaderVue,
  },

  computed: {
    ...mapGetters({
      searchTerm:
        'ViewSupportStore/NotificationsStore/getNotificationsSearchTerm',
      defaultSite: 'session/defaultSite',
      isLoading:
        'ViewSupportStore/NotificationsStore/getNotificationsIsLoading',
    }),
  },

  watch: {
    defaultSite() {
      this.handleLoadNotifications()
    },
  },

  created() {
    this.handleLoadNotifications()
  },

  data(instance) {
    return {
      search: '',
      headers: [
        {
          text: 'Type',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'notificationType',
        },
        {
          text: 'Notification',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'notificationValue',
        },
        {
          text: 'Created At',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'createdDateTimeOffset',
        },
        {
          text: 'Read/Unread',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'isRead',
        },
      ],
      actionButtons: [
        {
          icon: (item) =>
            item.isRead ? 'email-outline' : 'email-open-outline',
          toolTip: (item) => (item.isRead ? 'Mark as Unread' : 'Mark as Read'),
          action: async (item) => {
            instance.itemIsLoading = item.id
            await instance.handleNotificationToggleRead(item)
            instance.itemIsLoading = null
          },
          loading: (item) => item.id === instance.itemIsLoading,
        },
      ],
      notificationsList: [],
      itemIsLoading: null,
    }
  },

  methods: {
    ...mapActions({
      updateNotification:
        'ViewSupportStore/NotificationsStore/updateNotification',
    }),
    ...mapMutations({
      notificationsIsLoading:
        'ViewSupportStore/NotificationsStore/NOTIFICATIONS_IS_LOADING',
    }),

    async handleLoadNotifications() {
      try {
        this.notificationsIsLoading(true)
        const { siteId } = this.defaultSite
        this.notificationsList = await API.getAllNotifications({
          siteId,
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.notificationsIsLoading(false)
      }
    },

    handleNotificationType(notification) {
      const types = [
        {
          type: 'Email',
          color: 'greenGraph',
          icon: 'mdi-email-check-outline',
        },
        {
          type: 'SMS',
          color: 'purple',
          icon: 'mdi-message-alert-outline',
        },
        {
          type: 'PowerControlSettingNotification',
          color: 'intechBlue',
          icon: 'mdi-power-settings',
        },
        {
          type: 'ThermostatSetting',
          color: 'warning',
          icon: 'mdi-thermometer',
        },
      ]
      const type = types.find(
        (type) => type.type === notification.notificationType
      )
      return type || { type: 'Unknown', color: 'primary', icon: 'mdi-help' }
    },

    async handleNotificationToggleRead(notification) {
      try {
        notification.isRead = !notification.isRead
        await this.updateNotification(notification)
        this.handleLoadNotifications()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.v-text-field::v-deep
  .v-text-field__slot > input,
  .v-select__selection
    color: $primary !important
</style>
