import optionalConfig from '../configuration/appsettings.local.json';

// Note: keep all keys needed in here even if empty
// DO NOT CHANGE FOR LOCAL DEV --
//    override in src/configuration/appsettings.local.json instead
const defaultConfig = {
  DYNO_CORE_API_URL: "https://localhost:5001",
  DYNO_ANALYTICS_API_URL: "https://localhost:6001",
  DYNO_SENSOR_API_URL: "https://localhost:7001",
  DYNO_DD_APPLICATION_ID: "no value needed in appsettings.local.json",
  DYNO_DD_CLIENT_ID: "no value needed in appsettings.local.json",
  DYNO_AUTH0_DOMAIN: "intech.auth0.com",
  DYNO_AUTH0_AUDIENCE: "https://api.energy360.com",
  DYNO_AUTH0_CLIENTID: "put actual value in appsettings.local.json"
};

class GlobalConfig {
  constructor() {
    this.config = {
      ...defaultConfig,
      ...optionalConfig,
    };
  }

  get() {

    return this.config;
  }
}

export const globalConfig = new GlobalConfig();
