//import { createSiteContactResourceModel } from "./createSiteContactResourceModel";

//import { createSiteStartStopTimeResourceModel } from "./createSiteStartStopTimeResourceModel"

export class createSiteResourceModel {

  constructor(name = '', city = '', address = '', streetNumber= '', street= '', state = '', postalCode = '', latitude = 0.0, longitude = 0.0, 
    timezoneId = null, marketContextId = null, floors = 0, squareFootage = 0, occupants = 0, year = 0, siteTypeId = 0,
    kWhCost = 0.00, thermCost = 0.00, startTime = '', endTime = '', weatherStationId = null,
    utilityCompanyIds = [], createSiteContactResourceModels = [], updatedAt = new Date()) {
    this.name = name,
      this.streetNumber = streetNumber,
      this.street = street,
      this.city = city,
      this.state = state,
      this.postalCode = postalCode,
      this.address = address
      this.latitude = latitude,
      this.longitude = longitude,
      this.timeZoneId = timezoneId,
      this.marketContextId = marketContextId,
      this.floors = floors,
      this.squareFootage = squareFootage,
      this.occupants = occupants,
      this.year = year,
      this.siteTypeId = siteTypeId,
      this.kwhCost = kWhCost,
      this.thermCost = thermCost,
      this.startTime = startTime,
      this.endTime = endTime,
      this.weatherStationId = weatherStationId,
      this.utilityCompanyIds = utilityCompanyIds,
      this.createSiteContactResourceModels = createSiteContactResourceModels,
      this.updatedAt = updatedAt
      this.siteStartStopTime = []
  }

}