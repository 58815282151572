  export const getSites = async () => {
    let result = null
    try {
      result = await window.axios.get('/v1/sites/portfolio')
      let sites = result.data.data;
      return sites;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  }
  
  export const getMeasures = async () => {
    let result = null
    try {
      result = await window.axios.get('/v1/measure')
      let measures = result.data.data;
      return measures;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  }
  
  export const getMeasureById = async (id) => {
    let result = null
    try {
      result = await window.axios.get('/v1/measure/' + id)
      let measure = result.data.data;
      return measure;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  }
  
  export const addMeasure = async (measure) => {
    let result = null;
    try {
      result = await window.axios.post("/v1/measure", measure);
      let newMeasure = result.data.data;
      return newMeasure;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };
  
  export const updateMeasure = async (measure) => {
    let result = null;
    try {
      result = await window.axios.put("/v1/measure/update", measure);
      let newMeasure = result.data.data;
      return newMeasure;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };
  
  export const deleteMeasure = async(measure) => {
    let result = null;
    var config = {
      data: { 
        measureId: measure.id,
      }
    };
    try {
      result = await window.axios.delete("/v1/measure/delete", config);
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };
  
  export const getMeasureTypes = async() => {
    let result = null;
    try {
      result = await window.axios.get("/v1/measure/measuretype");
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };
  
  export const getMeasureInstallTypes = async() => {
    let result = null;
    try {
      result = await window.axios.get("/v1/measure/installtype");
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };
  
  export const getMeasureMarketSectors = async() => {
    let result = null;
    try {
      result = await window.axios.get("/v1/measure/marketsector");
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };
  
  export default {
    getSites,
    getMeasures,
    getMeasureById,
    addMeasure,
    updateMeasure,
    deleteMeasure,
    getMeasureTypes,
    getMeasureInstallTypes,
    getMeasureMarketSectors
  }