<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <component :is="currentComponent" />
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModelBar from "@/Core.Service.Domain/Analytics/Models/Components/ModelBar";
import MeasureBar from "@/Core.Service.Domain/Analytics/Measures/Components/MeasureBar";
import ProjectBar from "@/Core.Service.Domain/Analytics/Projects/Components/ProjectBar";
import NonRoutineEventBar from "@/Core.Service.Domain/Analytics/NonRoutineEvents/Components/NonRoutineEventBar";

export default {
  name: "AnalyticsView",

  components: {
    AnalyticsModel: ModelBar,
    AnalyticsMeasure: MeasureBar,
    AnalyticsProject: ProjectBar,
    AnalyticsNonRoutineEvent: NonRoutineEventBar
  },

  data(instance) {
    return {
      currentComponent: instance.$route.meta.analyticsTypeName
    };
  },

  watch: {
    $route() {
      this.currentComponent = this.$route.meta.analyticsTypeName;
    }
  }
};
</script>