const SITES_WITH_CONTROLS = (state, sitesWithControls) => {
  state.sitesWithControls = sitesWithControls;
};

const SELECTED_SITE_CONTROLS = (state, selectedSiteControls) => {
  state.selectedSiteControls = selectedSiteControls;
};

const CONTROL_CURRENT_ROUTE = (state, controlCurrentRoute) => {
  state.controlCurrentRoute = controlCurrentRoute;
};

const CONTROL_SEARCH_TERM = (state, controlSearchTerm) => {
  state.controlSearchTerm = controlSearchTerm;
};

const CONTROL_SETTINGS_HISTORY_TIMER_ON = (
  state,
  controlSettingsHistoryMessagesTimerOn
) => {
  state.controlSettingsHistoryMessagesTimerOn = controlSettingsHistoryMessagesTimerOn;
};

export default {
  SITES_WITH_CONTROLS,
  SELECTED_SITE_CONTROLS,
  CONTROL_CURRENT_ROUTE,
  CONTROL_SEARCH_TERM,
  CONTROL_SETTINGS_HISTORY_TIMER_ON,
};
