<template>
  <v-container fluid class="ml-n6 mt-n11">
    <v-row>
      <v-col>
        <StrategyTemplatesBar class="ml-3 mt-1" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col v-else>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StrategyTemplatesBar from "@/Core.Service.Domain/Strategies/StrategyTemplates/Components/StrategyTemplatesBar.vue";

import ProgressLinearLoaderVue from "@/Core.UI.Domain/Components/ProgressLinearLoader.vue";

import { mapActions } from "vuex";

export default {
  name: "StrategyTemplatesPortfolio",

  components: {
    StrategyTemplatesBar,
    ProgressLinearLoaderVue
  },

  computed: {
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    }
  },

  data() {
    return {
      isLoading: true
    };
  },

  async created() {
    try {
      await this.getStrategyTemplates();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      getStrategyTemplates:
        "StrategiesStore/StrategyTemplatesStore/getStrategyTemplates"
    })
  }
};
</script>