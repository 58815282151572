const SET_UNREAD_MESSAGES_COUNT = (state, unreadMessagesCount) => {
  state.unreadMessagesCount = unreadMessagesCount;
};

const SET_MESSAGES_BY_USER = (state, messages) => {
  state.messagesByUser = messages;
};

export default {
  SET_UNREAD_MESSAGES_COUNT,
  SET_MESSAGES_BY_USER,
};
