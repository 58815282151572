const AdminView = () => import('@/Core.Service.Domain/Admin/Views');

// Monitors Domain
import { MonitorsRoute } from '@/Core.Service.Domain/Monitors/Monitors.Common/Routes';

// These views should be refactored to be part of the new Piera and Aginova's Domains and should be under Monitors, as its children.
import {
  PieraLoginIndexRoute,
  PieraLoginCreateRoute,
  PieraLoginEditRoute,
} from '@/Core.Service.Domain/Monitors/Piera/Routes';

import {
  AginovaLoginIndexRoute,
  AginovaLoginCreateRoute,
  AginovaLoginEditRoute,
} from '@/Core.Service.Domain/Monitors/Aginova/Routes';
// End of Piera and Aginova Login routes.

import MessagesCenterRoutes from '@/Core.Service.Domain/MessageCenter/MessageCenter.Common/Routes';
import UsersRoutes from '@/Core.Service.Domain/MessageCenter/Users/Routes';

const AdminRoutes = {
  path: '/admin',
  name: 'Admin',
  children: [
    MonitorsRoute,
    PieraLoginIndexRoute,
    PieraLoginCreateRoute,
    PieraLoginEditRoute,
    AginovaLoginIndexRoute,
    AginovaLoginCreateRoute,
    AginovaLoginEditRoute,
    ...MessagesCenterRoutes,
    ...UsersRoutes,
  ],
  component: AdminView,
};

export default [AdminRoutes];
