<template>
  <v-container fluid>
    <v-row>
      <v-col v-show="!isLoading">
        <ROIPredictionsBar
          @isLoading="setIsLoading($event)"
          :ROIPredictionsSites="ROIPredictionsSites"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col v-else>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@/Core.Service.Domain/Sites/ROIPredictions/API'
import { mapGetters, mapMutations } from 'vuex'

const ROIPredictionsBar = () =>
  import(
    '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionsBar.vue'
  )
const ProgressLinearLoaderVue = () =>
  import('@/Core.UI.Domain/Components/ProgressLinearLoader.vue')

export default {
  name: 'ROIPredictionsView',

  components: {
    ROIPredictionsBar,
    ProgressLinearLoaderVue,
  },

  computed: {
    ...mapGetters({
      isLoading: 'SitesStore/ROIPredictionsStore/roiPredictionsBySiteIsLoading',
    }),
  },

  created() {
    this.getSitesWithROIPredictions()
  },

  data() {
    return {
      ROIPredictionsSites: [],
    }
  },

  methods: {
    ...mapMutations({
      setIsLoading:
        'SitesStore/ROIPredictionsStore/ROI_PREDICTIONS_BY_SITE_ARE_LOADING',
    }),

    async getSitesWithROIPredictions() {
      try {
        this.setIsLoading(true)
        this.ROIPredictionsSites = await API.getSitesWithROIPredictions()
      } catch (error) {
        console.log(error)
      } finally {
        this.setIsLoading(false)
      }
    },
  },
}
</script>
