const SET_USERS_LIST_FOR_MESSAGE_CENTER = (state, usersList) => {
  state.usersList = usersList;
};

const SET_MESSAGES_FROM_MESSAGE_CENTER = (state, messages) => {
  state.messagesList = messages;
};

const SET_MESSAGE_TO_EDIT_FROM_MESSAGE_CENTER = (state, message) => {
  state.messageToEdit = message;
};

const SET_MESSAGES_TYPES_LIST = (state, messagesTypes) => {
  state.messagesTypesList = messagesTypes;
};

export default {
  SET_USERS_LIST_FOR_MESSAGE_CENTER,
  SET_MESSAGES_FROM_MESSAGE_CENTER,
  SET_MESSAGE_TO_EDIT_FROM_MESSAGE_CENTER,
  SET_MESSAGES_TYPES_LIST,
};
