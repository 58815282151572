import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
const apiVersion = 'v1';

const getControlsBySite = async (site) => {
  const { id } = RemapObjectKeysAdapter(site, ['siteId'], ['id']);

  try {
    const controlsResponse = await window.axios.get(
      `/${apiVersion}/controls/site`,
      { params: { siteId: id } }
    );
    if (controlsResponse && controlsResponse.data) {
      return controlsResponse.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getSitesWithControls = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/controls/sites`);

    const { data } = result.data;
    data.forEach((site) => {
      RemapObjectKeysAdapter(site, ['siteId'], ['id']);
    });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getSitesWithControls,
  getControlsBySite,
};
