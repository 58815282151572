<template>
  <v-container fluid class="ml-n6">
    <v-row>
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col v-else>
        <BannerAlertVue
          v-if="!aginovaMonitorsList.length && !isEditingMode"
          :message="bannerMessage"
        />
        <router-view v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgressLinearLoaderVue from "@/Core.UI.Domain/Components/ProgressLinearLoader";
import BannerAlertVue from "@/Core.UI.Domain/Components/BannerAlert";

import { mapGetters } from "vuex";

export default {
  name: "AginovaMonitorsPortfolio",

  components: {
    ProgressLinearLoaderVue,
    BannerAlertVue
  },

  computed: {
    ...mapGetters({
      aginovaMonitorsList:
        "MonitorsStore/AginovaMonitorsStore/selectedSiteWithAginovaMonitors",
      isLoading:
        "MonitorsStore/AginovaMonitorsStore/selectedSiteWithAginovaMonitorsIsLoading"
    }),

    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },

    isEditingMode() {
      return this.$route.name === "EditAginovaMonitor";
    }
  }
};
</script>