const SITES_FOR_PIERA_MONITORS = (state, sitesForPieraMonitors) => {
  state.sitesForPieraMonitors = sitesForPieraMonitors;
};

const SELECTED_SITE_PIERA_MONITORS = (state, selectedSiteWithPieraMonitors) => {
  state.selectedSiteWithPieraMonitors = selectedSiteWithPieraMonitors;
};

const PIERA_MONITORS_CURRENT_ROUTE = (state, pieraMonitorsCurrentRoute) => {
  state.pieraMonitorsCurrentRoute = pieraMonitorsCurrentRoute;
};

const SELECTED_SITE_PIERA_MONITORS_IS_LOADING = (
  state,
  selectedSiteWithPieraMonitorsIsLoading
) => {
  state.selectedSiteWithPieraMonitorsIsLoading = selectedSiteWithPieraMonitorsIsLoading;
};

const PIERA_MONITORS_SEARCH_TERM = (state, pieraMonitorsSearchTerm) => {
  state.pieraMonitorsSearchTerm = pieraMonitorsSearchTerm;
};

const SELECTED_PIERA_MONITOR = (state, selectedPieraMonitor) => {
  state.selectedPieraMonitor = selectedPieraMonitor;
};

const SELECTED_PIERA_MONITOR_IS_LOADING = (
  state,
  selectedPieraMonitorIsLoading
) => {
  state.selectedPieraMonitorIsLoading = selectedPieraMonitorIsLoading;
};

const SITE_LOCATIONS_FOR_PIERA_MONITOR = (
  state,
  siteLocationsForPieraMonitor
) => {
  state.siteLocationsForPieraMonitor = siteLocationsForPieraMonitor;
};

export default {
  SITES_FOR_PIERA_MONITORS,
  SELECTED_SITE_PIERA_MONITORS,
  PIERA_MONITORS_CURRENT_ROUTE,
  SELECTED_SITE_PIERA_MONITORS_IS_LOADING,
  PIERA_MONITORS_SEARCH_TERM,
  SELECTED_PIERA_MONITOR,
  SELECTED_PIERA_MONITOR_IS_LOADING,
  SITE_LOCATIONS_FOR_PIERA_MONITOR,
};
