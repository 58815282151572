import moment from "moment";

const STORE_KEY = "$_kiosk_pbi_embed";
const mutations = {
    pbiEmbed_Set(state, pbiEmbed) {
        let pbiEmbedList = refreshEmbedList(pbiEmbed);
        state.pbiEmbedList = pbiEmbedList;
        localStorage.setItem(STORE_KEY, JSON.stringify(state.pbiEmbedList));
    }
}

const actions = {
    setpbiEmbed: async (context, pbiEmbed) => {
        context.commit('pbiEmbed_Set', pbiEmbed)
    }
}

const getters = {
    pbiEmbedList: state => state.pbiEmbedList
}

const state = {
    pbiEmbedList: JSON.parse(localStorage.getItem(STORE_KEY)),
}

export default {
    namespaced: true,
    mutations: mutations,
    actions: actions,
    getters: getters,
    state: state
}

function refreshEmbedList(pbiEmbed) {
    console.log("refreshEmbedList: " + pbiEmbed)
    let list = []; 

    if (state.pbiEmbedList) {
        //list = state.pbiEmbedList;
        //filter out any stale entries
        if (!Array.isArray(state.pbiEmbedList)){
            list.push(pbiEmbed);
            state.pbiEmbedList = list;
        }   
        const now = moment().unix();
        let filteredList = state.pbiEmbedList.filter((item) => {
           return now - item.timestamp <= 1200 //20 minutes
        })

        //remove any duplicate
        list = filteredList.filter((item) => {
            console.log(item.reportId == pbiEmbed.reportId)
            return item.reportId != pbiEmbed.reportId
        })

    }

    list.push(pbiEmbed);
    return list;
}

