import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
const apiVersion = 'v1';

const getAnalyticsModelsBySiteId = async (site) => {
  const { id } = RemapObjectKeysAdapter(site, ['siteId'], ['id']);

  try {
    const analyticsModelsResponse = await window.axios.get(
      `/${apiVersion}/AnalyticModel/sites/${id}`
    );
    if (analyticsModelsResponse && analyticsModelsResponse.data) {
      return analyticsModelsResponse.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getSitesWithAnalyticsModels = async () => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/AnalyticModel/site/sites`
    );

    const { data } = result.data;
    data.forEach((site) => {
      RemapObjectKeysAdapter(site, ['siteId'], ['id']);
    });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteAnalyticsModel = async (id) => {
  try {
    const result = await window.axios.delete(
      `/${apiVersion}/AnalyticModel/${id}`
    );
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getSitesWithAnalyticsModels,
  getAnalyticsModelsBySiteId,
  deleteAnalyticsModel,
};
