const getPowerBiEmbedConfiguration = async (reportName, siteId) => {
  let response = null
  try {
    if (siteId !== undefined) {
      response = await window.axios.get(
        `/v1/viewsupport/portfolio/site/${siteId}/embedconfiguration/${reportName}`
      )
    } else {
      response = await window.axios.get(
        `/v1/viewsupport/report/embedconfiguration/${reportName}`
      )
    }

    let { token, report, reportParameters, dbReportName } = response.data.data
    let embedConfiguration = {
      type: 'report',
      tokenType: 1,
      accessToken: token.token,
      embedUrl: report.embedUrl,
      id: report.id,
      settings: {
        filterPaneEnabled: false,
        panes: {
          pageNavigation: {
            visible: false,
          },
        },
      },
      reportParameters: reportParameters,
      reportName: dbReportName,
    }
    return embedConfiguration
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

const getPowerBiReportParametersBySiteName = async (reportName, siteName) => {
  let response = null
  try {
    response = await window.axios.get(
      `/v1/viewsupport/report/site/${siteName}/ReportParameters/${reportName}`
    )
    let { reportParameters } = response.data.data
    return reportParameters
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export default {
  getPowerBiEmbedConfiguration,
  getPowerBiReportParametersBySiteName,
}
