import PowerControlsPortfolio from '@/Core.Service.Domain/Controls/PowerControls/Views';

export const PowerControlsRoute = {
  path: 'power-controls',
  name: 'PowerControls',
  component: PowerControlsPortfolio,
  children: [
    {
      path: 'history/:id',
      name: 'PowerControlHistory',
    },
  ],
  meta: {
    placeholder: 'Search for a Power Control',
    controlTypeName: 'PowerControl',
    label: 'Power Controls',
    toolTip: 'Power Control',
  },
};
