const apiVersion = 'v1';

const getReports = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/viewsupport/report`);

    const { data } = result.data;
    return data;
  } catch (err) {
    return err;
  }
};

export default {
  getReports,
};
