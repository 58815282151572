//Dashboard
//Site Slicer - vwSite - SiteName
//Monitor Name Slicer - vwSensorNames - MonitorFriendlyName

import * as powerbi from 'powerbi-client'
import slicer from '../slicerReports/IAQSlicer.js'
import { DateTime } from 'luxon'

const regex = /\bDate Slicer\b/gi

// Embed a Power BI report in the given HTML element with the given configurations
// Read more about how to embed a Power BI report in your application here: https://go.microsoft.com/fwlink/?linkid=2153590
const getEmbed = async (container, configuration) => {
    /*-----------------------------------------------------------------------------------+
    |    Don't change these values here: access token, embed URL and report ID.          | 
    |    To make changes to these values:                                                | 
    |    1. Save any other code changes to a text editor, as these will be lost.         |
    |    2. Select 'Start over' from the ribbon.                                         |
    |    3. Select a report or use an embed token.                                       |
    +-----------------------------------------------------------------------------------*/
    // Embed the report and display it within the div container.
    const pbi = new powerbi.service.Service(
      powerbi.factories.hpmFactory,
      powerbi.factories.wpmpFactory,
      powerbi.factories.routerFactory
    )

    const report = pbi.embed(container, configuration)

    // report.off removes all event handlers for a specific event
    report.off('loaded')

    // report.on will add an event handler
    report.on('loaded', () => {
      report.off('loaded')
    })

    // report.off removes all event handlers for a specific event
    report.off('error')

    report.on('error', (event) => {
      console.error('reportError =>', event.detail)
    })

    //report.off removes all event handlers for a specific event
    // clears the event handlers before starting the event listener
    report.off('dataSelected')

    // report.on start the event listener
    report.on('dataSelected', async (event) => {
      // store the events
      const data = event.detail
      // set the variables for the slicers that need to be adjusted
      const slicerThatChanged = 'Site Slicer'
      const slicersToReset = ['Monitor Name Slicer']

      // filter events for only slicers
      if (data.visual.type != 'slicer') return

      try {
        // persist new site through out tab changes
        if (
          data.visual.title === 'Site Slicer' &&
          data.dataPoints[0].identity[0].target.column == 'SiteName'
        ) {
          const newSite = data.dataPoints[0].identity[0].equals
          report.config.reportParameters.SiteName = newSite
        }
        // run function to reset slicers
        await resetSlicers(
          data.visual.name,
          slicerThatChanged,
          slicersToReset,
          report
        )
      } catch (errors) {
        console.error('dataSelected =>', errors)
      }
    })

    report.on('rendered', async () => {
      try {
        const pages = await report.getPages()
        // Retrieve the active page.
        const [pageWithSlicer] = pages.filter((page) => {
          return page.isActive
        })
        // Retrieve the visuals so we have access to page elements
        const visuals = await pageWithSlicer.getVisuals()
        // Retrieve the Date Slicer
        const [dateSlicerVisual] = visuals.filter((visual) => {
          return visual.type === 'slicer' && regex.test(visual.title)
        })
        // Retrieve current parameters of slicer
        if (!dateSlicerVisual) return
        const state = await dateSlicerVisual.getSlicerState()
        // Format the date slicers so that they are in utc time
        const newStartDate = DateTime.fromISO(
          state.filters[0].conditions[0].value
        ).setZone('utc')
        const newEndDate = DateTime.fromISO(
          state.filters[0].conditions[1].value
        ).setZone('utc')
        // Save the new start/end dates as part of the report parameters
        configuration.reportParameters.StartDate = newStartDate
        configuration.reportParameters.EndDate = newEndDate
      } catch (errors) {
        console.error('rendered =>', errors)
      }
    })

    report.on('pageChanged', async () => {
      try {
        const pages = await report.getPages()
        // Retrieve the active page.
        const [pageWithSlicer] = pages.filter((page) => {
          return page.isActive
        })

        const visuals = await pageWithSlicer.getVisuals()
        // set the slicers again for the new active page
        if (pageWithSlicer.displayName === 'Over Time') {
          await slicer.setOvertimeSlicer(
            configuration.reportParameters,
            visuals
          )
        } else {
          await slicer.setSlicers(configuration.reportParameters, visuals)
        }
      } catch (errors) {
        console.error('pageChanged =>', errors)
      }
    })

    return report
  },
  resetSlicers = async (
    changedSlicerName,
    slicerToListenToName,
    slicersToResetNames,
    report
  ) => {
    // get list of all slicers on active page
    const pageSlicers = await getSlicersForActivePage(report)

    // get the slicer visual and compare with the visual that fired the data selected event
    const [changingSlicer] = pageSlicers.filter(
      (s) => s.title === slicerToListenToName
    )
    if (changingSlicer.name != changedSlicerName) return

    // for each slicer to be reset reset them.
    const slicersToReset = pageSlicers.filter(
      (s) => slicersToResetNames.indexOf(s.title) != -1
    )

    slicersToReset.map(async (s) => {
      await s.setSlicerState({ filters: [] })
    })
  },
  // Select Run and select an element of a visualization.
  // For example, a bar in a bar chart. You should see an entry in the Log window.
  getSlicersForActivePage = async (report) => {
    // Retrieve the page collection and get the visuals for the active page.
    try {
      const pages = await report.getPages()
      // Retrieve the active page.
      const [pageWithSlicer] = pages.filter((page) => {
        return page.isActive
      })
      const visuals = await pageWithSlicer.getVisuals()
      // Retrieve the target visual.
      const slicers = visuals.filter((visual) => {
        if (visual.type === 'slicer') console.log('Visual: ', visual)
        return visual.type === 'slicer'
      })
      // Get the slicer state
      return slicers
    } catch (errors) {
      console.error('getSlicersForActivePage =>', errors)
      return []
    }
  }

export default {
  getEmbed,
}
