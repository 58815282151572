const sitesWithAnalyticsModels = (state) => state.sitesWithAnalyticsModels;
const selectedSiteAnalyticsModels = (state) =>
  state.selectedSiteAnalyticsModels;
const analyticsModelsCurrentRoute = (state) =>
  state.analyticsModelsCurrentRoute;
const selectedSiteAnalyticsModelsIsLoading = (state) =>
  state.selectedSiteAnalyticsModelsIsLoading;

const getAnalyticsModelsSearchTerm = (state) => state.analyticsModelsSearchTerm;

export default {
  sitesWithAnalyticsModels,
  selectedSiteAnalyticsModels,
  analyticsModelsCurrentRoute,
  selectedSiteAnalyticsModelsIsLoading,
  getAnalyticsModelsSearchTerm,
};
