import actions from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Store/actions';
import getters from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Store/getters';
import mutations from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Store/mutations';

const state = {
  nonRoutineEventCurrentRoute: '',
  sitesWithAnalyticsNonRoutineEvents: [],
  selectedSiteWithAnalyticsNonRoutineEvents: [],
  selectedSiteWithAnalyticsNonRoutineEventsIsLoading: true,
  analyticsNREsSearchTerm: '',
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
