import actions from '@/Core.Service.Domain/Controls/Controls.Common/Store/actions.js'
import getters from '@/Core.Service.Domain/Controls/Controls.Common/Store/getters.js'
import mutations from '@/Core.Service.Domain/Controls/Controls.Common/Store/mutations.js'

import ThermostatsStore from '@/Core.Service.Domain/Controls/Thermostats/Store'
import PowerControlsStore from '@/Core.Service.Domain/Controls/PowerControls/Store'

const state = {
  controlCurrentRoute: '',
  sitesWithControls: [],
  selectedSiteControls: [],
  controlSearchTerm: '',
  controlSettingsHistoryMessagesTimerOn: false,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    ThermostatsStore,
    PowerControlsStore,
  },
}
