//Thermostat Readings
    //Site Slicer
    //Date Slicer
    //Thermostat Slicer
    //C02 / Temp Slicer
//CO2 Exceptions
    //Site Slicer
    //Date Slicer
    //Thermostat Slicer
  import cons from "../constants";

const setSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals);
  await setThermostatSlicerVisual(reportParameters, visuals);
},
  
setSiteSlicerVisual = async function(reportParameters, visuals) {
  const siteSlicerFilter = {
      $schema: cons.schemaUrlAdv,
      target: {
      table: "vwSite",
      column: "SiteName",
      },
      operator: "In",
      values: [reportParameters.SiteName],
  };

  let siteSlicerVisual = visuals.filter(function (visual) {
      return visual.type === "slicer" && visual.title === "Site Slicer";
  })[0];

  if (siteSlicerVisual) {
      // Set the slicer state which contains the slicer filters.
      await siteSlicerVisual.setSlicerState({
          filters: [siteSlicerFilter],
      });
  }
},
  
setDateSlicerVisual = async function(reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: cons.schemaUrlAdv,
    target: {
      table: "vwReportingDateTime",
      column: "Date",
    },
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: reportParameters.StartDate,
      },
      {
        operator: "LessThanOrEqual",
        value: reportParameters.EndDate,
      },
    ],
  };

  let dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === "slicer" && visual.title === "Date Slicer";
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
    console.log("Date slicer was set.");
  }
},
  
setThermostatSlicerVisual = async function(reportParameters, visuals) {
  const thermostatSlicerFilter = {
    $schema: cons.schemaUrlTopN,
    target: {
      table: "vwThermostatUsageData",
      column: "ControlName"
    },
    operator: "Top",
    itemCount: 1,
    orderBy: {
        table: "vwThermostatUsageData",
        measure: "ControlName"
     },
    filterType: 5
  };

  let thermostatSlicerVisual = visuals.filter(function (visual) {
    return (
      visual.type === "slicer" && visual.title === "Thermostat Slicer"
    );
  })[0];

  if (thermostatSlicerVisual) {
    await thermostatSlicerVisual.setSlicerState({
      filters: [thermostatSlicerFilter],
    });
    console.log("Thermostat slicer was set.");
  }
}
  
export default {
  setSlicers
}