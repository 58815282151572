import API from '@/Core.Service.Domain/MessageCenter/Management/API';

const getUsersListForMessageCenter = async ({ commit }) => {
  try {
    const usersList = await API.getAllUsersForMessageCenter();
    commit('SET_USERS_LIST_FOR_MESSAGE_CENTER', usersList);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllMessagesFromMessageCenter = async ({ commit }) => {
  try {
    const messages = await API.getAllMessagesFromMessageCenter();
    commit('SET_MESSAGES_FROM_MESSAGE_CENTER', messages);
  } catch (err) {
    return Promise.reject(err);
  }
};

const editMessageFromMessageCenter = async ({ commit }, id) => {
  try {
    const message = await API.getMessageById(id);
    commit('SET_MESSAGE_TO_EDIT_FROM_MESSAGE_CENTER', message);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMessagesTypesList = async ({ commit }) => {
  try {
    const messagesTypes = await API.getMessagesTypesList();
    commit('SET_MESSAGES_TYPES_LIST', messagesTypes);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getUsersListForMessageCenter,
  getAllMessagesFromMessageCenter,
  editMessageFromMessageCenter,
  getMessagesTypesList,
};
