const apiVersion = 'v1';

const getSitesWithStrategies = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/strategy/site/sites`);

    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getStrategiesBySiteId = async (site) => {
  const { siteId } = site;
  const { id } = site;

  try {
    const strategy = await window.axios.get(
      `/${apiVersion}/strategy/site/${siteId || id}`
    );
    if (strategy && strategy.data) {
      const { data } = strategy.data;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteStrategy = async (strategy) => {
  const data = {
    siteId: strategy.siteId,
    name: strategy.name,
  };
  try {
    const result = await window.axios.delete(`/${apiVersion}/strategy/delete`, {
      data,
    });
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const toggleActivateStrategy = async (strategy) => {
  try {
    await window.axios.put(
      `/${apiVersion}/strategy/${strategy.name}/activate`,
      strategy
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getSitesWithStrategies,
  getStrategiesBySiteId,
  deleteStrategy,
  toggleActivateStrategy,
};
