const sitesWithAnalyticsNonRoutineEvents = (state) =>
  state.sitesWithAnalyticsNonRoutineEvents;
const selectedSiteWithAnalyticsNonRoutineEvents = (state) =>
  state.selectedSiteWithAnalyticsNonRoutineEvents;
const nonRoutineEventCurrentRoute = (state) =>
  state.nonRoutineEventCurrentRoute;
const selectedSiteWithAnalyticsNonRoutineEventsIsLoading = (state) =>
  state.selectedSiteWithAnalyticsNonRoutineEventsIsLoading;

const getAnalyticsNREsearchTerm = (state) => state.analyticsNREsSearchTerm;

export default {
  sitesWithAnalyticsNonRoutineEvents,
  selectedSiteWithAnalyticsNonRoutineEvents,
  nonRoutineEventCurrentRoute,
  selectedSiteWithAnalyticsNonRoutineEventsIsLoading,
  getAnalyticsNREsearchTerm,
};
