import Contact from '@/modules/contact/issues'

const routesList = [];

const ContactFormRoute = {
  path: '/contact_us',
  name: 'ContactForm',
  component: Contact,
};

routesList.push(ContactFormRoute);

export default routesList;