import embed from '../embedReports/dashboardUtilityDataEmbed.js'
import slicer from '../slicerReports/dashboardUtilityDataSlicer.js'

const embedAndSlice = async function (container, embedConfiguration) {
    const pbiEmbed = await embed.getEmbed(container, embedConfiguration)
    try {
      const visuals = await getVisuals(pbiEmbed)
      // Retrieve the target visuals.
      await slicer.setElectricitySlicers(
        embedConfiguration.reportParameters,
        visuals
      )
    } catch (error) {
      console.error(error)
    }
  },
  getVisuals = async function (pbiEmbed) {
    const pages = await pbiEmbed.getPages()
    // Retrieve the active page.
    const [page] = pages.filter(function (page) {
      return page.isActive
    })

    return await page.getVisuals()
  }

export default {
  embedAndSlice,
}
