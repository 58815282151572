<template>
  <v-container
    fluid
    class="ml-n6 mt-n11"
  >
    <v-row>
      <v-col>
        <StrategiesBar class="ml-3" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col v-else>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import StrategiesBar from
  '@/Core.Service.Domain/Strategies/Strategies/Components/StrategiesBar.vue';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';


export default {
  name: 'StrategiesPortfolio',

  components: {
    StrategiesBar,
    ProgressLinearLoaderVue,
  },

  computed: {
    ...mapGetters({
      isLoading: 'StrategiesStore/StrategiesStore/selectedSiteStrategiesIsLoading',
    }),
  },
};
</script>