import MessageCenter from '@/Core.Service.Domain/MessageCenter/MessageCenter.Common/Views';
import MessageCenterCreateMessage from '@/Core.Service.Domain/MessageCenter/Management/Components/MessageCenterCreateMessage.vue';
import MessageCenterEditMessage from '@/Core.Service.Domain/MessageCenter/Management/Components/MessageCenterEditMessage.vue';

import MessageCenterUsers from '@/Core.Service.Domain/MessageCenter/Users/Views';

const MessagesCenterRoutes = [
  {
    path: 'message-center',
    name: 'MessageCenter',
    component: MessageCenter,
    redirect: { name: 'MessageCenterCreate' },
    children: [
      {
        path: 'create',
        name: 'MessageCenterCreate',
        component: MessageCenterCreateMessage,
      },
      {
        path: 'edit-message/:id',
        name: 'MessageCenterEdit',
        component: MessageCenterEditMessage,
      },
      {
        path: 'my-messages',
        name: 'MessageCenterMyMessages',
      },
      {
        path: 'users',
        name: 'MessageCenterUsers',
        component: MessageCenterUsers,
      },
    ],
  },
];

export default MessagesCenterRoutes;
