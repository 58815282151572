<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="monitors-bar d-flex flex-row"
    class="monitors-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <!-- Select Site -->
      <v-toolbar dense :width="toolBarWidth">
        <v-autocomplete
          dense
          :items="sitesForAginovaMonitors"
          :item-text="item => item.name"
          v-model="selectedSite"
          class="mt-6 mr-3"
          return-object
          @change="setDefaultSiteFromMonitorsDomain"
        >
          <template v-slot:label>
            <span class="primary--text text-caption font-weight-bold">
              Site
            </span>
          </template>
        </v-autocomplete>
        <v-text-field
          v-model="searchTerm"
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "AginovaMonitorsBar",

  data() {
    return {
      searchTerm: "",
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null
    };
  },

  created() {
    this.getSitesForAginova();
    this.setDefaultSiteFromMonitorsDomain(this.defaultSite);
  },

  computed: {
    ...mapGetters({
      sitesForAginovaMonitors:
        "MonitorsStore/AginovaMonitorsStore/sitesForAginovaMonitors",
      defaultSite: "session/defaultSite"
    }),

    placeholder() {
      return this.$route.meta.placeholder || "Search for a Monitor";
    },

    userCanDelete() {
      return this.$can("delete", "meters");
    },
    userCanUpdate() {
      return this.$can("update", "meters");
    }
  },

  watch: {
    $route() {
      this.setDefaultSiteFromMonitorsDomain(this.defaultSite);
      this.clearSearchTerm();
    }
  },

  methods: {
    ...mapActions({
      getSitesForAginova:
        "MonitorsStore/AginovaMonitorsStore/getSitesForAginova",
      getAginovaMonitorsBySiteId:
        "MonitorsStore/AginovaMonitorsStore/getAginovaMonitorsBySiteId",
      setDefaultSite: "session/setDefaultSite"
    }),

    ...mapMutations({
      setAginovaMonitorsTerm:
        "MonitorsStore/AginovaMonitorsStore/AGINOVA_MONITORS_SEARCH_TERM"
    }),

    setDefaultSiteFromMonitorsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesForAginovaMonitors[0];

      this.getAginovaMonitorsBySiteId({
        selectedSite: this.selectedSite
      }).then(() => {
        this.setDefaultSite(this.selectedSite);
        this.clearSearchTerm();
      });
    },

    handleSearchInput(value) {
      this.setAginovaMonitorsTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = "";
      this.setAginovaMonitorsTerm("");
    }
  }
};
</script>
<style lang="sass" scoped>
  .monitors-bar
    position: fixed
    top: 100px
    z-index: 1

  .v-select::v-deep
    .v-select__slot > input
      color: $primary !important

  .v-list::v-deep
    .v-list-item__title
      color: $primary !important
</style>