// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.

const UtilityMeterIndexRoute = {
  path: '/utility_meters',
  name: 'UtilityMeterIndex',
  component: () =>
    import('@/modules/monitors/utility_meters/_components/Index'),
  meta: { permission: 'meters:read' },
};

const UtilityMeterCreateRoute = {
  path: '/utility_meters/create/:id',
  name: 'UtilityMeterCreate',
  component: () =>
    import('@/modules/monitors/utility_meters/_components/Create'),
  meta: { permission: 'meters:create' },
};

const UtilityMeterEditRoute = {
  path: '/utility_meters/edit/:id',
  name: 'UtilityMeterEdit',
  component: () => import('@/modules/monitors/utility_meters/_components/Edit'),
  meta: { permission: 'meters:update' },
};

//temp
const UtilityMeterEditRoute2 = {
  path: '/utility_meters/edit/site/:siteId/meter/:meterId',
  name: 'MeterEdit2',
  component: () => import('@/modules/monitors/utility_meters/_components/Edit'),
  meta: { permission: 'meters:update' },
};

export default [
  UtilityMeterIndexRoute,
  UtilityMeterCreateRoute,
  UtilityMeterEditRoute,
  UtilityMeterEditRoute2,
];
