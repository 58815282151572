import actions from '@/Core.Service.Domain/Monitors/Piera/Store/actions';
import getters from '@/Core.Service.Domain/Monitors/Piera/Store/getters';
import mutations from '@/Core.Service.Domain/Monitors/Piera/Store/mutations';

const state = {
  pieraMonitorsCurrentRoute: '',
  sitesForPieraMonitors: [],
  selectedSiteWithPieraMonitors: [],
  selectedSiteWithPieraMonitorsIsLoading: false,
  pieraMonitorsSearchTerm: '',
  selectedPieraMonitor: {},
  selectedPieraMonitorIsLoading: false,
  siteLocationsForPieraMonitor: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
