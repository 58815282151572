import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
import API from '@/Core.Service.Domain/Strategies/Strategies/API';

const getSitesWithStrategies = async (context) => {
  const sitesWithStrategies = await API.getSitesWithStrategies();
  context.commit('SITES_WITH_STRATEGIES', sitesWithStrategies);
};

const getStrategiesBySiteId = async (context, site) => {
  const { selectedSite } = site;

  const remappedSelectedSite = RemapObjectKeysAdapter(
    selectedSite,
    ['id'],
    ['siteId']
  );

  context.commit('SELECTED_SITE_STRATEGIES_IS_LOADING', true);

  const selectedSiteStrategies = await API.getStrategiesBySiteId(
    remappedSelectedSite
  );

  context.commit('SELECTED_SITE_STRATEGIES', selectedSiteStrategies);
  context.commit('SELECTED_SITE_STRATEGIES_IS_LOADING', false);
};

const setStrategyCurrentRoute = async (context, strategyCurrentRoute) => {
  context.commit('STRATEGY_CURRENT_ROUTE', strategyCurrentRoute);
};

export default {
  getSitesWithStrategies,
  getStrategiesBySiteId,
  setStrategyCurrentRoute,
};
