import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
const apiVersion = 'v1';

const getStrategyTemplates = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/StrategyTemplate`);

    const { data } = result.data;
    data.forEach((site) => {
      RemapObjectKeysAdapter(site, ['siteId'], ['id']);
    });
    return data;
  } catch (err) {
    return err;
  }
};

const deleteStrategyTemplate = async (template) => {
  const data = {
    id: template.id,
  };
  try {
    const result = await window.axios.delete(
      `/${apiVersion}/strategytemplate/delete`,
      { data }
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getStrategyTemplates,
  deleteStrategyTemplate,
};
