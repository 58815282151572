<template>
  <v-container fluid>
    <v-row
      v-for="row in Math.ceil(strategiesList.length / cardsPerRow)"
      :key="row"
    >
      <v-col
        v-for="strategy in strategiesList.slice((row - 1) * cardsPerRow, row * cardsPerRow)"
        :key="strategy.id"
      >
        <StrategyCardVue :StrategyInfo="strategy" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StrategyCardVue from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyCard';
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

export default {
  name: "StrategiesList",

  components: {
    StrategyCardVue,
  },

  props: {
    StrategiesList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'StrategiesStore/StrategiesStore/getStrategySearchTerm',
    }),

    strategiesList() {
      return this.filteredItems.length ? sortAlphabetically(this.filteredItems) : sortAlphabetically(this.StrategiesList);
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        [
          'description',
          'name',
          'executionFrequency',
        ],
        this.StrategiesList
      );
    },
  },

  data() {
    return {
      filteredItems: [],
      cardsPerRow: 3,
    };
  },
};
</script>

<style lang="sass" scoped>
.col
  flex-grow: 0
</style>
