const SITES_WITH_ANALYTICS_PROJECT = (state, sitesWithAnalyticsProjects) => {
  state.sitesWithAnalyticsProjects = sitesWithAnalyticsProjects;
};

const SELECTED_SITE_ANALYTICS_PROJECT = (
  state,
  selectedSiteAnalyticsProjects
) => {
  state.selectedSiteAnalyticsProjects = selectedSiteAnalyticsProjects;
};

const ANALYTICS_PROJECT_CURRENT_ROUTE = (
  state,
  analyticsProjectsCurrentRoute
) => {
  state.analyticsProjectsCurrentRoute = analyticsProjectsCurrentRoute;
};

const SELECTED_SITE_ANALYTICS_PROJECT_IS_LOADING = (
  state,
  selectedSiteAnalyticsProjectsIsLoading
) => {
  state.selectedSiteAnalyticsProjectsIsLoading = selectedSiteAnalyticsProjectsIsLoading;
};

const ANALYTICS_PROJECT_SEARCH_TERM = (state, analyticsProjectSearchTerm) => {
  state.analyticsProjectSearchTerm = analyticsProjectSearchTerm;
};

export default {
  SITES_WITH_ANALYTICS_PROJECT,
  SELECTED_SITE_ANALYTICS_PROJECT,
  ANALYTICS_PROJECT_CURRENT_ROUTE,
  SELECTED_SITE_ANALYTICS_PROJECT_IS_LOADING,
  ANALYTICS_PROJECT_SEARCH_TERM,
};
