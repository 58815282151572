const roiPredictionsBySite = (state) => state.roiPredictionsBySite

const roiPredictionBySiteBySummaryId = (state) =>
  state.roiPredictionBySiteBySummaryId

const roiPredictionsBySiteIsLoading = (state) =>
  state.roiPredictionsBySiteIsLoading

export default {
  roiPredictionsBySite,
  roiPredictionBySiteBySummaryId,
  roiPredictionsBySiteIsLoading,
}
