// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.
// Also all the components should be rewritten to use the new route system.
// All these routes will be divides into separate files and imported in this file as children.

import SitesView from '@/modules/site/_components/Index'
import SiteEditView from '@/modules/site/_components/Edit'
import SiteCreateView from '@/modules/site/_components/Create'

import ROIPredictionsView from '@/Core.Service.Domain/Sites/ROIPredictions/Views'
import ROIPredictionsList from '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionsList.vue'
import ROIPredictionSummary from '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionSummary.vue'

const routesList = []

// Sites routes
const SitesRoute = {
  path: '/sites',
  name: 'SitesRoute',
  component: SitesView,
  meta: { permission: 'sites:read' },
}

const ROIPredictions = {
  path: '/sites/roi-predictions',
  name: 'ROIPredictions',
  component: ROIPredictionsView,
  children: [
    {
      path: 'site/:siteId/all',
      name: 'ROIPredictionsList',
      component: ROIPredictionsList,
      meta: { visualAid: 'list' },
    },
    {
      path: 'site/:siteId/summary/:summaryId',
      name: 'ROIPredictionSummary',
      component: ROIPredictionSummary,
      meta: { visualAid: 'summary' },
    },
  ],
}

const SiteEditRoute = {
  path: '/site/edit/:id',
  name: 'SiteEditRoute',
  component: SiteEditView,
}

const SiteCreateRoute = {
  path: '/site/create',
  name: 'SiteCreateRoute',
  component: SiteCreateView,
}

// Meter Upload routes
const MeterUploadRoute = {
  path: '/site/:siteId/meter/:meterId/data/upload',
  name: 'MeterUpload',
  component: () => import('@/modules/data/_components/Upload'),
  meta: { permission: 'sites:read' },
}

// Manual Monitor End Use routes
const ManualMonitorEndUseIndexRoute = {
  path: '/site/enduses/manual-utility-monitor',
  name: 'ManualMonitorEndUseIndex',
  component: () =>
    import('@/modules/site/end_use/manual_monitor/_components/Index'),
  meta: { permission: 'sites:create' },
}

// Egauge End Use routes
const EgaugeEndUseIndexRoute = {
  path: '/site/enduses/egauge',
  name: 'EgaugeEndUseIndex',
  component: () => import('@/modules/site/end_use/egauge/_components/Index'),
  meta: { permission: 'sites:create' },
}

const EgaugeEndUseCreateRoute = {
  path: '/site/:siteId/enduse/create',
  name: 'EgaugeEndUseCreate',
  component: () => import('@/modules/site/end_use/egauge/_components/Create'),
  meta: { permission: 'sites:create' },
}

const EgaugeEndUseEditRoute = {
  path: '/site/:siteId/enduse/:enduseId/monitor/:monitorId/register/:registerId/edit',
  name: 'EgaugeEndUseEdit',
  component: () => import('@/modules/site/end_use/egauge/_components/Edit'),
  meta: { permission: 'sites:update' },
}

// Manual Monitor End Use routes
const ManualMonitorEndUseCreateRoute = {
  path: '/site/:siteId/enduse/manualmonitor/create',
  name: 'ManualMonitorEndUseCreate',
  component: () =>
    import('@/modules/site/end_use/manual_monitor/_components/Create'),
  meta: { permission: 'sites:create' },
}

const ManualMonitorEndUseEditRoute = {
  path: '/site/:siteId/enduse/manualmonitor/:enduseId/monitor/:monitorId/register/:registerId/edit',
  name: 'ManualMonitorEndUseEdit',
  component: () =>
    import('@/modules/site/end_use/manual_monitor/_components/Edit'),
  meta: { permission: 'sites:update' },
}

// Equipment routes
const EquipmentIndexRoute = {
  path: '/site/equipment',
  name: 'EquipmentIndex',
  component: () => import('@/modules/site/equipment/_components/Index'),
  meta: { permission: 'sites:create' },
}

const EquipmentCreateRoute = {
  path: '/site/:siteId/equipment/create',
  name: 'EquipmentCreate',
  component: () => import('@/modules/site/equipment/_components/Create'),
  meta: { permission: 'sites:create' },
}

const EquipmentEditRoute = {
  path: '/site/:siteId/equipment/:equipmentId/edit',
  name: 'EquipmentEdit',
  component: () => import('@/modules/site/equipment/_components/Edit'),
  meta: { permission: 'sites:update' },
}

// Site Location routes
const SiteLocationIndexRoute = {
  path: '/site/sitelocation',
  name: 'SiteLocationIndex',
  component: () => import('@/modules/site/site_location/_components/Index'),
  meta: { permission: 'meters:read' },
}

const SiteLocationCreateRoute = {
  path: '/site/sitelocation/create/:siteId',
  name: 'SiteLocationCreate',
  component: () => import('@/modules/site/site_location/_components/Create'),
  meta: { permission: 'meters:create' },
}

const SiteLocationEditRoute = {
  path: '/site/:siteId/sitelocation/edit/:siteLocationId',
  name: 'EgaugeSiteLocationEdit',
  component: () => import('@/modules/site/site_location/_components/Edit'),
  meta: { permission: 'meters:update' },
}

// Control Permissions routes
const ControlPermissionsIndexRoute = {
  path: '/administration/control_permissions',
  name: 'ControlPermissionsIndex',
  component: () =>
    import('@/modules/administration/control_permissions/_components/Index'),
  meta: { permission: 'meters:read' },
}

const ControlPermissionsCreateRoute = {
  path: '/site/:siteId/control_permissions/create',
  name: 'ControlPermissionsCreate',
  component: () =>
    import('@/modules/administration/control_permissions/_components/Create'),
  meta: { permission: 'meters:create' },
}

const ControlPermissionsEditRoute = {
  path: '/site/:siteId/control_permissions/:ControlPermissionId/edit',
  name: 'ControlPermissionsEdit',
  component: () =>
    import('@/modules/administration/control_permissions/_components/Index'),
  meta: { permission: 'meters:update' },
}

// Sensors routes
const SensorIndexRoute = {
  path: '/site/sensors',
  name: 'SensorIndex',
  component: () => import('@/modules/site/sensor/_components/Index'),
  meta: { permission: 'meters:read' },
}

const SensorCreateRoute = {
  path: '/site/:siteId/sensor/create',
  name: 'SensorCreate',
  component: () => import('@/modules/site/sensor/_components/Create'),
  meta: { permission: 'meters:create' },
}

const SensorEditRoute = {
  path: '/site/:siteId/monitor/:monitorId/register/:registerId/sensor/:sensorId/edit',
  name: 'SensorEdit',
  component: () => import('@/modules/site/sensor/_components/Edit'),
  meta: { permission: 'meters:update' },
}

routesList.push(
  SitesRoute,
  SiteEditRoute,
  SiteCreateRoute,
  MeterUploadRoute,
  ManualMonitorEndUseIndexRoute,
  EgaugeEndUseIndexRoute,
  EgaugeEndUseCreateRoute,
  EgaugeEndUseEditRoute,
  ManualMonitorEndUseCreateRoute,
  ManualMonitorEndUseEditRoute,
  EquipmentIndexRoute,
  EquipmentCreateRoute,
  EquipmentEditRoute,
  SiteLocationIndexRoute,
  SiteLocationCreateRoute,
  SiteLocationEditRoute,
  ControlPermissionsIndexRoute,
  ControlPermissionsCreateRoute,
  ControlPermissionsEditRoute,
  SensorIndexRoute,
  SensorCreateRoute,
  SensorEditRoute,
  ROIPredictions
)

export default routesList
