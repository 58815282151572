<template>
  <div class="text-left">
    <v-overlay class="message-overlay" :value="overlay" opacity="0.7">
      <v-carousel
        :continuous="false"
        :cycle="cycle"
        :show-arrows="true"
        hide-delimiters
        height="auto"
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
          <v-card
            class="mx-auto"
            :max-width="!smallBreakpoint ? 800 : 350"
            width="auto"
            color="primary"
            min-height="500"
            :max-height="!smallBreakpoint ? auto : 350"
          >
            <!-- <v-img
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200px"
            ></v-img> -->

            <v-card-title>
              {{ slide.title }}
            </v-card-title>

            <v-card-subtitle>
              {{ slide.date }}
            </v-card-subtitle>

            <div
              :style="
                smallBreakpoint ? 'min-height: 350px' : 'min-height: 400px'
              "
            >
              <v-divider />

              <v-card-text
                class="px-15 mt-5 text-justify"
                :class="{ 'message-overflow': smallBreakpoint }"
              >
                {{ slide.message }}
              </v-card-text>
            </div>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue-grey" class="ma-2 white--text">
                Mark as Read
                <v-icon right dark> mdi-email-open </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </v-overlay>
  </div>
</template>
<script>
// import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
export default {
  name: 'PopUpMessageTypeText',

  // components: {
  //   ActionButtonsVue,
  // },

  data: (instance) => ({
    itemIsLoading: null,
    actionButtons: [
      {
        icon: (item) => (item.isRead ? 'email-outline' : 'email-open'),
        toolTip: (item) => (item.isRead ? 'Mark as Unread' : 'Mark as Read'),
        action: async (item) => {
          instance.itemIsLoading = item.id;
          await instance.toggleMessageRead(item);
          instance.itemIsLoading = null;
        },
        loading: (item) =>
          item.userMessageCenterMessageId === instance.itemIsLoading,
      },
    ],
    items: [
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
      },
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
      },
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
      },
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
      },
    ],
    width: 1024,
    overlay: true,
    colors: [
      'green',
      'secondary',
      'yellow darken-4',
      'red lighten-2',
      'orange darken-1',
    ],
    cycle: false,
    slides: [
      {
        message: `Lorem Ipsum is simply dummy text of the printing and typesetting
industry. Lorem Ipsum has been the industry's standard dummy
text ever since the 1500s, when an unknown printer took a galley
of type and scrambled it to make a type specimen book. It has
survived not only five centuries, but also the leap into
electronic typesetting, remaining essentially unchanged. It was
popularised in the 1960s with the release of Letraset sheets
containing Lorem Ipsum passages, and more recently with desktop
publishing software like Aldus PageMaker including versions of
Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting
industry. Lorem Ipsum has been the industry's standard dummy
text ever since the 1500s, when an unknown printer took a galley
of type and scrambled it to make a type specimen book. It has
survived not only five centuries, but also the leap into
electronic typesetting, remaining essentially unchanged. It was
popularised in the 1960s with the release of Letraset sheets
containing Lorem Ipsum passages, and more recently with desktop
publishing software like Aldus PageMaker including versions of
Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting
industry. Lorem Ipsum has been the industry's standard dummy
text ever since the 1500s, when an unknown printer took a galley
of type and scrambled it to make a type specimen book. It has
survived not only five centuries, but also the leap into
electronic typesetting, remaining essentially unchanged. It was
popularised in the 1960s with the release of Letraset sheets
containing Lorem Ipsum passages, and more recently with desktop
publishing software like Aldus PageMaker including versions of
Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting
industry. Lorem Ipsum has been the industry's standard dummy
text ever since the 1500s, when an unknown printer took a galley
of type and scrambled it to make a type specimen book. It has
survived not only five centuries, but also the leap into
electronic typesetting, remaining essentially unchanged. It was
popularised in the 1960s with the release of Letraset sheets
containing Lorem Ipsum passages, and more recently with desktop
publishing software like Aldus PageMaker including versions of
Lorem Ipsum.`,
        title: 'Message Title Will be Here',
        date: '2020-01-01',
      },
      {
        message:
          'Lorem Ipsum is simply dummy text of the printing and typesetting typesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesettingtypesetting',
        title: 'Message Title Will be Here 2',
        date: '2020-10-01',
      },
      {
        message: `Lorem Ipsum is simply dummy text of the printing and typesetting`,
        title: 'Message Title Will be Here 3',
        date: '2020-10-05',
      },
      {
        message: `Lorem Ipsum is simply dummy text of the printing and typesetting`,
        title: 'Message Title Will be Here 4',
        date: '2020-14-05',
      },
    ],
  }),

  methods: {
    toggleMessageRead(item) {
      console.log(item);
    },
  },
};
</script>
<style lang="sass" scoped>
.message-overlay
  z-index: 9999 !important

.message-overflow
  height: 300px
  overflow-y: scroll
</style>
