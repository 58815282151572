import actions from '@/Core.UI.Domain/Store/actions.js';
import getters from '@/Core.UI.Domain/Store/getters.js';
import mutations from '@/Core.UI.Domain/Store/mutations.js';

const state = {
  isLateralMenuOpen: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
