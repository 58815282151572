const sitesWithAnalyticsProjects = (state) => state.sitesWithAnalyticsProjects;
const selectedSiteAnalyticsProjects = (state) =>
  state.selectedSiteAnalyticsProjects;
const analyticsProjectsCurrentRoute = (state) =>
  state.analyticsProjectsCurrentRoute;
const selectedSiteAnalyticsProjectsIsLoading = (state) =>
  state.selectedSiteAnalyticsProjectsIsLoading;
const getAnalyticsProjectSearchTerm = (state) =>
  state.analyticsProjectSearchTerm;

export default {
  sitesWithAnalyticsProjects,
  selectedSiteAnalyticsProjects,
  analyticsProjectsCurrentRoute,
  selectedSiteAnalyticsProjectsIsLoading,
  getAnalyticsProjectSearchTerm,
};
