<template>
  <ValidationObserver ref="obs_range">
    <v-row>
      <v-col cols="auto" md="6" sm="12">
        <ValidationProvider name="dateRange" :rules="rules" v-slot="{ errors, valid }">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="innerValue.startDateTime"
                :label="innerLabels.start"
                prepend-icon="event"
                readonly
                v-on="on"
                :outlined="true"
                :error-messages="errors"
                :success="valid"
                name="start date"
                ref="startPicker"
                v-bind="$attrs"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="innerValue.startDateTime"
              @input="startMenu = false"
              @change="changeHandler('startDateTime', $event)"
              @click:date="dateRangeSet($event)"
              name="start date"
              v-bind="$attrs"
            ></v-date-picker>
          </v-menu>
        </ValidationProvider>
      </v-col>

      <v-col cols="auto" md="6" sm="12">
        <ValidationProvider name="end date" :rules="rules" v-slot="{ errors, valid }">
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="innerValue.endDateTime"
                :label="innerLabels.end"
                prepend-icon="event"
                readonly
                v-on="on"
                :outlined="true"
                :error-messages="errors"
                :success="valid"
                name="end date"
                ref="endPicker"
                v-bind="$attrs"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="innerValue.endDateTime"
              @input="endMenu = false"
              @change="changeHandler('endDateTime', $event)"
              @click:date="dateRangeSet($event)"
              name="start date"
              v-bind="$attrs"
            ></v-date-picker>
          </v-menu>
        </ValidationProvider>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  Validator
} from "vee-validate";
//import moment from "moment";
import { DateTime } from "luxon";
import _ from "lodash";

const dateRangeFlagReset = function() {
  this.pendingChange = false;
};

export default {
 
  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: {
    rules: {
      type: [Object, String],
      default: "validDateRange"
    },
    value: {
      type: [Object, String],
      default: () => {
        return {
        startDateTime: DateTime.local(),
        endDateTime: DateTime.local()
      };
}
    },
    dateFormat: {
      type: String,
      default: "yyyy-MM-dd"
    },
    debounce: {
      type: Number,
      default: 100
    },
    labels: {
      type: [Object, String],
      default: () => {
        return {
          start: "Start Date",
          end: "End Date"
        };
      }
    }
  },

  computed: {
    dateRangeSet() {
      return _.debounce(dateRangeFlagReset, this.debounce).bind(this);
    }
  },

  data() {
    return {
      innerValue: {
        startDateTime: {},
        endDateTime: {}
      },
      startMenu: false,
      endMenu: false,
      innerDateFormat: "",
      innerLabels: {},
      pendingChange: false
    };
  },

  created() {
    this.registerCustomValidators();
    this.$validator.attach;
    if (this.value) {
      this.innerValue = this.value;
    }

    if (this.labels) {
      this.innerLabels = this.labels
    }

    if (this.dateFormat) {
      console.log(this.dateFormat);
      this.innerDateFormat = this.dateFormat;
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit("input", this.formatDates(newVal));
    },

    value(newVal) {
      if (newVal) {
        this.innerValue = this.formatDates(newVal);
      }
    },

    dateFormat(newVal) {
      if (newVal) {
        this.innerDateFormat = newVal;
      }
    },

    labels(newVal) {
      if (newVal) {
        this.innerLabels = newVal;
      }
    },

    pendingChange(newVal) {
      if (newVal === false) {
        console.log("emit change event");
        this.$emit("change", this.innerValue);
      }
    }
  },

  methods: {
    changeHandler(propName, e) {
      this.innerValue[propName] = DateTime.fromISO(e).toFormat(
        this.innerDateFormat
      );
      this.pendingChange = true;
    },

    defaultDates() {
      this.innerValue = {
        startDateTime: DateTime.fromFormat(
          DateTime.local(),
          this.innerDateFormat
        ),
        endDateTime: DateTime.fromFormat(DateTime.local(), this.innerDateFormat)
      };
    },

    formatDates(input) {
      if (input.startDateTime && input.endDateTime) {
        let sdt = DateTime.fromISO(input.startDateTime).toFormat(
          this.innerDateFormat
        );
        let edt = DateTime.fromISO(input.endDateTime).toFormat(
          this.innerDateFormat
        );
        input.startDateTime = sdt;
        input.endDateTime = edt;
      }

      return input;
    },

    registerCustomValidators() {
      let self = this;

      Validator.extend("valid_date_range", {
        // Custom validation message
        getMessage: () => `Start Date must be less than End Date.`,
        // Custom validation rule
        validate: () =>
          new Promise(resolve => {
            let dr_valid =
              new Date(self.innerValue.startDateTime) <
              new Date(self.innerValue.endDateTime);
            if (dr_valid === true && self.$refs.obs_range) {
              self.$refs.obs_range.reset();
            }
            resolve({
              valid: dr_valid
            });
          })
      });
    }
  }
};
</script>