<template>
  <section style="height: 100%">
    <div v-if="hasSubmit">
      <v-btn @click="getReport">Get Report</v-btn>
    </div>
    <div
      style="height: 100%"
      class="mt-4"
      id="reportContainer"
      ref="reportContainer"
    ></div>
  </section>
</template>

<style>
#reportContainer {
  width: 100%;
}

#reportContainer iframe {
  border: none;
}
</style>

<script>
import api from '../_api'
import powerBiReportfactory from '../_factories/powerBiReportFactory.js'

export default {
  name: 'PowerBIReport',
  props: {
    reportName: { type: String, required: true },
    siteId: { type: String, required: false },
    hasSubmit: { type: Boolean, default: false },
    maxHeight: { type: Boolean, default: true },
    height: { type: Number, default: -1 },
  },
  watch: {
    reportName: function (newVal) {
      if (!this.hasSubmit && newVal && newVal !== '') {
        this.getReport()
      }
    },
  },

  created() {
    this.getReport()
  },

  methods: {
    getReport: async function () {
      const embedConfiguration = await api.getPowerBiEmbedConfiguration(
        this.reportName,
        this.siteId
      )
      const container = this.$refs.reportContainer
      powerBiReportfactory.getPowerBiReportStrategy(
        this.reportName,
        container,
        embedConfiguration
      )
    },
  },
}
</script>
