import API from '@/Core.Service.Domain/Controls/PowerControls/API';

const setPowerControlsList = async (context, siteId) => {
  const powerControlsList = await API.getPowerControlLatestUsage({
    id: siteId,
  });
  context.commit('POWER_CONTROLS_LIST', powerControlsList);
};

export default {
  setPowerControlsList,
};
