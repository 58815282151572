import API from '@/Core.Service.Domain/Sites/ROIPredictions/API'

const getROIPredictionsBySite = async ({ commit }, siteId) => {
  const result = await API.getROIPredictionsBySite(siteId)
  const { data } = result
  commit('ROI_PREDICTIONS_BY_SITE', data)
}

const getROIPredictionBySiteBySummaryId = async ({ commit }, siteData) => {
  const { summaryId } = siteData
  const result = await API.getROIPredictionBySiteBySummaryId(summaryId)
  const { data } = result
  commit('ROI_PREDICTION_BY_SITE_BY_SUMMARY_ID', data)
}

export default {
  getROIPredictionsBySite,
  getROIPredictionBySiteBySummaryId,
}
