const getUsersList = (state) => state.usersList;

const getMessagesList = (state) => state.messagesList;

const getMessageToEdit = (state) => state.messageToEdit;

const getMessagesTypesList = (state) => state.messagesTypesList;

export default {
  getUsersList,
  getMessagesList,
  getMessageToEdit,
  getMessagesTypesList,
};
