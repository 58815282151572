/**
 * @param {Object} obj
 * @param {Array} keys
 * @param {Array} newKeys
 * @returns Object
 * @description Remap object keys replacing old keys with new keys
 * @example RemapObjectKeysAdapter({ a: 1, b: 2, c: 3 }, ['a', 'b', 'c'], ['x', 'y', 'z']);
 * returns { x: 1, y: 2, z: 3 }
 */
export const RemapObjectKeysAdapter = (obj, keys, newKeys) => {
  keys.forEach((key, index) => {
    if (obj[key] !== undefined) {
      obj[newKeys[index]] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};
