import AnalyticsView from '@/Core.Service.Domain/Analytics/Analytics.Common/Views';
import {
  ModelsRoute,
  ModelCreateRoute,
  ModelEditRoute,
} from '@/Core.Service.Domain/Analytics/Models/Routes';
import { MeasuresRoute } from '@/Core.Service.Domain/Analytics/Measures/Routes';
import { ProjectsRoute } from '@/Core.Service.Domain/Analytics/Projects/Routes';
import {
  NonRoutineEventsRoute,
  NRECreateRoute,
  NREEditRoute,
  NREEnergyAdjustmentChartRoute,
} from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Routes';
import {
  AnalyticsMandVProjectDetailsRoute,
  AnalyticsMandVProjectNewRoute,
  AnalyticsMandVProjectIndexRoute,
  AnalyticsMandVProjectMeasureIndexRoute,
  AnalyticsMandVProjectMeasureCostSavingsChartRoute,
  AnalyticsMandVProjectImpactReportRoute,
  AnalyticsMandVMeasuresIndexRoute,
  AnalyticsMandVMeasuresDetailsRoute,
  AnalyticsMandVMeasuresNewRoute,
  AnalyticsMandVProjectEventsDetailsRoute,
} from '@/Core.Service.Domain/Analytics/MandVs/Routes' 

const routesList = [];

const AnalyticsRoute = {
  path: '/analytics',
  name: 'Analytics',
  children: [
    ModelsRoute,
    ModelCreateRoute,
    ModelEditRoute,
    MeasuresRoute,
    ProjectsRoute,
    NonRoutineEventsRoute,
    NRECreateRoute,
    NREEditRoute,
    NREEnergyAdjustmentChartRoute,
    AnalyticsMandVProjectDetailsRoute,
    AnalyticsMandVProjectNewRoute,
    AnalyticsMandVProjectIndexRoute,
    AnalyticsMandVProjectMeasureIndexRoute,
    AnalyticsMandVProjectMeasureCostSavingsChartRoute,
    AnalyticsMandVProjectImpactReportRoute,
    AnalyticsMandVMeasuresIndexRoute,
    AnalyticsMandVMeasuresDetailsRoute,
    AnalyticsMandVMeasuresNewRoute,
    AnalyticsMandVProjectEventsDetailsRoute,
  ],
  component: AnalyticsView,
  meta: { permission: 'controls:read' },
};

routesList.push(AnalyticsRoute);

export default routesList;
