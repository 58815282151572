const sitesForAginovaMonitors = (state) => state.sitesForAginovaMonitors;
const selectedSiteWithAginovaMonitors = (state) =>
  state.selectedSiteWithAginovaMonitors;
const aginovaMonitorsCurrentRoute = (state) =>
  state.aginovaMonitorsCurrentRoute;
const selectedSiteWithAginovaMonitorsIsLoading = (state) =>
  state.selectedSiteWithAginovaMonitorsIsLoading;

const getAginovaMonitorsSearchTerm = (state) => state.aginovaMonitorsSearchTerm;

const getAginovaMonitorByDeviceId = (state) => state.selectedAginovaMonitor;

const selectedAginovaMonitorIsLoading = (state) =>
  state.selectedAginovaMonitorIsLoading;

const siteLocationsForAginovaMonitor = (state) =>
  state.siteLocationsForAginovaMonitor;

export default {
  sitesForAginovaMonitors,
  selectedSiteWithAginovaMonitors,
  aginovaMonitorsCurrentRoute,
  selectedSiteWithAginovaMonitorsIsLoading,
  getAginovaMonitorsSearchTerm,
  getAginovaMonitorByDeviceId,
  selectedAginovaMonitorIsLoading,
  siteLocationsForAginovaMonitor,
};
