<template>
  <v-layout align-center v-show="showDialog">
    <v-dialog
      v-model="showDialog"
      scrollable
      :persistent="!viewOnlyMode"
      light
      @input="close()"
      max-width="50vw"
    >
      <v-card v-if="currentTermsOfService">
        <v-card-title>{{ currentTermsOfService.name }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text
          v-bind:style="{ height: dialogHeight + 'px' }"
          v-html="currentTermsOfService.template"
        />

        <v-divider></v-divider>
        <v-card-actions
          v-if="!viewOnlyMode"
          style="flex-direction: row-reverse; align-self: flex-end"
        >
          <v-spacer></v-spacer>
          <v-btn
            autofocus
            color="primary"
            :disabled="!isAccepted"
            :loading="loading"
            text
            @click="acceptTerms"
            >I accept</v-btn
          >
          <v-checkbox
            v-model="isAccepted"
            label="Do you accept these terms of services?"
            :rules="[(v) => !!v || 'You must accept to continue']"
            required
            style="flex-grow: 0.02; margin-right: 15px"
          ></v-checkbox>
        </v-card-actions>
        <v-card-actions v-else>
          <span class="px-4 text-caption primary--text">
            {{ $appOldVersion }}
          </span>
          <v-spacer></v-spacer>
          <v-btn autofocus color="primary" text @click="close(false)"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TermsOfService',
  props: {
    termsOfService: Object,
    viewOnly: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
  },
  mounted() {
    window.onresize = () => {
      this.windowHeight = window.innerHeight;
    };

    if (this.isAuthenticated) {
      this.getAcceptedTermsOfService().then(() => {
        if (this.hasNewTermsOfServices) {
          this.open(this.newTermsOfServices, false);
        }
      });
    }
  },
  data() {
    return {
      loading: false,
      isAccepted: false,
      showDialog: false,
      viewOnlyMode: false,
      windowHeight: window.innerHeight,
      currentTermsOfService: null,
    };
  },
  computed: {
    ...mapGetters('session', [
      'isAuthenticated',
      'hasNewTermsOfServices',
      'newTermsOfServices',
    ]),
    dialogHeight: function () {
      return this.windowHeight - 150;
    },
  },
  watch: {
    viewOnly: function (newVal) {
      this.viewOnlyMode = newVal;
    },
    isAuthenticated: function (newVal) {
      if (newVal) {
        this.getAcceptedTermsOfService();
      }
    },
    hasNewTermsOfServices: function (newVal) {
      if (newVal) {
        this.open(this.newTermsOfServices, false);
      } else {
        this.loading = false;
        this.getAcceptedTermsOfService();
        this.close();
      }
    },
    show: function (newVal) {
      this.showDialog = newVal;
      this.viewOnlyMode = true;
    },
  },
  methods: {
    acceptTerms() {
      if (!this.viewOnly) {
        this.loading = true;
        this.$store.dispatch('session/acceptTermsOfServices', {
          termsOfServiceId: this.newTermsOfServices.id,
        });
      }
    },
    getAcceptedTermsOfService() {
      return this.axios.get('/v1/termsofservices/accepted').then((response) => {
        if (response && response.data && response.data.data) {
          this.currentTermsOfService = response.data.data;
        } else {
          this.currentTermsOfService = null;
        }

        this.$emit('onhastermsofservice', this.currentTermsOfService !== null);
      });
    },
    open(termsOfService, isViewOnly = false) {
      this.showDialog = true;
      this.viewOnlyMode = isViewOnly;
      this.currentTermsOfService = termsOfService;
    },
    close() {
      this.showDialog = false;
      this.isAccepted = false;
      this.viewOnlyMode = false;

      this.$emit('onclose');
    },
  },
};
</script>
