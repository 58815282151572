import StrategyTemplatesPortfolio from '@/Core.Service.Domain/Strategies/StrategyTemplates/Views';
import StrategyTemplatesListVue from '@/Core.Service.Domain/Strategies/StrategyTemplates/Views/StrategiesTemplatesListView.vue';

// These are old views. They should be refactored to be part of the new Strategies Domain.
const StrategyTemplatesCreateRoute = {
  path: 'create',
  name: 'StrategyTemplatesCreate',
  component: () => import('@/modules/strategytemplates/Create'),
  meta: { permission: 'strategies:create' },
};

const StrategyTemplatesEditRoute = {
  path: 'edit-strategy-template/:strategyTemplateId',
  name: 'StrategyTemplatesEdit',
  component: () => import('@/modules/strategytemplates/Edit'),
  meta: { permission: 'strategies:create' },
};
// End of old views.

const StrategyTemplatesListRoute = {
  path: 'all',
  name: 'StrategyTemplatesList',
  component: StrategyTemplatesListVue,
  meta: {
    label: 'Strategy Templates',
    toolTip: 'Strategy Template',
  },
};

export const StrategyTemplatesRoute = {
  path: 'strategy-templates',
  name: 'StrategyTemplates',
  component: StrategyTemplatesPortfolio,
  children: [
    StrategyTemplatesCreateRoute,
    StrategyTemplatesEditRoute,
    StrategyTemplatesListRoute,
  ],
  meta: {
    label: 'Strategy Templates',
    toolTip: 'Strategy Template',
  },
  redirect: { name: 'StrategyTemplatesList' },
};
