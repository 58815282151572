import {createStartStopTimeResourceModel} from "./createStartStopTimeResourceModel"
export class createSiteStartStopTimeResourceModel
 {

  constructor(siteId, dayOfWeekId, dayOfWeekName, isOpen) {
    this.siteId = siteId,
    this.dayOfWeekId = dayOfWeekId,
    this.dayOfWeekName = dayOfWeekName,
    this.isOpen = isOpen,
    this.startStopTime = new createStartStopTimeResourceModel('24:00', '24:00');
   }
}