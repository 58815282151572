<template>
  <v-container fluid class="mt-n3">
    <!-- <v-card class="pl-3"> -->
    <v-row v-if="pieraMonitorError || isLoading">
      <v-col>
        <ProgressLinearLoaderVue v-if="isLoading" :isLoading="isLoading" />
        <BannerAlertVue v-if="pieraMonitorError" :message="bannerMessage" />
      </v-col>
    </v-row>
    <v-row v-if="!pieraMonitorError && !isLoading">
      <v-col cols="5">
        <v-card class="pa-4">
          <!-- Piera Monitor Details -->
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="PieraMonitorStatus.deviceName"
              disabled
              outlined
            >
              <template v-slot:label>
                <span class="text--disabled text-caption font-weight-bold">
                  Device Name
                </span>
              </template>
            </v-text-field>

            <v-text-field
              v-model="PieraMonitorStatus.friendlyName"
              required
              :disabled="isSiteLocationEditMode || isSiteLocationAddMode"
              outlined
              :class="
                isSiteLocationEditMode || isSiteLocationAddMode
                  ? 'text--disabled'
                  : 'text--enabled'
              "
            >
              <template v-slot:label>
                <span
                  class="primary--text text-caption font-weight-bold"
                  :class="{
                    'text--disabled':
                      isSiteLocationEditMode || isSiteLocationAddMode
                  }"
                >
                  Friendly Name
                </span>
              </template>
            </v-text-field>
            <!-- Site Location Add/Edit -->
            <SiteLocationCard
              :MonitorStatus="PieraMonitorStatus"
              :isMonitorDirty="isPieraMonitorDirty"
              :siteLocation="siteLocation"
              :isSiteLocationEditMode="isSiteLocationEditMode"
              :isSiteLocationAddMode="isSiteLocationAddMode"
              :siteLocationsForMonitor="siteLocationsForPieraMonitor"
              @site-location-edit-mode="toggleEditMode"
              @site-location-add-mode="toggleAddMode"
              @cancel-site-location-edit-mode="handleCancelSiteLocationEditMode"
              @init="init"
            />
            <!-- Control Buttons -->
            <v-btn
              class="rounded-pill mr-2"
              color="primary"
              depressed
              @click="handleSave"
              :disabled="!isPieraMonitorDirty || isSiteLocationEditMode"
            >
              <div
                class="subtitle-2 white--text text-truncate font-weight-bold px-3"
              >
                Save
              </div>
            </v-btn>

            <v-btn
              class="rounded-pill"
              color="warning"
              depressed
              @click="handleCancelButtonClick"
              :disabled="isSiteLocationEditMode || isSiteLocationAddMode"
            >
              <div
                class="subtitle-2 white--text text-truncate font-weight-bold px-3"
              >
                {{ getCancelButtonText }}
              </div>
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
      <!-- Sensors List -->
      <v-col cols="7" v-if="sensorsList.length">
        <SensorsList :SensorsList="sensorsList" />
      </v-col>
      <v-col cols="8" v-else>
        <BannerAlertVue :message="sensorsListMessage" />
      </v-col>
    </v-row>
    <!-- </v-card> -->
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinearLoaderVue from "@/Core.UI.Domain/Components/ProgressLinearLoader";
import BannerAlertVue from "@/Core.UI.Domain/Components/BannerAlert";

import SensorsList from "@/Core.Service.Domain/Monitors/Piera/Components/SensorsList.vue";

import SiteLocationCard from "@/Core.Service.Domain/Monitors/Monitors.Common/Components/SiteLocationCard.vue";

import API from "@/Core.Service.Domain/Monitors/Piera/API";

export default {
  components: {
    ProgressLinearLoaderVue,
    BannerAlertVue,
    SensorsList,
    SiteLocationCard
  },

  computed: {
    ...mapGetters({
      getPieraMonitorByDeviceId:
        "MonitorsStore/PieraMonitorsStore/getPieraMonitorByDeviceId",
      siteLocationsForPieraMonitor:
        "MonitorsStore/PieraMonitorsStore/siteLocationsForPieraMonitor"
    }),

    bannerMessage() {
      return "There was an error loading this monitor";
    },

    sensorsListMessage() {
      return "There are no sensors for this monitor";
    },

    sensorsList() {
      return this.getPieraMonitorByDeviceId.sensors;
    },

    isPieraMonitorDirty() {
      return Object.values(this.isDirty).some(value => value);
    },

    getCancelButtonText() {
      if (
        this.isPieraMonitorDirty &&
        (!this.isSiteLocationEditMode || !this.isSiteLocationAddMode)
      ) {
        return "Cancel";
      } else {
        return "Go Back";
      }
    }
  },

  data: () => ({
    valid: true,
    pieraMonitorError: false,
    isLoading: false,
    PieraMonitorInfo: {},
    isSiteLocationEditMode: false,
    isSiteLocationAddMode: false,
    siteLocation: {},
    isDirty: {
      friendlyName: false,
      siteLocationName: false,
      siteLocationId: false
    },
    PieraMonitorStatus: {}
  }),

  watch: {
    "PieraMonitorStatus.friendlyName": function(newVal) {
      this.isDirty.friendlyName =
        `${newVal}` !== `${this.PieraMonitorInfo.friendlyName}`;
    },
    "PieraMonitorStatus.siteLocationId": function(newVal) {
      this.isDirty.siteLocationId =
        `${newVal}` !== `${this.PieraMonitorInfo.siteLocationId}`;
    },
    "siteLocation.siteLocationName": function(newVal) {
      this.isDirty.siteLocationName =
        `${newVal}` !== `${this.PieraMonitorInfo.siteLocationName}`;
    }
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions({
      pieraMonitorByDeviceId:
        "MonitorsStore/PieraMonitorsStore/pieraMonitorByDeviceId",
      getSiteLocationsForPieraMonitor:
        "MonitorsStore/PieraMonitorsStore/getSiteLocationsForPieraMonitor"
    }),

    async init() {
      try {
        this.isLoading = true;
        const pieraMonitor = await this.pieraMonitorByDeviceId(
          this.$route.params.id
        );

        const { siteId } = pieraMonitor;
        await this.getSiteLocationsForPieraMonitor(siteId);

        this.PieraMonitorInfo = {
          ...this.mapPieraMonitorBySiteLocationId(pieraMonitor)
        };

        this.clonePieraMonitorInfo();
        this.resetDirty();
      } catch (error) {
        this.pieraMonitorError = true;
      } finally {
        this.isLoading = false;
      }
    },

    resetDirty() {
      this.isDirty.friendlyName = false;
      this.isDirty.siteLocationName = false;
      this.isDirty.siteLocationId = false;
    },

    handleCancel() {
      this.clonePieraMonitorInfo();
    },

    handleGoBack() {
      this.$router.push({ name: "PieraMonitorsList" });
    },

    async handleSave() {
      if (!this.isPieraMonitorDirty) {
        return;
      }
      this.isLoading = true;
      try {
        await API.updatePieraMonitor(this.PieraMonitorStatus);
        this.init();
      } catch (error) {
        this.pieraMonitorError = true;
      } finally {
        this.isLoading = false;
      }
    },

    clonePieraMonitorInfo() {
      this.PieraMonitorStatus = { ...this.PieraMonitorInfo };
      this.siteLocation.siteLocationName = this.PieraMonitorInfo.siteLocationName;
    },

    mapPieraMonitorBySiteLocationId(pieraMonitor) {
      try {
        const siteLocation = this.siteLocationsForPieraMonitor.filter(
          siteLocation => {
            return siteLocation.id === pieraMonitor.siteLocationId;
          }
        );
        if (siteLocation.length) {
          this.siteLocation = siteLocation[0];
          pieraMonitor.siteLocationName = siteLocation[0].siteLocationName;
        }

        return pieraMonitor;
      } catch (error) {
        console.log("error =>", error);
      }
    },

    handleCancelSiteLocationEditMode() {
      this.isSiteLocationEditMode = false;
      this.isSiteLocationAddMode = false;
      this.clonePieraMonitorInfo();
    },

    toggleEditMode(value) {
      this.isSiteLocationEditMode = value;
    },
    toggleAddMode(value) {
      this.isSiteLocationAddMode = value;
    },

    handleCancelButtonClick() {
      if (this.isPieraMonitorDirty && !this.isSiteLocationEditMode) {
        this.handleCancel();
      } else {
        this.handleGoBack();
      }
    }
  }
};
</script>
<style lang="sass" scoped>
.text--enabled::v-deep
  input
    color: $primary !important
</style>