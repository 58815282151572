/**
 *
 * @param {*} array
 * @param {*} field
 * @returns sorted array
 * @description Sorts an array of objects alphabetically by a given field. Fields can also be nested.
 */
export const sortAlphabetically = (array, field = 'name') => {
  const keys = field.split('.')
  return array.sort((a, b) => {
    let aValue = a
    let bValue = b
    for (let key of keys) {
      aValue = aValue[key]
      bValue = bValue[key]
    }
    if (aValue < bValue) {
      return -1
    }
    if (aValue > bValue) {
      return 1
    }
    return 0
  })
}

/**
 *
 * @param {*} str
 * @returns string
 * @description Removes all words separated by dot from a string and returns the last word
 */

export const removeWordsSeparatedByDotAndReturnTheLast = (str) => {
  var words = str.split('.')
  words.splice(0, words.length - 1)
  var result = words.join('.')

  return result
}

/**
 * @param {String} dateStr
 * @returns Date
 * @description Parses a date string in the format of 'MM/DD/YYYY HH:MM AM/PM'
 * @example parseCustomDate('01/01/2020 5:00 PM');
 * returns Date object
 */
function parseCustomDate(dateStr) {
  const [datePart, timePart] = dateStr.split(' ')
  const [month, day, year] = datePart.split('/').map(Number)
  const [hourMin, period] = timePart.split(/([APM]+)/)
  let [hour, minute] = hourMin.split(':').map(Number)

  if (period === 'PM' && hour !== 12) {
    hour += 12
  } else if (period === 'AM' && hour === 12) {
    hour = 0
  }

  return new Date(year, month - 1, day, hour, minute)
}

/**
 * @param {Array} arr
 * @param {String} fieldName
 * @returns Array
 * @description Sorts an array of objects by a given field in ascending order. Fields can also be nested.
 * @example sortArrayByFieldAscending([{ name: 'b' }, { name: 'a' }], 'name');
 * returns [{ name: 'a' }, { name: 'b' }]
 */
export const sortArrayByFieldDescending = (arr, fieldName) => {
  return arr.sort((a, b) => {
    const dateA = parseCustomDate(a[fieldName])
    const dateB = parseCustomDate(b[fieldName])
    return dateB - dateA
  })
}
