import Vue from 'vue';
import Vuex from 'vuex';

import ApplicationModule from './modules/application';
import SessionModule from './modules/session';

import UIStore from '@/Core.UI.Domain/Store';

import ControlsStore from '@/Core.Service.Domain/Controls/Controls.Common/Store';
import StrategiesStore from '@/Core.Service.Domain/Strategies/Strategies.Common/Store';
import AnalyticsStore from '@/Core.Service.Domain/Analytics/Analytics.Common/Store';
import ViewSupportStore from '@/Core.Service.Domain/ViewSupport/ViewSupport.Common/Store';
import MonitorsStore from '@/Core.Service.Domain/Monitors/Monitors.Common/Store';

import SitesStore from '@/Core.Service.Domain/Sites/Sites.Common/Store';

import MessageCenterManagementStore from '@/Core.Service.Domain/MessageCenter/Management/Store';

import MessageCenterUsersStore from '@/Core.Service.Domain/MessageCenter/Users/Store';

import AdminStore from '@/Core.Service.Domain/Users/Store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AdminStore,
    ControlsStore,
    StrategiesStore,
    AnalyticsStore,
    ViewSupportStore,
    MonitorsStore,
    UIStore,
    SitesStore,
    MessageCenterManagementStore,
    MessageCenterUsersStore,
    application: ApplicationModule,
    session: SessionModule,
  },
});
