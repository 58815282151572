import API from '@/Core.Service.Domain/Analytics/Measures/API';

const getAnalyticsMeasures = async (context) => {
  const analyticsMeasures = await API.getAnalyticsMeasures();
  context.commit('ANALYTICS_MEASURES_LIST', analyticsMeasures);
};

export default {
  getAnalyticsMeasures,
};
