<template>
  <v-snackbar
      v-model="active"
      :top="y === 'top'"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :right="x === 'right'"
      :multi-line="multiLine"
      :timeout="timeout"
      :vertical="vertical"
      :color="color"
    >
      {{ message }}
      <v-btn
        color="pink"
        text
        @click="active = false"
      >
        Close
      </v-btn>
    </v-snackbar>
</template>

<script>
export default {
  props: {
    x: { type: String, default: 'right' },
    y: { type: String, default: 'bottom' },
    icon: { type: String },
    classes: { type: [ String, Object, Array ] },
    color: { type: String },
    message: { type: String },
    timeout: { type: Number, default: 6000 },
    dismissable: { type: Boolean, default: true },
    autoHeight: { type: Boolean, default: false },
    multiLine: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false }
  },

  data() {
    return {
      active: true
    }
  },

  watch: {
    active: function(isActive, wasActive) {
      this.$emit('onactivechanged', isActive, wasActive);
    }
  },

  methods: {
    show: function() { this.active = true; },
    close: function() { this.active = false; }
  }
}
</script>