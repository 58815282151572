import PortfolioStore from '@/Core.Service.Domain/ViewSupport/Portfolio/Store'
import NotificationsStore from '@/Core.Service.Domain/ViewSupport/Notifications/Store'
import ReportsStore from '@/Core.Service.Domain/ViewSupport/Reports/Store'

export default {
  namespaced: true,
  modules: {
    PortfolioStore,
    NotificationsStore,
    ReportsStore,
  },
}
