import PieraMonitorsPortfolio from '@/Core.Service.Domain/Monitors/Piera/Views';

import PieraMonitorsList from '@/Core.Service.Domain/Monitors/Piera/Components/PieraMonitorsList.vue';

import EditPieraMonitor from '@/Core.Service.Domain/Monitors/Piera/Views/EditPieraMonitor';

// Old Piera Login routes. These views should be refactored to be part of the Piera Domain.
const PieraLoginIndexRoute = {
  path: 'piera-login',
  name: 'PieraLoginIndex',
  component: () =>
    import('@/modules/administration/piera_login/_components/Index'),
  meta: { permission: 'meters:read' },
};

const PieraLoginCreateRoute = {
  path: 'piera-login/create',
  name: 'PieraLoginCreate',
  component: () =>
    import('@/modules/administration/piera_login/_components/Create'),
  meta: { permission: 'meters:create' },
};

const PieraLoginEditRoute = {
  path: 'piera-login/:pieraLoginId/edit',
  name: 'PieraLoginEdit',
  component: () =>
    import('@/modules/administration/piera_login/_components/Edit'),
  meta: { permission: 'meters:update' },
};
// End of Piera Login routes.

const EditPieraMonitorRoute = {
  path: 'edit-Piera-monitor/:id',
  name: 'EditPieraMonitor',
  component: EditPieraMonitor,
  meta: {
    monitorsTypeName: 'TitleBar',
    label: 'Edit Piera Monitor',
    icon: 'mdi-alpha-p-box',
    toolTip: 'Edit Piera Monitor',
    permission: 'meters:read',
  },
};

const PieraMonitorsListRoute = {
  path: 'list-by-site',
  name: 'PieraMonitorsList',
  component: PieraMonitorsList,
  meta: {
    placeholder: 'Search for a Monitor',
    monitorsTypeName: 'PieraMonitors',
    label: 'Piera Monitors',
    toolTip: 'Piera Monitor',
    permission: 'meters:read',
  },
};

const PieraMonitorsRoute = {
  path: 'Piera',
  name: 'PieraMonitors',
  component: PieraMonitorsPortfolio,
  children: [PieraMonitorsListRoute, EditPieraMonitorRoute],
};

export { PieraMonitorsRoute };

export { PieraLoginIndexRoute, PieraLoginCreateRoute, PieraLoginEditRoute };
