<template>
  <div>
    <h4 class="primary--text mb-2">Edit Message</h4>
    <v-row class="mb-n9">
      <v-col>
        <v-text-field
          label="Message Title"
          color="primary"
          outlined
          v-model="messageTitle"
        />
      </v-col>
    </v-row>
    <v-row class="mb-n9">
      <v-col>
        <v-textarea
          color="primary"
          outlined
          name="message"
          label="Message to the Users"
          v-model="messageBody"
        />
      </v-col>
    </v-row>
    <v-row class="mb-n9">
      <v-col cols="6">
        <v-select
          color="primary"
          class="mb-7"
          v-model="selectedType"
          :items="messagesTypesList"
          item-text="messageCenterMessageTypeName"
          item-value="messageCenterMessageTypeId"
          label="Message Type"
          outlined
          chips
          hint="Select the message type"
          persistent-hint
        >
          <template v-slot:selection="{ item }">
            <v-chip close @click:close="selectedType = ''">
              {{ item.messageCenterMessageTypeName }}
            </v-chip>
          </template>
        </v-select>
        <v-autocomplete
          color="primary"
          v-model="selectedUsers"
          :items="usersList"
          item-text="fullName"
          item-value="userId"
          label="Users"
          multiple
          outlined
          chips
          clearable
          hint="Select users who will receive the message"
          persistent-hint
          :filter="filterUsers"
          @click:clear="clearSelectedUsers"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 2" close @click:close="removeUser(item)">
              <span>{{ item.fullName }}</span>
            </v-chip>
            <span
              v-if="index === selectedUsers.length - 1 && index > 2"
              class="grey--text text-caption"
            >
              (+{{ selectedUsers.length - 1 }} others)
            </span>
          </template>
          <template v-slot:append-outer>
            <v-btn
              :disabled="selectedUsers.length > 0"
              small
              color="primary"
              class="rounded-pill"
              depressed
              @click="selectAllUsers"
            >
              <div
                class="subtitle-2 white--text text-truncate font-weight-bold px-3"
              >
                {{
                  filteredItems.length
                    ? `Select ${filteredItems.length} Users`
                    : 'Select All Users'
                }}
              </div>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-date-picker
          v-model="selectedDate"
          :show-current="currentDate"
          full-width
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-btn
          :loading="isLoading"
          :disabled="isLoading || !isFormValid"
          color="primary"
          class="rounded-pill mr-2"
          depressed
          @click="sendMessage"
        >
          <div
            class="subtitle-2 white--text text-truncate font-weight-bold px-3"
          >
            Edit Message
          </div>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          class="rounded-pill mb-3"
          color="warning"
          depressed
          @click="cancel"
        >
          <div
            class="subtitle-2 white--text text-truncate font-weight-bold px-3"
          >
            Cancel
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import { transformDateToDatepicker } from '@/Core.Patterns/Factory';

import API from '@/Core.Service.Domain/MessageCenter/Management/API';

export default {
  name: 'MessagesCenterEditMessage',

  computed: {
    ...mapGetters({
      getMessageToEdit: 'MessageCenterManagementStore/getMessageToEdit',
      usersList: 'MessageCenterManagementStore/getUsersList',
      messagesTypesList: 'MessageCenterManagementStore/getMessagesTypesList',
    }),

    isFormValid() {
      return (
        this.messageBody.length > 0 &&
        this.messageTitle.length > 0 &&
        this.selectedType !== '' &&
        this.selectedDate.length > 0
      );
    },
  },

  mounted() {
    this.setFormValues();
  },

  data() {
    return {
      currentDate: new Date().toISOString().substring(0, 10),
      selectedDate: new Date().toISOString().substring(0, 10),
      isLoading: false,
      selectedUsers: [],
      filteredItems: [],
      messageBody: '',
      messageTitle: '',
      selectedType: '',
    };
  },

  methods: {
    ...mapActions({
      getAllMessagesFromMessageCenter:
        'MessageCenterManagementStore/getAllMessagesFromMessageCenter',
      getMessagesTypesList: 'MessageCenterManagementStore/getMessagesTypesList',
      setUnreadMessagesCount: 'MessageCenterUsersStore/setUnreadMessagesCount',
    }),

    async removeUser(item) {
      const { id } = this.$route.params;
      const index = this.selectedUsers.indexOf(item.userId);
      if (index > -1) {
        this.selectedUsers.splice(index, 1);
      }
      if (this.filteredItems.length) {
        this.filteredItems = this.filteredItems.filter(
          (user) => user.userId !== item.userId
        );
      }

      await API.disassociateUserToMessage({
        MessageCenterMessageId: id,
        UserIds: [item.userId],
      });
    },

    async clearSelectedUsers() {
      const { id } = this.$route.params;
      await API.disassociateUserToMessage({
        MessageCenterMessageId: id,
        UserIds: this.selectedUsers,
      });
      this.selectedUsers = [];
      this.filteredItems = [];
    },

    selectAllUsers() {
      if (this.filteredItems.length) {
        this.selectedUsers = this.filteredItems.map((item) => item.userId);
      } else {
        this.selectedUsers = this.usersList.map((item) => item.userId);
      }
    },

    setFormValues() {
      this.messageBody = this.getMessageToEdit.messagingCenterMessageValue;
      this.messageTitle = this.getMessageToEdit.messageCenterMessageLabel;
      this.selectedUsers = this.getMessageToEdit.usersId.map((user) => {
        return user;
      });
      this.selectedType = this.getMessageToEdit.messageCenterMessageTypeId;
      this.selectedDate = transformDateToDatepicker(
        this.getMessageToEdit.messageCenterMessageExpirationDateTimeOffset
      );
    },

    clearForm() {
      this.selectedUsers = [];
      this.filteredItems = [];
      this.messageBody = '';
      this.messageTitle = '';
      this.selectedType = '';
      this.selectedDate = '';
    },

    async editMessage() {
      this.isLoading = true;
      const { id } = this.$route.params;
      try {
        await API.messageCenterEditMessage({
          MessageCenterMessageId: id,
          MessageCenterMessageLabel: this.messageTitle,
          MessageCenterMessageValue: this.messageBody,
          MessageCenterMessageTypeId: this.selectedType,
          MessageCenterMessageExpirationDateTimeOffset: this.selectedDate,
        });

        if (this.selectedUsers.length) {
          await API.associateUserToMessage({
            MessageCenterMessageId: id,
            UserIds: this.selectedUsers,
          });
        }

        await this.getAllMessagesFromMessageCenter();
        await this.setUnreadMessagesCount();
        this.$router.push({
          name: 'MessageCenter',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    sendMessage() {
      this.editMessage();
    },

    cancel() {
      this.$router.push({
        name: 'MessageCenter',
      });
    },

    filterUsers(item, queryText, itemText) {
      const text = itemText ? itemText : '';
      const searchText = queryText ? queryText.toString().toLowerCase() : '';

      this.filteredItems = this.usersList.filter((user) => {
        return (
          user.fullName.toLowerCase().indexOf(searchText) > -1 &&
          !this.selectedUsers.includes(user.userId)
        );
      });

      return text.toLowerCase().indexOf(searchText) > -1;
    },
  },
};
</script>
