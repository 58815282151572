<template>
  <v-progress-linear
    :height="height"
    reverse
    :active="isLoading"
    :indeterminate="isLoading"
    :query="query"
  />
</template>

<script>
export default {
  name: 'ProgressLinearLoader',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 5,
    },
    query: {
      type: Boolean,
      default: true,
    },
  },
}
</script>