import API from '@/Core.Service.Domain/Analytics/NonRoutineEvents/API';

const getSitesWithAnalyticsNonRoutineEvents = async (context) => {
  const sitesWithAnalyticsNonRoutineEvents = await API.getSitesWithAnalyticsNonRoutineEvents();
  context.commit(
    'SITES_WITH_NON_ROUTINE_EVENTS',
    sitesWithAnalyticsNonRoutineEvents
  );
};

const getAnalyticsNonRoutineEventsBySiteId = async (context, site) => {
  const { selectedSite } = site;
  const { nonRoutineEventTypeName } = site;

  context.commit('SELECTED_SITE_NON_ROUTINE_EVENTS_IS_LOADING', true);

  const selectedSiteNonRoutineEvents = await API.getAnalyticsNonRoutineEventsBySiteId(
    selectedSite
  );
  const selectedSiteWithAnalyticsNonRoutineEvents = selectedSiteNonRoutineEvents.filter(
    (site) => site.nonRoutineEventTypeName === nonRoutineEventTypeName
  );

  context.commit(
    'SELECTED_SITE_NON_ROUTINE_EVENTS',
    selectedSiteWithAnalyticsNonRoutineEvents
  );
  context.commit('SELECTED_SITE_NON_ROUTINE_EVENTS_IS_LOADING', false);
};

const setAnalyticsNonRoutineEventsCurrentRoute = async (
  context,
  nonRoutineEventCurrentRoute
) => {
  context.commit(
    'NON_ROUTINE_EVENTS_CURRENT_ROUTE',
    nonRoutineEventCurrentRoute
  );
};

export default {
  getSitesWithAnalyticsNonRoutineEvents,
  getAnalyticsNonRoutineEventsBySiteId,
  setAnalyticsNonRoutineEventsCurrentRoute,
};
