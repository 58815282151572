import Auth0 from 'auth0-js';
import Vue from 'vue';
import { globalConfig } from '../configuration/config';

let getWebAuth = (dynoConfig) => {
  return new Auth0.WebAuth({
    domain: dynoConfig.DYNO_AUTH0_DOMAIN,   
    clientID: dynoConfig.DYNO_AUTH0_CLIENTID,    
    redirectUri: import.meta.env.VITE_APP_CALLBACK_URI,
    audience: dynoConfig.DYNO_AUTH0_AUDIENCE,   
    responseType: 'token id_token',
    scope: 'openid email profile',
  });
};

const callBackRoute = `${window.location.origin}/callback`;

export default class AuthenticationService {
  constructor() {
    this.auth0 = getWebAuth(globalConfig.get());
  }

  isAuthenticated() {
    let expiresAt = localStorage.getItem('expires_at');
    if (expiresAt == null) return false;

    return new Date().getTime() < JSON.parse(expiresAt);
  }

  handleAuthentication() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri = callBackRoute; //hack for alpha-uat / plain alpha
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, result) => {
        if (err) return reject(err);
        resolve(result);
      });
    });
  }

  login() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri = callBackRoute; //hack for alpha-uat / plain alpha
    this.auth0.authorize();
  }

  logout() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri = callBackRoute; //hack for alpha-uat / plain alpha
    this.auth0.logout();
  }

  checkSession() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri = callBackRoute; //hack for alpha-uat / plain alpha
    return new Promise((resolve, reject) => {
      const options = { connection: 'google-oauth2' };
      this.auth0.checkSession(options, (err, result) => {
        if (err) return reject(err);
        resolve(result);
      });
    });
    // this.auth0.authorize({ connection: 'google-oauth2' });
  }
}
