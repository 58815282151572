<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="measure-bar d-flex flex-row"
    class="measure-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <v-toolbar
        dense
        :width="toolBarWidth"
      >
        <v-text-field
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'ReportsBar',

  data() {
    return {
      toolBarHeight: 50,
      toolBarWidth: 995,
    };
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Report';
    },
  },

  methods: {
    ...mapMutations({
      setReportSearchTerm: 'ViewSupportStore/ReportsStore/REPORT_SEARCH_TERM',
    }),

    handleSearchInput(value) {
      this.setReportSearchTerm(value);
    },
  },

};
</script>
<style lang="sass" scoped>
.measure-bar
  position: fixed
  top: 100px
  z-index: 1
</style>