import UsersView from '@/Core.Service.Domain/MessageCenter/Users/Views';
import PopUpMessageTypeText from '@/Core.Service.Domain/MessageCenter/Users/Components/PopUpMessageTypeText.vue';

const UsersRoutes = [
  {
    path: 'users',
    name: 'Users',
    component: UsersView,
    redirect: { name: 'PopUpMessage' },
    children: [
      {
        path: 'pop-up-message',
        name: 'PopUpMessage',
        component: PopUpMessageTypeText,
      },
    ],
  },
];

export default UsersRoutes;
