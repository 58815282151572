const BoilersPortfolio = () =>
  import('@/Core.Service.Domain/Controls/Boilers/Views');

export const BoilersRoute = {
  path: 'boilers',
  name: 'Boilers',
  component: BoilersPortfolio,
  meta: {
    placeholder: 'Search for a Boiler',
    controlTypeName: 'Boiler',
    label: 'Boilers',
    toolTip: 'Boiler',
  },
};
