<template>
  <v-container
    fluid
    class="ml-n6 mt-n11"
  >
    <v-row>
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col v-else>
        <BannerAlertVue
          v-if="!measuresList.length"
          :message="bannerMessage"
        />
        <MeasuresListVue
          v-else
          :MeasuresList="measuresList"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MeasuresListVue from '@/Core.Service.Domain/Analytics/Measures/Components/MeasuresList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeasuresPortfolio',

  components: { MeasuresListVue, ProgressLinearLoaderVue, BannerAlertVue },

  computed: {
    ...mapGetters({
      measuresList: 'AnalyticsStore/AnalyticsMeasuresStore/getAnalyticsMeasuresList',
    }),
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getAnalyticsMeasures();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      getAnalyticsMeasures: 'AnalyticsStore/AnalyticsMeasuresStore/getAnalyticsMeasures',
    }),
  }
};
</script>