import API from '@/Core.Service.Domain/Analytics/Models/API';

const getSitesWithAnalyticsModels = async (context) => {
  const sitesWithAnalyticsModels = await API.getSitesWithAnalyticsModels();
  context.commit('SITES_WITH_ANALYTICS_MODELS', sitesWithAnalyticsModels);
};

const getAnalyticsModelsBySiteId = async (context, site) => {
  const { selectedSite } = site;

  context.commit('SELECTED_SITE_ANALYTICS_MODELS_IS_LOADING', true);

  const selectedSiteAnalyticsModels = await API.getAnalyticsModelsBySiteId(
    selectedSite
  );

  context.commit('SELECTED_SITE_ANALYTICS_MODELS', selectedSiteAnalyticsModels);
  context.commit('SELECTED_SITE_ANALYTICS_MODELS_IS_LOADING', false);
};

const setAnalyticsModelsCurrentRoute = async (
  context,
  analyticsModelsCurrentRoute
) => {
  context.commit('ANALYTICS_MODELS_CURRENT_ROUTE', analyticsModelsCurrentRoute);
};

export default {
  getSitesWithAnalyticsModels,
  getAnalyticsModelsBySiteId,
  setAnalyticsModelsCurrentRoute,
};
