<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="MeasuresList"
      :items-per-page="10"
      :search="searchTerm"
      :loading="!MeasuresList.length"
      :header-props="headerProps"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <!--Headers slot-->
      <template v-slot:headers="props">
        <tr>
          <th
            v-for="(header, i) in props.headers"
            :key="i"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Code slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.measureCode }}
            </span>
          </td>
          <!--Name slot-->
          <td>
            <span class="primary--text text-subtitle-1 cut-text">
              {{ item.measureName }}
            </span>
          </td>
          <!--Type slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.measureType }}
            </span>
          </td>
          <!--Actions buttons slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :outlined="true"
              :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
              :item="item"
              class="ml-n2"
            />
          </td>
          <!--Assigned to Project slot-->
          <td>
            <v-tooltip right v-if="item.analtyicProjectResourceModels.length">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  style="cursor: pointer"
                  @click="
                    handleAssignedProject(
                      item.analtyicProjectResourceModels[0].id
                    )
                  "
                >
                  mdi-check-all
                </v-icon>
              </template>
              <span>
                Assigned to: {{ item.analtyicProjectResourceModels[0].name }}
              </span>
            </v-tooltip>
            <v-btn
              v-else
              :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
              outlined
              color="primary"
              @click="handleAssignProject"
            >
              Assign
              <v-icon right dark> mdi-chart-gantt </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteMeasure
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :measureInfo="MeasureInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { searchByTermFactory } from '@/Core.Patterns/Factory'

const ActionButtonsVue = () =>
  import('@/Core.UI.Domain/Components/ActionButtons')
const ConfirmDialogDeleteMeasure = () =>
  import(
    '@/Core.Service.Domain/Analytics/Measures/Components/ConfirmDialogDeleteMeasure.vue'
  )

export default {
  name: 'MeasuresList',

  props: {
    MeasuresList: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ActionButtonsVue,
    ConfirmDialogDeleteMeasure,
  },

  computed: {
    ...mapGetters({
      searchTerm: 'AnalyticsStore/AnalyticsMeasuresStore/getMeasureSearchTerm',
    }),

    userCanCreate() {
      return this.$can('create', 'measures')
    },
    userCanDelete() {
      return this.$can('delete', 'measures')
    },
    userCanUpdate() {
      return this.$can('update', 'measures')
    },
  },

  data(instance) {
    return {
      showConfirmDialog: false,
      MeasureInfo: {},
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          text: 'Code',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'measureCode',
          sortable: true,
        },
        {
          text: 'Name',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'measureName',
          sortable: true,
        },
        {
          text: 'Type',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'measureType',
          sortable: true,
        },
        {
          text: 'Assigned to Project',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'analtyicProjectResourceModels[0]',
          sortable: true,
        },
        {
          text: 'Actions',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'actions',
          sortable: false,
        },
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
      filteredItems: [],
      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: (item) => {
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete
            )
              return
            instance.$router.push({
              name: 'AnalyticsMandVMeasuresDetails',
              params: {
                measuresId: item.id,
              },
            })
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          disabled: (item) => item.analtyicProjectResourceModels.length > 0,
          action: (item) => {
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete ||
              item.analtyicProjectResourceModels.length > 0
            )
              return
            instance.MeasureInfo = item
            instance.showConfirmDialog = true
          },
        },
      ],
    }
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['measureCode', 'measureName', 'measureType'],
        this.MeasuresList
      )
    },
  },

  methods: {
    replaceTab(text) {
      return text.replace(/\t/g, '')
    },

    handleAssignProject() {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return
      this.$router.push({
        name: 'AnalyticsProjects',
      })
    },

    handleAssignedProject(id) {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return
      this.$router.push({
        name: 'AnalyticsMandVProjectDetails',
        params: {
          id,
        },
      })
    },
  },
}
</script>
