const ProjectsPortfolio = () =>
  import('@/Core.Service.Domain/Analytics/Projects/Views');

export const ProjectsRoute = {
  path: 'projects',
  name: 'AnalyticsProjects',
  component: ProjectsPortfolio,
  meta: {
    placeholder: 'Search for an Analytics Project',
    analyticsTypeName: 'AnalyticsProject',
    label: 'Analytics Projects',
    buttonLabel: 'New Project',
    toolTip: 'Analytics Project',
  },
};
