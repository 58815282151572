import actions from '@/Core.Service.Domain/Strategies/StrategyTemplates/Store/actions.js';
import getters from '@/Core.Service.Domain/Strategies/StrategyTemplates/Store/getters.js';
import mutations from '@/Core.Service.Domain/Strategies/StrategyTemplates/Store/mutations.js';

const state = {
  strategyTemplatesList: [],
  strategyTemplateSearchTerm: '',
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
