import actions from '@/Core.Service.Domain/Analytics/Models/Store/actions';
import getters from '@/Core.Service.Domain/Analytics/Models/Store/getters';
import mutations from '@/Core.Service.Domain/Analytics/Models/Store/mutations';

const state = {
  analyticsModelsCurrentRoute: '',
  sitesWithAnalyticsModels: [],
  selectedSiteAnalyticsModels: [],
  selectedSiteAnalyticsModelsIsLoading: true,
  analyticsModelsSearchTerm: '',
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
