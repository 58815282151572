import Vue from 'vue';
import Toast from '@/components/Toasts/index.vue';

const getErrorMessage = (errorObject) => {
  const regex = /error/i;

  const errorKeys = Object.keys(errorObject);
  const errorKey = errorKeys.find((key) => key.match(regex));

  if (Array.isArray(errorObject[errorKey])) {
    return errorObject[errorKey].join('\n');
  }

  const errorMessage = errorObject[errorKey];
  return errorMessage;
};

const ToastPlugin = {
  install(Vue) {
    let component = new Vue(Toast);

    let createComponent = function (options) {
      Object.assign(Toast, options);

      component = new Vue(Toast);
      let $el = component.$mount().$el;

      document.getElementById('app').appendChild($el);

      return component;
    };

    Vue.prototype.$toast = {
      show(message, timeout, color, options = {}) {
        // debugger;
        options.propsData = {
          message: message,
          timeout: timeout || 6000,
          color: color,
        };
        component = createComponent(options);
        component.$on('onactivechanged', (isActive, wasActive) => {
          if (wasActive && !isActive) {
            component = null;
          }
        });
      },
    };

    Vue.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        this.prototype.$toast.show(
          `There was an error processing your request.\r\n${getErrorMessage(
            error
          )}`
        );

        return Promise.reject(error);
      }.bind(Vue)
    );
  },
};

//Vue.use(Plugin);

export default ToastPlugin;
