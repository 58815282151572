// Further options can be passed to options object
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

/**
 * @param {String} dateTime
 * @param {Object} options
 * @returns String
 * @description Convert date time to US format
 * @example dateTimeToUSFormatFactory('2020-01-01T00:00:00.000Z');
 * returns 'January 1, 2020'
 * @example dateTimeToUSFormatFactory('2020-01-01T00:00:00.000Z', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
 * returns 'January 1, 2020, 5:00:00 PM'
 */
export const dateTimeToUSFormatFactory = (
  dateTime,
  options = { year: 'numeric', month: 'long', day: 'numeric' }
) => {
  const date = new Date(dateTime);
  return date.toLocaleDateString('en-US', options);
};

/**
 * @param {String} searchTerm
 * @param {Array} searchFields
 * @param {Array} originalArray
 * @returns Array
 * @description Search an array of objects by term
 */
export const searchByTermFactory = (
  searchTerm,
  searchFields,
  originalArray
) => {
  const searchTerms = searchTerm.toLowerCase().split(' ');
  return originalArray.filter((item) => {
    return searchTerms.every((term) => {
      return searchFields.some((field) => {
        return item[field].toLowerCase().includes(term);
      });
    });
  });
};

/**
 * @param {Number} value
 * @param {Number} decimals
 * @returns String
 * @description Format number to N decimals
 * @example formatNumberPrecisionToNDecimalsFactory(123.456, 2);
 * returns '123.46'
 *
 **/
export const formatNumberPrecisionToNDecimalsFactory = (
  value,
  decimals = 2
) => {
  if (value === null) {
    return 'N/A';
  }
  return value.toFixed(decimals);
};

/**
 * @param {Number} value
 * @returns String
 * @description Format number to US format
 * @example formatNumberToUS(123456.789);
 * returns '123,456.79'
 *
 **/
export const formatNumberToUS = (num) => {
  if (Math.floor(num) === num) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 0,
    }).format(num);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

export const transformDateToDatepicker = (inputDate) => {
  const dateParts = inputDate.split('T');
  const dateOnly = dateParts[0];

  return dateOnly;
};
