import AginovaMonitorsStore from '@/Core.Service.Domain/Monitors/Aginova/Store';
import PieraMonitorsStore from '@/Core.Service.Domain/Monitors/Piera/Store';

export default {
  namespaced: true,
  modules: {
    AginovaMonitorsStore,
    PieraMonitorsStore,
  },
};
