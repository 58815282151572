<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="strategies-templates-bar d-flex flex-row"
    class="strategies-templates-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <v-btn
        v-if="userCanUpdate"
        class="rounded-pill mr-3"
        color="primary"
        depressed
        large
        @click="handleAddStrategyTemplate"
      >
        <div class="subtitle-2 white--text text-truncate font-weight-bold">
          New Strategy Template
        </div>
      </v-btn>
      <v-toolbar
        dense
        :width="toolBarWidth"
      >
        <v-text-field
          v-model="searchTerm"
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'StrategyTemplatesBar',

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Strategy Template';
    },

    userCanUpdate() {
      return this.$can('update', 'controls');
    },
  },

  watch: {
    $route() {
      this.clearSearchTerm();
    },
  },

  data() {
    return {
      searchTerm: '',
      toolBarHeight: 50,
      toolBarWidth: 995,
      selectedSite: null,
    };
  },

  methods: {
    ...mapMutations({
      setStrategyTemplateSearchTerm: 'StrategiesStore/StrategyTemplatesStore/STRATEGY_TEMPLATE_SEARCH_TERM',
    }),

    handleAddStrategyTemplate() {
      if (!this.userCanUpdate) return;
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({ name: 'StrategyTemplatesCreate' });
    },

    handleSearchInput(value) {
      this.setStrategyTemplateSearchTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setStrategyTemplateSearchTerm('');
    },
  },

};
</script>
<style lang="sass" scoped>
.strategies-templates-bar
  position: fixed
  top: 98px
  z-index: 1
</style>