import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import ability from '@/plugins/abilities'

import Callback from '@/components/Callback.vue'

// Core Service Domain

// Controls Domain
import ControlsRoutes from '@/Core.Service.Domain/Controls/Controls.Common/Routes'
// Strategies Domain
import StrategiesRoutes from '@/Core.Service.Domain/Strategies/Strategies.Common/Routes'
// Analytics Domain
import AnalyticsRoutes from '@/Core.Service.Domain/Analytics/Analytics.Common/Routes'
// Sites Domain
import SitesRoutes from '@/Core.Service.Domain/Sites/Sites.Common/Routes'
// View Support Domain
import PortfolioRoutes from '@/Core.Service.Domain/ViewSupport/Portfolio/Routes'
import ReportsRoutes from '@/Core.Service.Domain/ViewSupport/Reports/Routes'
import KioskRoutes from '@/Core.Service.Domain/ViewSupport/Kiosk/Routes'
import NotificationsRoutes from '@/Core.Service.Domain/ViewSupport/Notifications/Routes'
// Contact Domain
import ContactRoutes from '@/Core.Service.Domain/Contact/Routes'

// Admin Domain
import AdminRoutes from '@/Core.Service.Domain/Admin/Routes'

// Users Domain
// These routes are old fashioned and should be refactored to be part of the new Users Domain.
import UsersRoutes from '@/Core.Service.Domain/Users/Routes'

// Monitors Domain (Egauges)
// These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
import EgaugesRoutes from '@/Core.Service.Domain/Monitors/Egauges/Routes'

// Monitors Domain (Utility Meters)
// These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
import UtilityMetersRoutes from '@/Core.Service.Domain/Monitors/UtilityMeters/Routes'

// Monitors Domain (Manual Utility Monitors)
// These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
import ManualUtilityMonitorsRoutes from '@/Core.Service.Domain/Monitors/ManualUtilityMonitors/Routes'

// Monitors Domain (Virtual Monitors)
// These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
import VirtualMonitorsRoutes from '@/Core.Service.Domain/Monitors/VirtualMonitors/Routes'

Vue.use(Router)

let routes = []

const HomeRoute = {
  path: '/',
  name: 'Home',
  redirect: () => {
    return { name: 'Portfolio' }
  },
}

const CallbackRoute = {
  path: '/callback',
  name: 'Callback',
  component: Callback,
  meta: { allowAnonymous: true },
}

routes.push(HomeRoute, CallbackRoute)

const ApplicationErrorRoute = {
  path: '/error',
  name: 'ApplicationError',
  component: () => import('@/modules/errors/_components/Index'),
  meta: { allowAnonymous: true },
}
routes.push(ApplicationErrorRoute)

/**********************************
 * Site Routes
 */
SitesRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Heat Map Routes
 */
const HeatMapIndexRoute = {
  path: '/heatmaps',
  name: 'HeatMapIndex',
  component: () => import('@/modules/heatmaps/_components/Index'),
}

routes.push(HeatMapIndexRoute)

/***************************************
 * Analytics Routes
 */
AnalyticsRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Control Routes
 */
ControlsRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Portfolio Routes
 */
PortfolioRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Notifications Routes
 */
NotificationsRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Report Routes
 */
ReportsRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Admin Routes
 */
AdminRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * User Routes
 * These routes are old fashioned and should be refactored to be part of the new Users Domain.
 */
UsersRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * SubMetering Routes(Egauges)
 * These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
 */
EgaugesRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * SubMetering Routes(Utility Meters)
 * These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
 */
UtilityMetersRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Monitor Routes(Manual Utility Monitors)
 * These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
 */
ManualUtilityMonitorsRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Monitor Routes(Virtual Monitors)
 * These routes are old fashioned and should be refactored to be part of the new Monitors Domain.
 */
VirtualMonitorsRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Kiosk Routes
 */
KioskRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Contact Routes
 */
ContactRoutes.forEach((route) => {
  routes.push(route)
})

/***************************************
 * Strategies Routes
 */
StrategiesRoutes.forEach((route) => {
  routes.push(route)
})

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: routes,
})

router.beforeEach((to, from, next) => {
  const allowAnonymous = to.matched.some((route) => route.meta.allowAnonymous)
  const isAuthenticated = store.getters['session/isAuthenticated']
  const userPermissionsSet = store.getters['session/userPermissionsSet']

  //router gets hit before initialize session gets to initialize the permissions, so we force it
  if (isAuthenticated && !userPermissionsSet) {
    console.log('user permissions not loaded yet in router')
    store.dispatch('session/initializeSession')
  }

  if (allowAnonymous || isAuthenticated) {
    if ('permission' in to.meta) {
      const [subject, action] = to.meta.permission.split(':')
      const noAccess = ability.cannot(action, subject)

      if (noAccess) {
        next({
          name: 'ApplicationError',
          query: { redirectFrom: to.fullPath, originalUrl: from.fullPath },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    if (to.fullPath !== '/logout') {
      localStorage.setItem('redirect', to.fullPath)
    }
    store.dispatch('session/login')
  }
})

export default router
