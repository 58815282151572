import getters from '@/Core.Service.Domain/ViewSupport/Portfolio/Store/getters.js'
import mutations from '@/Core.Service.Domain/ViewSupport/Portfolio/Store/mutations.js'

const state = {
  siteSearchTerm: '',
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
