// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.

const VirtualMonitorIndexRoute = {
  path: '/monitors/virtual_monitor',
  name: 'VirtualMonitorIndex',
  component: () =>
    import('@/modules/monitors/virtual_monitors/_components/Index'),
  meta: { permission: 'meters:read' },
};

const VirtualMonitorCreateRoute = {
  path: '/site/:siteId/virtual_monitor/create',
  name: 'VirtualMonitorCreate',
  component: () =>
    import('@/modules/monitors/virtual_monitors/_components/Create'),
  meta: { permission: 'meters:create' },
};

const VirtualMonitorEditRoute = {
  path: '/site/:siteId/virtual_monitors/:virtualMonitorId/edit',
  name: 'VirtualMonitorEdit',
  component: () =>
    import('@/modules/monitors/virtual_monitors/_components/Edit'),
  meta: { permission: 'meters:update' },
};

export default [
  VirtualMonitorIndexRoute,
  VirtualMonitorCreateRoute,
  VirtualMonitorEditRoute,
];
