const sitesForPieraMonitors = (state) => state.sitesForPieraMonitors;
const selectedSiteWithPieraMonitors = (state) =>
  state.selectedSiteWithPieraMonitors;
const pieraMonitorsCurrentRoute = (state) => state.pieraMonitorsCurrentRoute;
const selectedSiteWithPieraMonitorsIsLoading = (state) =>
  state.selectedSiteWithPieraMonitorsIsLoading;

const getPieraMonitorsSearchTerm = (state) => state.pieraMonitorsSearchTerm;

const getPieraMonitorByDeviceId = (state) => state.selectedPieraMonitor;

const selectedPieraMonitorIsLoading = (state) =>
  state.selectedPieraMonitorIsLoading;

const siteLocationsForPieraMonitor = (state) =>
  state.siteLocationsForPieraMonitor;

export default {
  sitesForPieraMonitors,
  selectedSiteWithPieraMonitors,
  pieraMonitorsCurrentRoute,
  selectedSiteWithPieraMonitorsIsLoading,
  getPieraMonitorsSearchTerm,
  getPieraMonitorByDeviceId,
  selectedPieraMonitorIsLoading,
  siteLocationsForPieraMonitor,
};
