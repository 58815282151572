<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="measure-bar d-flex flex-row"
    class="measure-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <!-- Add Button -->
      <v-btn
        v-if="userCanUpdate || userCanCreate || userCanDelete"
        class="rounded-pill mr-3"
        color="primary"
        depressed
        large
        @click="handleAddAnalyticsMeasure"
      >
        <div class="subtitle-2 white--text text-truncate font-weight-bold">
          {{ $route.meta.buttonLabel }}
        </div>
      </v-btn>
      <!-- Select Site -->
      <v-toolbar
        dense
        :width="toolBarWidth"
      >
        <v-text-field
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'MeasureBar',

  data() {
    return {
      toolBarHeight: 50,
      toolBarWidth: 995,
    };
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Measure';
    },

    userCanCreate() {
      return this.$can('create', 'measures');
    },
    userCanDelete() {
      return this.$can('delete', 'measures');
    },
    userCanUpdate() {
      return this.$can('update', 'measures');
    },
  },

  methods: {
    ...mapMutations({
      setMeasure: 'AnalyticsStore/AnalyticsMeasuresStore/MEASURE_SEARCH_TERM',
    }),

    handleAddAnalyticsMeasure() {
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({ name: 'AnalyticsMandVMeasuresNew' });
    },

    handleSearchInput(value) {
      this.setMeasure(value);
    },
  },

};
</script>
<style lang="sass" scoped>
.measure-bar
  position: fixed
  top: 100px
  z-index: 1
</style>