const apiVersion = 'v1';

const getMessageCenterUnreadCount = async () => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/MessageCenter/unread`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMessagesByUserFromMessageCenter = async () => {
  try {
    const response = await window.axios.get(`/${apiVersion}/MessageCenter`);
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const markMessageAsRead = async (messageId) => {
  try {
    const response = await window.axios.put(
      `/${apiVersion}/MessageCenter/read`,
      { UserMessageCenterMessageId: messageId }
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const markMessageAsUnread = async (messageId) => {
  try {
    const response = await window.axios.put(
      `/${apiVersion}/MessageCenter/unread`,
      { UserMessageCenterMessageId: messageId }
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getMessageCenterUnreadCount,
  getMessagesByUserFromMessageCenter,
  markMessageAsRead,
  markMessageAsUnread,
};
