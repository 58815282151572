<template>
  <div class="mt-n8">
    <v-icon color="primary" large class="mt-n1">
      {{ icon }}
    </v-icon>
    <span class="text-h6 primary--text">
      {{ label }}
    </span>
  </div>
</template>
<script>
export default {
  name: "TitleBar",
  computed: {
    label() {
      return this.$route.meta.label;
    },

    icon() {
      return this.$route.meta.icon;
    }
  }
};
</script>