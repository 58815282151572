const apiVersion = 'v1';
const rootPath = `${apiVersion}/PieraMonitor`;

const getSitesForPiera = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/sites/portfolio`);
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPieraMonitorsBySiteId = async (id) => {
  try {
    const result = await window.axios.get(`${rootPath}/monitors/site/${id}`);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getPieraMonitorByDeviceId = async (deviceId) => {
  try {
    const result = await window.axios.get(`${rootPath}/${deviceId}`);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSiteLocationsForPieraMonitor = async (siteId) => {
  try {
    const result = await window.axios.get(
      `${apiVersion}/SiteLocation/SiteLocations/${siteId}`
    );
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updatePieraMonitor = async (PieraMonitor) => {
  try {
    const result = await window.axios.put(`${rootPath}/update`, PieraMonitor);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deletePieraMonitor = async (PieraMonitor) => {
  const { siteId, deviceId } = PieraMonitor;
  try {
    const result = await window.axios.delete(`${rootPath}/delete`, {
      data: {
        siteId,
        deviceId,
      },
    });
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getPieraMonitorsBySiteId,
  getSitesForPiera,
  deletePieraMonitor,
  getPieraMonitorByDeviceId,
  getSiteLocationsForPieraMonitor,
  updatePieraMonitor,
};
