const apiVersion = 'v1';
const rootPath = `${apiVersion}/AginovaMonitor`;

const getSitesForAginova = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/sites/portfolio`);
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAginovaMonitorsBySiteId = async (id) => {
  try {
    const result = await window.axios.get(`${rootPath}/monitors/site/${id}`);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAginovaMonitorByDeviceId = async (deviceId) => {
  try {
    const result = await window.axios.get(`${rootPath}/${deviceId}`);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSiteLocationsForAginovaMonitor = async (siteId) => {
  try {
    const result = await window.axios.get(
      `${apiVersion}/SiteLocation/SiteLocations/${siteId}`
    );
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateAginovaMonitor = async (AginovaMonitor) => {
  try {
    const result = await window.axios.put(`${rootPath}/update`, AginovaMonitor);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteAginovaMonitor = async (AginovaMonitor) => {
  const { siteId, deviceId } = AginovaMonitor;
  try {
    const result = await window.axios.delete(`${rootPath}/delete`, {
      data: {
        siteId,
        deviceId,
      },
    });
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getAginovaMonitorsBySiteId,
  getSitesForAginova,
  deleteAginovaMonitor,
  getAginovaMonitorByDeviceId,
  getSiteLocationsForAginovaMonitor,
  updateAginovaMonitor,
};
