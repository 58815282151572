import API from '@/Core.Service.Domain/Analytics/Projects/API';

const getSitesWithAnalyticsProjects = async (context) => {
  const sitesWithAnalyticsProjects = await API.getSitesWithAnalyticsProjects();
  context.commit('SITES_WITH_ANALYTICS_PROJECT', sitesWithAnalyticsProjects);
};

const getAnalyticsProjectsBySiteId = async (context, site) => {
  const { selectedSite } = site;

  context.commit('SELECTED_SITE_ANALYTICS_PROJECT_IS_LOADING', true);

  const selectedSiteAnalyticsProjects = await API.getAnalyticsProjectsBySiteId(
    selectedSite
  );

  context.commit(
    'SELECTED_SITE_ANALYTICS_PROJECT',
    selectedSiteAnalyticsProjects
  );
  context.commit('SELECTED_SITE_ANALYTICS_PROJECT_IS_LOADING', false);
};

const setAnalyticsProjectsCurrentRoute = async (
  context,
  analyticsProjectsCurrentRoute
) => {
  context.commit(
    'ANALYTICS_PROJECT_CURRENT_ROUTE',
    analyticsProjectsCurrentRoute
  );
};

export default {
  getSitesWithAnalyticsProjects,
  getAnalyticsProjectsBySiteId,
  setAnalyticsProjectsCurrentRoute,
};
