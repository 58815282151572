import API from '@/Core.Service.Domain/Strategies/StrategyTemplates/API';

const getStrategyTemplates = async (context) => {
  const strategyTemplatesList = await API.getStrategyTemplates();
  context.commit('STRATEGY_TEMPLATES_LIST', strategyTemplatesList);
};

export default {
  getStrategyTemplates,
};
