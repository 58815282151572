<template>
  <div>
    <v-row
      v-for="row in Math.ceil(getReports.length / cardsPerRow)"
      :key="row"
    >
      <v-col
        v-for="report in getReports.slice((row - 1) * cardsPerRow, row * cardsPerRow)"
        :key="report.powerBiReportId"
      >
        <ReportCard :reportInfo="report" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReportCard from '@/Core.Service.Domain/ViewSupport/Reports/Components/ReportCard';
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

export default {
  name: "ReportsList",

  components: {
    ReportCard,
  },

  props: {
    reportsList: { type: Array },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'ViewSupportStore/ReportsStore/getReportSearchTerm',
    }),

    getReports() {
      return this.filteredItems.length ? sortAlphabetically(this.filteredItems, 'reportName') : sortAlphabetically(this.reportsList, 'reportName')
    }
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        [
          'reportName',
        ],
        this.reportsList
      );
    },
  },

  data() {
    return {
      cardsPerRow: 3,
      filteredItems: [],
    };
  },
};
</script>

<style lang="sass" scoped>
.col
  flex-grow: 0
</style>
