import ServicesView from '@/Core.Service.Domain/Strategies/Strategies.Common/Views';
import { StrategiesRoute } from '@/Core.Service.Domain/Strategies/Strategies/Routes';
import { StrategyTemplatesRoute } from '@/Core.Service.Domain/Strategies/StrategyTemplates/Routes';

const routesList = [];

const StrategiesCommonRoute = {
  path: '/strategies',
  name: 'StrategiesCommon',
  redirect: { name: 'Strategies' },
  children: [StrategiesRoute, StrategyTemplatesRoute],
  component: ServicesView,
};

routesList.push(StrategiesCommonRoute);

export default routesList;
