import StrategyTemplatesStore from '@/Core.Service.Domain/Strategies/StrategyTemplates/Store';
import StrategiesStore from '@/Core.Service.Domain/Strategies/Strategies/Store';

export default {
  namespaced: true,
  modules: {
    StrategyTemplatesStore,
    StrategiesStore,
  },
};
