const ROI_PREDICTIONS_BY_SITE = (state, roiPredictionsBySite) => {
  state.roiPredictionsBySite = roiPredictionsBySite
}

const ROI_PREDICTIONS_BY_SITE_ARE_LOADING = (
  state,
  roiPredictionsBySiteIsLoading
) => {
  state.roiPredictionsBySiteIsLoading = roiPredictionsBySiteIsLoading
}

const ROI_PREDICTION_BY_SITE_BY_SUMMARY_ID = (
  state,
  roiPredictionBySiteBySummaryId
) => {
  state.roiPredictionBySiteBySummaryId = roiPredictionBySiteBySummaryId
}

export default {
  ROI_PREDICTIONS_BY_SITE,
  ROI_PREDICTION_BY_SITE_BY_SUMMARY_ID,
  ROI_PREDICTIONS_BY_SITE_ARE_LOADING,
}
