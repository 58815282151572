import API from '@/Core.Service.Domain/Controls/Controls.Common/API';

const getSitesWithControls = async (context) => {
  const sitesWithControls = await API.getSitesWithControls();
  context.commit('SITES_WITH_CONTROLS', sitesWithControls);
};

const getControlsBySite = async (context, site) => {
  const { selectedSite } = site;
  const { controlTypeName } = site;

  const selectedSiteControls = await API.getControlsBySite(selectedSite);
  const selectedSiteControlsWithType = selectedSiteControls.filter(
    (site) => site.controlTypeName === controlTypeName
  );

  context.commit('SELECTED_SITE_CONTROLS', selectedSiteControlsWithType);
};

const setControlCurrentRoute = async (context, controlCurrentRoute) => {
  context.commit('CONTROL_CURRENT_ROUTE', controlCurrentRoute);
};

const setControlsSettingsHistoryMessagesTimerOn = async (context, timerOn) => {
  context.commit('CONTROL_SETTINGS_HISTORY_TIMER_ON', timerOn);
};

export default {
  getSitesWithControls,
  getControlsBySite,
  setControlCurrentRoute,
  setControlsSettingsHistoryMessagesTimerOn,
};
