//Power Details
//Power Heatmap
//Site Slicer - vwSite - SiteName
//Electricity Date Slicer - vwReportingDateTime - Date
//Billing
import * as powerbi from 'powerbi-client';
import slicer from '../slicerReports/dashboardUtilityElectricitySlicer.js';
import api from '../../_api'

// Embed a Power BI report in the given HTML element with the given configurations
// Read more about how to embed a Power BI report in your application here: https://go.microsoft.com/fwlink/?linkid=2153590
const getEmbed = async function(container, configuration) {
    /*-----------------------------------------------------------------------------------+
    |    Don't change these values here: access token, embed URL and report ID.          | 
    |    To make changes to these values:                                                | 
    |    1. Save any other code changes to a text editor, as these will be lost.         |
    |    2. Select 'Start over' from the ribbon.                                         |
    |    3. Select a report or use an embed token.                                       |
    +-----------------------------------------------------------------------------------*/
    // Embed the report and display it within the div container.
    const pbi = new powerbi.service.Service(
      powerbi.factories.hpmFactory,
      powerbi.factories.wpmpFactory,
      powerbi.factories.routerFactory
    );

    let report = pbi.embed(container, configuration);

    // report.off removes all event handlers for a specific event
    report.off('loaded');

    // report.on will add an event handler
    report.on('loaded', function() {
      report.off('loaded');
    });

    // report.off removes all event handlers for a specific event
    report.off('error');

    report.on('error', function(event) {
      console.log(event.detail);
    });

    //report.off removes all event handlers for a specific event
    // clears the event handlers before starting the event listener
    report.off('dataSelected');

    // report.on start the event listener
    report.on('dataSelected', async function(event) {
      // store the events
      let data = event.detail;

      // set the variables for the slicers that need to be adjusted
      let slicerThatChanged = 'Site Slicer';
      let slicersToReset = [];

      // filter events for only slicers
      if (data.visual.type != 'slicer') return;

      try {
        // persist new site through out tab changes
        if (
          data.visual.title === 'Site Slicer' && 
          data.dataPoints[0].identity[0].target.column == 'SiteName') {
          let newSite = data.dataPoints[0].identity[0].equals;
          report.config.reportParameters.SiteName = newSite;
          
          ///get new report parameters because we changes the site slicer
          const newReportParameters =
            await api.getPowerBiReportParametersBySiteName(report.config.reportName, report.config.reportParameters.SiteName);
          configuration.reportParameters = newReportParameters;
          
          await setSlicersWithNewData(report, configuration.reportParameters);
        }
        // run function to reset slicers
        await resetSlicers(
          data.visual.name,
          slicerThatChanged,
          slicersToReset,
          report
        );
      } catch (errors) {
        console.log(errors);
      }
    });

    report.on('pageChanged', async function(event) {
      console.log(event);
      try {
        setSlicersWithNewData(report, configuration.reportParameters);
      } catch (errors) {
        console.log(errors);
      }
    });

    return report;
  },
  resetSlicers = async function(
    changedSlicerName,
    slicerToListenToName,
    slicersToResetNames,
    report
  ) {
    // get list of all slicers on active page
    let pageSlicers = await getSlicersForActivePage(report);

    // get the slicer visual and compare with the visual that fired the data selected event
    let changingSlicer = pageSlicers.filter(
      (s) => s.title === slicerToListenToName
    )[0];
    if (changingSlicer.name != changedSlicerName) return;

    // for each slicer to be reset reset them.
    let slicersToReset = pageSlicers.filter(
      (s) => slicersToResetNames.indexOf(s.title) != -1
    );

    slicersToReset.map(async (s) => {
      await s.setSlicerState({ filters: [] });
    });
  },
  
  // Select Run and select an element of a visualization.
  // For example, a bar in a bar chart. You should see an entry in the Log window.
  getSlicersForActivePage = async function(report) {
    // Retrieve the page collection and get the visuals for the active page.
    try {
      const pages = await report.getPages();
      // Retrieve the active page.
      let pageWithSlicer = pages.filter(function(page) {
        return page.isActive;
      })[0];
      const visuals = await pageWithSlicer.getVisuals();
      // Retrieve the target visual.
      let slicers = visuals.filter(function(visual) {
        if (visual.type === 'slicer') console.log('Visual: ', visual);
        return visual.type === 'slicer';
      });
      // Get the slicer state
      return slicers;
    } catch (errors) {
      console.log(errors);
      return [];
    }
  },

  setSlicersWithNewData = async function(report, reportParameters) {
    const pages = await report.getPages();
    // Retrieve the active page.
    let pageWithSlicer = pages.filter(function(page) {
      return page.isActive;
    })[0];

    const visuals = await pageWithSlicer.getVisuals();
    await slicer.setSlicers(reportParameters, visuals);
  }

export default {
  getEmbed,
};
