export const getAnalyticModels = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get(
      '/v1/AnalyticProject/AnalyticModel/Site/' + siteId
    );
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticModel = async (modelId) => {
  let result = null;
  try {
    result = await window.axios.get(
      '/v1/AnalyticProject/AnalyticModel/' + modelId
    );
    let model = result.data.data;
    return model;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticProjects = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticProject/Site/' + siteId);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticProject = async (projectId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticProject/' + projectId);
    let project = result.data.data;
    return project;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticProjectForImpactReport = async (projectId) => {
  let result = null;
  try {
    result = await window.axios.get(
      '/v1/ImpactReport/AnalyticProject/' + projectId
    );
    let project = result.data.data;
    return project;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const createAnalyticProject = async (project) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/AnalyticProject/Create', project);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const updateAnalyticProject = async (project) => {
  let result = null;
  try {
    result = await window.axios.put('/v1/AnalyticProject/Update', project);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const deleteAnalyticProject = async (projectId) => {
  let result = null;
  try {
    result = await window.axios.delete(
      '/v1/AnalyticProject/Delete/' + projectId
    );
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const analyzeEnergySavings = async (
  siteId,
  analyticModel,
  projectStart,
  projectEnd,
  implementationEnd
) => {
  let result = null;
  console.log(implementationEnd);
  let data = {
    siteId: siteId,
    analyticModelId: analyticModel.id,
    startDateTime: projectStart,
    endDateTime: projectEnd,
    projectEndDateTime: implementationEnd,
  };

  try {
    result = await window.axios.post(
      '/v1/viewsupport/projectimpactreport/monthly',
      data
    );
    let predictions = result.data.data;
    return predictions;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getSites = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticProject/Site/sites');
    let sites = result.data.data;
    return sites;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getMeasures = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticProject/Measure');
    let measures = result.data.data;
    return measures;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getNREs = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get(
      '/v1/AnalyticProject/NonRoutineEvent/site/' + siteId
    );
    let nres = result.data.data;
    return nres;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

//AnalyticProjectMeasure
export const getAnalyticProjectMeasures = async (analyticProjectId) => {
  let result = null;
  try {
    result = await window.axios.get(
      '/v1/AnalyticProject/AnalyticProjectMeasure/' + analyticProjectId
    );
    var analyticsProjectMeasures = result.data.data;
    return analyticsProjectMeasures;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getMonthlyCostSavingsForImpactReport = async (
  projectMeasureId,
  year
) => {
  let result = null;
  let data = {
    analyticProjectMeasureId: projectMeasureId,
    year: year,
  };
  try {
    result = await window.axios.post('/v1/ImpactReport/monthly_value/', data);
    var analyticsProjectMeasures = result.data.data;
    return analyticsProjectMeasures;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getMonthlyEnergyAdjustmentForImpactReport = async (
  projectNreId,
  year
) => {
  let result = null;
  let data = {
    analyticProjectNreId: projectNreId,
    year: year,
  };
  try {
    result = await window.axios.post(
      '/v1/ImpactReport/monthly_energyadjustment_value/',
      data
    );
    var analyticProjectEnergyAdjustmentValues = result.data.data;
    return analyticProjectEnergyAdjustmentValues;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const downloadNewYearlySpreadsheet = async (
  analyticProjectId,
  analyticProjectMeasureId,
  year
) => {
  let result = null;
  var data = {
    AnalyticProjectId: analyticProjectId,
    AnalyticProjectMeasureId: analyticProjectMeasureId,
    Year: year,
  };
  try {
    result = await window.axios.post(
      '/v1/AnalyticProject/CostSavingsGoal/getyearlysheet',
      data,
      { responseType: 'blob' }
    );
    console.log(result.data);
    var spreadSheet = result.data;
    return spreadSheet;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const downloadYearlySpreadsheet = async (
  analyticsProjectId,
  analyticsProjectMeasureId,
  year,
  versionId
) => {
  let result = null;
  var data = {
    AnalyticProjectId: analyticsProjectId,
    AnalyticProjectMeasureId: analyticsProjectMeasureId,
    Year: year,
    VersionId: versionId,
  };
  try {
    result = await window.axios.post(
      '/v1/AnalyticProject/CostSavingsGoal/exportyearly',
      data,
      { responseType: 'blob' }
    );
    var spreadSheet = result.data;
    return spreadSheet;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const uploadYearlySpreadsheet = async (
  analyticsProjectId,
  analyticsProjectMeasureId,
  file
) => {
  let result = null;
  let formData = new FormData();
  formData.append('uploadedFile', file, file.name);
  let config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  try {
    result = await window.axios.post(
      '/v1/AnalyticProject/CostSavingsGoal/uploadyearly/' +
        analyticsProjectId +
        '/' +
        analyticsProjectMeasureId,
      formData,
      config
    );
    var status = result.data;
    return status;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getChartData = async (
  analyticProjectId,
  projectMeasureId,
  year,
  version
) => {
  let result = null;
  let data = {
    AnalyticProjectId: analyticProjectId,
    AnalyticProjectMeasureId: projectMeasureId,
    Year: year,
    Version: version,
  };
  try {
    result = await window.axios.post(
      '/v1/AnalyticProject/CostSavingsGoal/chartdata',
      data
    );
    var status = result.data;
    return status;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  getAnalyticProject,
  getAnalyticProjectForImpactReport,
  getAnalyticProjects,
  updateAnalyticProject,
  createAnalyticProject,
  deleteAnalyticProject,
  getAnalyticModel,
  getAnalyticModels,
  analyzeEnergySavings,
  getSites,
  getMeasures,
  getNREs,
  getMonthlyCostSavingsForImpactReport,
  getMonthlyEnergyAdjustmentForImpactReport,
  getAnalyticProjectMeasures,
  downloadNewYearlySpreadsheet,
  downloadYearlySpreadsheet,
  uploadYearlySpreadsheet,
  getChartData,
};
