const STRATEGY_TEMPLATES_LIST = (state, strategyTemplatesList) => {
  state.strategyTemplatesList = strategyTemplatesList;
};

const STRATEGY_TEMPLATE_SEARCH_TERM = (state, strategyTemplateSearchTerm) => {
  state.strategyTemplateSearchTerm = strategyTemplateSearchTerm;
};

export default {
  STRATEGY_TEMPLATES_LIST,
  STRATEGY_TEMPLATE_SEARCH_TERM,
};
