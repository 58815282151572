const apiVersion = 'v1';

const getPortfolios = async () => {
  try {
    let result = await window.axios.get(`/${apiVersion}/sites/portfolio`);
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPortfolioSites = async () => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/viewsupport/portfolio/sitedata`
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPortfolioSiteById = async (siteId) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/viewsupport/portfolio/sitedetail/${siteId}`
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPortfolioById = async (portfolioId) => {
  var site = await window.axios.get(`/${apiVersion}/sites/${portfolioId}`);
  return site.data.data;
};

const uploadOccupancyCsv = async (formData, siteId) => {
  let result = null;
  try {
    result = await window.axios.post(
      `/${apiVersion}/sensor/sites/${siteId}/occupancy/monthly`,
      formData,
      {
        headers: {
          'X-API-Type': 'sensor',
        },
      }
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPowerBiEmbedConfiguration = async (siteId, reportId) => {
  let response = null;
  try {
    response = await window.axios.get(
      `/${apiVersion}/viewsupport/portfolio/site/${siteId}/embedconfiguration/${reportId}`
    );
    let { token, report, reportParameters } = response.data.data;

    let embedConfiguration = {
      type: 'report',
      tokenType: 1,
      accessToken: token.token,
      embedUrl: report.embedUrl,
      id: report.id,
      settings: {
        filterPaneEnabled: false,
      },
      reportParameters: reportParameters,
    };
    return embedConfiguration;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  uploadOccupancyCsv,
  getPortfolioById,
  getPortfolios,
  getPortfolioSites,
  getPortfolioSiteById,
  getPowerBiEmbedConfiguration,
};
