<template>
  <div class="text-left" @click="close()">
    <v-overlay class="message-overlay" :value="overlay" opacity="0.7">
      <PopUpMessageTypeImage :slides="messagesByUserList" />
    </v-overlay>
  </div>
</template>
<script>
import PopUpMessageTypeImage from '@/Core.Service.Domain/MessageCenter/Users/Components/PopUpMessageTypeImage.vue';

export default {
  name: 'PopUpMessageOverlay',

  props: {
    messagesByUserList: {
      type: Array,
    },
  },

  components: {
    PopUpMessageTypeImage,
  },

  data() {
    return {
      overlay: true,
    };
  },

  watch: {
    $route(v) {
      if (v.name !== 'MessageCenterMyMessages') {
        this.overlay = true;
      } else {
        this.overlay = false;
      }
    },
  },

  methods: {
    close() {
      const { name } = this.$route;
      if (name !== 'MessageCenterMyMessages') {
        this.overlay = false;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.message-overlay
  z-index: 9999 !important

.container
  position: relative
  text-align: center
  color: white

.v-image
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0))) !important

.centered
  position: absolute
  bottom: 1px
  // top: 50%
  left: 50%
  transform: translate(-50%, 0)
</style>
