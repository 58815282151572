const sitesWithControls = (state) => state.sitesWithControls;
const selectedSiteControls = (state) => state.selectedSiteControls;
const controlCurrentRoute = (state) => state.controlCurrentRoute;
const getControlSearchTerm = (state) => state.controlSearchTerm;
const getControlSettingsHistoryMessagesTimerOn = (state) =>
  state.controlSettingsHistoryMessagesTimerOn;

export default {
  sitesWithControls,
  selectedSiteControls,
  controlCurrentRoute,
  getControlSearchTerm,
  getControlSettingsHistoryMessagesTimerOn,
};
