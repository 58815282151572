<template>
  <div>
    <v-app-bar fixed app height="88" color="white">
      <v-toolbar-title class="headline text-uppercase">
        <v-img
          class="mt-2"
          :class="{ 'ml-n10': smallBreakpoint }"
          src="./images/E360-Blue-with-Logo.svg"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <MessageCenterUserButton @openMessagesCenter="dialog = true" />
      <!-- <BreadCrumbs /> -->
      <WarningBanner v-if="showWarningBanner" />
    </v-app-bar>
    <MessageCenterDialog :dialog="dialog" @close="dialog = false" />
  </div>
</template>
<script>
// const BreadCrumbs = () => import('@/Core.UI.Domain/Components/BreadCrumbs.vue')
const WarningBanner = () =>
  import('@/Core.UI.Domain/Components/WarningBanner.vue');

const MessageCenterDialog = () =>
  import(
    '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterDialog.vue'
  );

const MessageCenterUserButton = () =>
  import(
    '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterUserButton.vue'
  );

export default {
  name: 'AppBar',

  components: {
    // BreadCrumbs,
    WarningBanner,
    MessageCenterDialog,
    MessageCenterUserButton,
  },

  data: () => ({
    showWarningBanner: false,
    dialog: false,
  }),
};
</script>
