//Sensor Readings
    //Site Slicer
    //Date Slicer
    //End Use Slicer
    //UOM Slicer
import cons from "../constants";

const setSlicers = async function (reportParameters, visuals) {
    await setSiteSlicerVisual(reportParameters, visuals);
    await setDateSlicerVisual(reportParameters, visuals);
    //await setEndUseSlicerVisual(reportParameters, visuals);
},
  
setSiteSlicerVisual = async function(reportParameters, visuals) {
    const siteSlicerFilter = {
        $schema: cons.schemaUrlAdv,
        target: {
        table: "vwSite",
        column: "SiteName",
        },
        operator: "In",
        values: [reportParameters.SiteName],
    };
    let siteSlicerVisual = visuals.filter(function (visual) {
        return visual.type === "slicer" && visual.title === "Site Slicer";
    })[0];

    if (siteSlicerVisual) {
        // Set the slicer state which contains the slicer filters.
        await siteSlicerVisual.setSlicerState({
            filters: [siteSlicerFilter],
        });
    }
},

setDateSlicerVisual = async function(reportParameters, visuals) {
    const dateSlicerFilter = {
        $schema: cons.schemaUrlAdv,
        target: {
        table: "vwReportingDateTime",
        column: "Date",
        },
        logicalOperator: "And",
        conditions: [
            {
                operator: "GreaterThanOrEqual",
                value: reportParameters.StartDate,
            },
            {
                operator: "LessThanOrEqual",
                value: reportParameters.EndDate,
            },
        ],
    };

    let dateSlicerVisual = visuals.filter(function (visual) {
        return visual.type === "slicer" && visual.title === "Date Slicer";
    })[0];

    if (dateSlicerVisual) {
        await dateSlicerVisual.setSlicerState({
        filters: [dateSlicerFilter],
        });
        console.log("Date slicer was set.");
    }
}

// setEndUseSlicerVisual = async function(reportParameters, visuals) {
//     console.log(reportParameters)
//     const endUseSlicerFilter = {
//         $schema: cons.schemaUrlTopN,
//         target: {
//             table: "vwSensorNames",
//             column: "SensorName",
//         }, 
//         operator: "Top",
//         itemCount: 1,
//         orderBy: {
//             table: "vwSensorNames",
//             measure: "SensorName"
//          },
//         filterType: powerbi.models.FilterType.TopN
//     };

//     let endUseSlicerVisual = visuals.filter(function (visual) {
//         return (
//         visual.type === "slicer" && visual.title === "End Use Slicer"
//         );
//     })[0];

//     if (endUseSlicerVisual) {
//         await endUseSlicerVisual.setSlicerState({
//             filters: [endUseSlicerFilter],
//         });
//         console.log("End Use slicer was set.");
//     }
// }

export default {
    setSlicers
}