<template>
  <validation-observer ref="obs" v-slot="{ invalid }">
    <v-container grid-list-xl>
      <h1>Project Event Detail</h1>
      <form @submit.prevent="submit">
        <validation-summary :messages="server_errors" />
        <validated-text-field
          rules="required|duplicate_name"
          v-model="event.name"
          label="Name"
        ></validated-text-field>
        <v-row>
          <v-col>
            <validated-select-field
              rules="required"
              :items="siteOptions"
              :readonly="currentMode === mode.EDIT"
              v-model="event.siteId"
              label="Portfolio Site"
              hint="What site do you want to analyze"
              persistent-hint
              @change-value="setSite"
            ></validated-select-field>
          </v-col>
        </v-row>

        <validated-text-field
          rules="required"
          v-model="event.description"
          label="Description"
        ></validated-text-field>
        <validated-select-field
          rules="required"
          :items="typeOptions"
          v-model="event.type"
          label="Event Type"
          hint="select an event type"
          persistent-hint
        ></validated-select-field>
        <start-end-date-picker
          name="date range"
          v-model="dateRange"
          date-format="yyyy-MM-dd"
          rules="required|valid_date_range"
        ></start-end-date-picker>
        <v-row v-if="$can('edit', 'analysis')">
          <v-col>
            <v-btn type="submit" color="primary" :disabled="invalid"
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-container>
  </validation-observer>
</template>

<script>
import ValidationSummary from '@/components/Dialogs/ValidationSummary';
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import ValidatedTextField from '@/components/Fields/ValidatedTextField';
import ValidatedSelectField from '@/components/Fields/ValidatedSelectField';
import { ValidationObserver, Validator } from 'vee-validate';
import api from '../_api';
import errorSummary from '../../../_mixins/error_utilities';

export default {
  components: {
    'validation-summary': ValidationSummary,
    'start-end-date-picker': StartEndDatePicker,
    'validation-observer': ValidationObserver,
    'validated-text-field': ValidatedTextField,
    'validated-select-field': ValidatedSelectField,
  },

  mixins: [errorSummary],

  data() {
    return {
      mode: {
        EDIT: 'edit',
        NEW: 'new',
      },
      server_errors: [],
      dateRange: {
        startDateTime: '2018-10-01',
        endDateTime: '2018-01-03',
      },
      project: {
        projectName: '',
        description: '',
        analyticsModel: {},
        startDateTime: '',
        endDateTime: '',
      },
      typeOptions: [
        {
          text: 'Project Implementation',
          value: 'Project',
        },
        {
          text: 'Non Routine Event',
          value: 'NRE',
        },
      ],
      event: {
        name: 'Fall Gathering',
        description: 'This is the description for the fall gathering',
        eventStart: '2018-10-01',
        eventEnd: '2018-10-03',
        type: 'NRE',
        siteId: 36,
      },
      projectName: '',
      modelOptions: [],
      analyticModels: [],

      currentMode: {},

      sites: [],
      siteOptions: [],
      selectedSite: '36',

      loading: false,
    };
  },

  created() {
    this.setCurrentMode();
    this.getProjects();
    this.setCurrentMode();
    this.registerCustomValidators();
    this.getUserSites();
  },

  mounted() {
    this.setCurrentMode();
    if (this.currentMode === this.mode.EDIT) {
      //this.getProject();
      this.dateRange = {
        startDateTime: this.event.eventStart,
        endDateTime: this.event.eventEnd,
      };
    } else {
      this.dateRange = {
        startDateTime: new Date().toISOString().substring(0, 10),
        endDateTime: new Date().toISOString().substring(0, 10),
      };
      this.clearErrors();
    }
  },

  methods: {
    clearErrors() {
      requestAnimationFrame(() => {
        this.$refs.obs.reset();
      });
    },

    async getUserSites() {
      let self = this;
      api
        .getSites()
        .then((response) => {
          self.sites = response;
          self.siteOptions = self.mapSiteLookup(response);
          self.selectedSite = 36;
        })
        .catch((error) => {
          self.server_errors = self.errorSummary(error);
        });
    },

    async submit() {
      this.project.startDateTime = this.dateRange.startDateTime;
      this.project.endDateTime = this.dateRange.endDateTime;
      // switch (this.currentMode) {
      //   case this.mode.EDIT:
      //     this.update();
      //     break;

      //   default:
      //     this.create();
      //     break;
      // }
    },

    async create() {
      let self = this;
      let valid = await this.$refs.obs.validate();

      if (valid) {
        this.axios
          .post(
            '/v1/analyticmodel/sites/' + self.selectedSite,
            self.project
          )
          .then((response) => {
            //show success toast
            self.projectName = self.project.projectName;
            console.log(response);
            this.$toast.show('Project created.', null, 'success');
          })
          .catch((error) => {
            self.server_errors = self.errorSummary(error);
          });
      }
    },

    async update() {
      let self = this;
      let valid = await this.$refs.obs.validate();

      if (valid) {
        self.server_errors = [];
        this.axios
          .put(
            '/v1/analyticmodel/sites/' + self.selectedSite,
            self.project
          )
          .then((response) => {
            //show success toast
            self.projectName = self.project.projectName;
            console.log(response);
            this.$toast.show('Project updated.', null, 'success');
          })
          .catch((error) => {
            let errs = self.errorSummary(error);
            console.log(errs);
            self.server_errors = errs;
          });
      }
    },

    getProject() {
      const self = this;
      this.loading = true;
      api
        .getAnalyticProject(self.$route.params.id)
        .then((response) => {
          self.project = this.mapProject(response);
          self.projectName = response.projectName;
          self.selectedSite = response.site.id;
          //self.getModels();
          this.dateRange = {
            startDateTime: self.project.startDateTime,
            endDateTime: self.project.endDateTime,
          };
          if (self.currentMode === self.mode.EDIT) {
            self.setCurrentModel(response.analyticsModel.id);
          }
        })
        .catch((error) => {
          self.server_errors = self.errorSummary(error);
        })
        .finally(() => (self.loading = false));
    },

    async getProjects() {
      try {
        let response = await api.getPortfolioAnalyticProjects();
        this.projects = response;
        this.clearErrors();
      } catch (error) {
        this.server_errors = self.errorSummary(error);
      }
    },

    // mapModelLookup(models) {
    //   const makeOptionItems = item => {
    //     return {
    //       value: item.id,
    //       text: item.name
    //     };
    //   };
    //   let ml = models.map(makeOptionItems);
    //   return ml;
    // },

    mapSiteLookup(sites) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.name,
        };
      };
      var sl = sites.map(makeOptionItems);
      return sl;
    },

    mapProject(proj) {
      return {
        id: proj.id,
        projectName: proj.projectName,
        description: proj.description,
        analyticsModelId: proj.analyticsModel.id,
        startDateTime: new Date(proj.startDateTime)
          .toISOString()
          .substring(0, 10),
        endDateTime: new Date(proj.endDateTime).toISOString().substring(0, 10),
      };
    },

    setCurrentMode() {
      this.currentMode =
        this.$route.params.id === 'new' ? this.mode.NEW : this.mode.EDIT;
    },

    setSite(site) {
      console.log('site: ' + site);
    },

    registerCustomValidators() {
      let self = this;
      Validator.extend('duplicate_name', {
        // Custom validation message
        getMessage: (field) =>
          `${field} is already used by another project.  Enter a different name.`,
        // Custom validation rule
        validate: (value) =>
          new Promise((resolve) => {
            let valid = true;
            if (self.projects) {
              let existingNames = self.projects.map((a) => a.projectName);
              //filter project name out of list if in edit
              if (self.currentMode === self.mode.EDIT) {
                existingNames = existingNames.filter((n) => {
                  return n !== self.projectName;
                });
              }
              valid = existingNames.indexOf(value) === -1;
            }

            resolve({
              valid: valid,
            });
          }),
      });
    },

    // setCurrentModel(id) {
    //   let cm = {};

    //   try {
    //     cm = this.analyticModels.find(m => {
    //       return m.id === id;
    //     });
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   if (cm) {
    //     cm.startDateTime = new Date(cm.startDateTime)
    //       .toISOString()
    //       .substring(0, 10);
    //     cm.endDateTime = new Date(cm.endDateTime)
    //       .toISOString()
    //       .substring(0, 10);
    //     this.currentModel = cm;
    //   }
    // },

    // setDefaultDateRange(id) {
    //   if (this.loading !== true) {
    //     this.setCurrentModel(id);

    //     this.dateRange = {
    //       startDateTime: this.currentModel.startDateTime,
    //       endDateTime: this.currentModel.endDateTime
    //     };
    //   }
    // }
  },
};
</script>
