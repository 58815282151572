<template>
  <section>
    <v-row>
      <v-col v-if="$can('create', 'sites')">
        <v-btn color="primary" to="/site/create">
          <v-icon left>mdi-plus-thick</v-icon>New Site
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-text-field v-model="search" label="Search" solo></v-text-field>
      </v-col>
    </v-row>

    <!-- </v-row> -->
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>
    <v-data-table
      :headers="headers"
      :items="sites"
      :hide-default-footer="sites.length <= 10"
      :search="search"
      :options="tableOptions"
      class="elevation-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.floors }}</td>
          <td>{{ item.occupants }}</td>
          <td>{{ item.squareFootage }}</td>
          <td>{{ item.kwhCost }}</td>
          <td>{{ item.latitude }}</td>
          <td>{{ item.longitude }}</td>
          <td>
            <v-btn :to="`/site/edit/${item.siteId}`" icon>
              <v-icon medium>edit</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <span class="px-4 text-caption primary--text">
      {{ $appOldVersion }}
    </span>
  </section>
</template>

<script>
export default {
  name: 'SiteIndex',
  async created() {
    let response = await this.$http.get('/v1/sites')
    response.data.data.forEach((site) => {
      site.latitude = Number(site.latitude).toFixed(5)
      site.longitude = Number(site.longitude).toFixed(5)
    })
    this.sites = response.data.data
    this.loading = false
  },

  data() {
    return {
      loading: true,
      search: '',
      headers: [
        { text: 'Site Name', value: 'name' },
        { text: 'Floors', value: 'floors' },
        { text: 'Occupants', value: 'occupants' },
        { text: 'Square Footage', value: 'squareFootage' },
        { text: 'kWh Cost', value: 'kwhCost' },
        { text: 'Latitude', value: 'latitude' },
        { text: 'Longitude', value: 'longitude' },
        { text: 'Actions', value: 'id', sortable: false },
      ],
      tableOptions: { itemsPerPage: 10 },
      sites: [],

      toggleView: 0,
    }
  },
}
</script>
