<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="portfolio-bar d-flex flex-row"
    class="portfolio-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <v-toolbar dense :width="toolBarWidth">
        <v-text-field
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'PortfolioBar',

  data() {
    return {
      toolBarHeight: 50,
      toolBarWidth: 995,
    }
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Site'
    },
  },

  methods: {
    ...mapMutations({
      setSiteSearchTerm: 'ViewSupportStore/PortfolioStore/SITE_SEARCH_TERM',
    }),

    handleSearchInput(value) {
      this.setSiteSearchTerm(value)
    },
  },
}
</script>
<style lang="sass" scoped>
.portfolio-bar
  position: fixed
  top: 100px
  z-index: 1
</style>
