<template>
  <v-banner single-line transition="slide-y-transition">
    <template v-slot:icon>
      <v-icon color="warning" size="36"> mdi-map-marker-alert </v-icon>
    </template>
    <span class="primary--text font-weight-bold text-h6">
      {{ message }}
    </span>
    <template v-slot:actions="{ dismiss }" v-if="showDismiss">
      <v-btn text color="primary" @click="dismiss"> Dismiss </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: 'BannerAlert',

  props: {
    message: {
      type: String,
      default: '',
    },

    showDismiss: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
