import actions from '@/Core.Service.Domain/Monitors/Aginova/Store/actions';
import getters from '@/Core.Service.Domain/Monitors/Aginova/Store/getters';
import mutations from '@/Core.Service.Domain/Monitors/Aginova/Store/mutations';

const state = {
  aginovaMonitorsCurrentRoute: '',
  sitesForAginovaMonitors: [],
  selectedSiteWithAginovaMonitors: [],
  selectedSiteWithAginovaMonitorsIsLoading: false,
  aginovaMonitorsSearchTerm: '',
  selectedAginovaMonitor: {},
  selectedAginovaMonitorIsLoading: false,
  siteLocationsForAginovaMonitor: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
