const SITES_WITH_ANALYTICS_MODELS = (state, sitesWithAnalyticsModels) => {
  state.sitesWithAnalyticsModels = sitesWithAnalyticsModels;
};

const SELECTED_SITE_ANALYTICS_MODELS = (state, selectedSiteAnalyticsModels) => {
  state.selectedSiteAnalyticsModels = selectedSiteAnalyticsModels;
};

const ANALYTICS_MODELS_CURRENT_ROUTE = (state, analyticsModelsCurrentRoute) => {
  state.analyticsModelsCurrentRoute = analyticsModelsCurrentRoute;
};

const SELECTED_SITE_ANALYTICS_MODELS_IS_LOADING = (
  state,
  selectedSiteAnalyticsModelsIsLoading
) => {
  state.selectedSiteAnalyticsModelsIsLoading = selectedSiteAnalyticsModelsIsLoading;
};

const ANALYTICS_MODEL_SEARCH_TERM = (state, analyticsModelsSearchTerm) => {
  state.analyticsModelsSearchTerm = analyticsModelsSearchTerm;
};

export default {
  SITES_WITH_ANALYTICS_MODELS,
  SELECTED_SITE_ANALYTICS_MODELS,
  ANALYTICS_MODELS_CURRENT_ROUTE,
  SELECTED_SITE_ANALYTICS_MODELS_IS_LOADING,
  ANALYTICS_MODEL_SEARCH_TERM,
};
