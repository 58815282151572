import Reports from '@/Core.Service.Domain/ViewSupport/Reports/Views';
import ReportDetails from '@/modules/reports/_components/Details';
import ReportFilterParams from '@/modules/reports/_components/Details';

const routesList = [];

const ReportDetailsRoute = {
  path: '/reports/:reportName',
  name: 'ReportDetailsRoute',
  component: ReportDetails,
};

const ReportFilterParamsRoute = {
  path: '/portfolio/reports/:reportName/site/:siteId',
  name: 'PortfolioReportRoute',
  component: ReportFilterParams,
};

const ReportsRoute = {
  path: '/reports',
  name: 'Reports',
  component: Reports,
  meta: { permission: 'reports:read' },
};

routesList.push(ReportsRoute, ReportDetailsRoute, ReportFilterParamsRoute);

export default routesList;
