const SITES_WITH_STRATEGIES = (state, sitesWithStrategies) => {
  state.sitesWithStrategies = sitesWithStrategies;
};

const SELECTED_SITE_STRATEGIES = (state, selectedSiteStrategies) => {
  state.selectedSiteStrategies = selectedSiteStrategies;
};

const STRATEGY_CURRENT_ROUTE = (state, strategyCurrentRoute) => {
  state.strategyCurrentRoute = strategyCurrentRoute;
};

const SELECTED_SITE_STRATEGIES_IS_LOADING = (
  state,
  selectedSiteStrategiesIsLoading
) => {
  state.selectedSiteStrategiesIsLoading = selectedSiteStrategiesIsLoading;
};

const STRATEGY_SEARCH_TERM = (state, strategySearchTerm) => {
  state.strategySearchTerm = strategySearchTerm;
};

export default {
  SITES_WITH_STRATEGIES,
  SELECTED_SITE_STRATEGIES,
  STRATEGY_CURRENT_ROUTE,
  SELECTED_SITE_STRATEGIES_IS_LOADING,
  STRATEGY_SEARCH_TERM,
};
