import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
const apiVersion = 'v1';

const getAnalyticsProjectsBySiteId = async (site) => {
  const { id } = RemapObjectKeysAdapter(site, ['siteId'], ['id']);

  try {
    const analyticsProjectsResponse = await window.axios.get(
      `/${apiVersion}/AnalyticProject/site/${id}`
    );
    if (analyticsProjectsResponse && analyticsProjectsResponse.data) {
      return analyticsProjectsResponse.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getSitesWithAnalyticsProjects = async () => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/AnalyticProject/site/sites`
    );

    const { data } = result.data;
    data.forEach((site) => {
      RemapObjectKeysAdapter(site, ['siteId'], ['id']);
    });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteAnalyticsProject = async (id) => {
  try {
    const result = await window.axios.delete(
      `/${apiVersion}/AnalyticProject/Delete/${id}`
    );
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getSitesWithAnalyticsProjects,
  getAnalyticsProjectsBySiteId,
  deleteAnalyticsProject,
};
