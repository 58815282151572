const Benchmark = "Benchmark";
const BillEndUseUsage = "Energy Billing Report";
const CO2 = "CO2 Report";
const DashboardSubmeterV2 = "Submeter Dashboard V2";
const DashboardUtilityData = "Dashboard Utility Data";
const DashboardUtilityElectricity = "Dashboard Utility Electricity";
const EndUse = "End Use Report";
const HourlyEndUse = "Hourly End Use Report";
const IndoorAirQuality = "Indoor Air Quality";
const LoadUsage = "Energy Billing Report";
const MonthlyUtility = "Monthly Utility Report";
const NOC = "NOC Report";
const PowerQuality = "Power Quality";
const Sensor = "Sensor Report";
const SiteStartTimeAnalytics = "Site Start Time Analtycs";
const TenentBilling = "Tenent Billing";
const TenantBillingWideFormat ="Tenant Billing Wide Format";

export default {
    Benchmark,
    BillEndUseUsage,
    CO2,
    DashboardSubmeterV2,
    DashboardUtilityData,
    DashboardUtilityElectricity,
    EndUse,
    HourlyEndUse,
    IndoorAirQuality,
    LoadUsage,
    MonthlyUtility,
    NOC,
    PowerQuality,
    Sensor,
    SiteStartTimeAnalytics,
    TenentBilling,
    TenantBillingWideFormat
}