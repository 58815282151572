<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="!defaultSite">
        <ProgressLinearLoaderVue :isLoading="true" />
      </v-col>
      <v-col>
        <NotificationsBar v-show="defaultSite" />
        <NotificationsList v-if="defaultSite" class="mt-10" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificationsBar from '@/Core.Service.Domain/ViewSupport/Notifications/Components/NotificationsBar.vue'

import NotificationsList from '@/Core.Service.Domain/ViewSupport/Notifications/Components/NotificationsList.vue'

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue'

export default {
  name: 'NotificationsView',

  components: {
    NotificationsList,
    NotificationsBar,
    ProgressLinearLoaderVue,
  },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
    }),
  },
}
</script>
