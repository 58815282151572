<template>
  <v-container fluid>
    <v-row class="mt-9">
      <v-col>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'StrategiesView',
};
</script>