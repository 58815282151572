<template>
  <v-container fluid>
    <h2 class="primary--text mb-2">Message Center - Management</h2>
    <v-card class="pa-2">
      <v-row>
        <v-col cols="6">
          <router-view />
        </v-col>
        <v-col cols="6">
          <MessageCenterSentMessagesList />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import MessageCenterSentMessagesList from '@/Core.Service.Domain/MessageCenter/Management/Components/MessageCenterSentMessagesList.vue';

import { mapActions } from 'vuex';

export default {
  name: 'MessagesCenterView',

  components: {
    MessageCenterSentMessagesList,
  },

  async created() {
    await this.getUsersListForMessageCenter();
  },

  methods: {
    ...mapActions({
      getUsersListForMessageCenter:
        'MessageCenterManagementStore/getUsersListForMessageCenter',
    }),
  },
};
</script>
