const ThermostatsPortfolio = () =>
  import('@/Core.Service.Domain/Controls/Thermostats/Views');

export const ThermostatsRoute = {
  path: 'thermostats',
  name: 'Thermostats',
  component: ThermostatsPortfolio,
  children: [
    {
      path: 'history/:id',
      name: 'ThermostatHistory',
    },
  ],
  meta: {
    placeholder: 'Search for a Thermostat',
    controlTypeName: 'Thermostat',
    label: 'Thermostats',
    toolTip: 'Thermostat',
  },
};
