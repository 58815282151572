export default {
  methods: {
    errorSummary(error) {
      return error.response
        ? error.response.data.Errors
          ? error.response.data.Errors
          : error.response.data.errors ? error.response.data.errors : [error]
        : [error];
    }
  }
}