<template>
  <v-container fluid class="ml-n6 mt-n11 pr-0">
    <v-row>
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col v-else>
        <BannerAlertVue
          v-if="!selectedSiteControls.length || !powerControlsList.length"
          :message="bannerMessage"
        />
        <PowerControlsListVue
          v-else
          :PowerControlsList="powerControlsList"
          @delete-power-control="handleDataChange"
          @set-power-control-config="handleDataChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@/Core.Service.Domain/Controls/PowerControls/API'

const PowerControlsListVue = () =>
  import(
    '@/Core.Service.Domain/Controls/PowerControls/Components/PowerControlsList'
  )
const ProgressLinearLoaderVue = () =>
  import('@/Core.UI.Domain/Components/ProgressLinearLoader.vue')
const BannerAlertVue = () =>
  import('@/Core.UI.Domain/Components/BannerAlert.vue')

export default {
  name: 'PowerControlsPortfolio',

  components: { PowerControlsListVue, ProgressLinearLoaderVue, BannerAlertVue },

  computed: {
    ...mapGetters({
      selectedSiteControls: 'ControlsStore/selectedSiteControls',
      controlCurrentRoute: 'ControlsStore/controlCurrentRoute',
      getControlSettingsHistoryMessagesTimerOn:
        'ControlsStore/getControlSettingsHistoryMessagesTimerOn',
      defaultSite: 'session/defaultSite',
    }),
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`
    },
  },

  data() {
    return {
      interval: null,
      isLoading: true,
      powerControlsList: [],
      timeInterval: 120000,
    }
  },

  watch: {
    controlCurrentRoute() {
      this.handleDataChange()
    },
    defaultSite() {
      this.handleDataChange()
    },
    getControlSettingsHistoryMessagesTimerOn(on) {
      if (on) {
        clearInterval(this.interval)
      } else {
        this.interval = setInterval(() => {
          this.handleDataChange()
        }, this.timeInterval)
      }
    },
  },

  created() {
    this.interval = setInterval(() => {
      this.handleDataChange()
    }, this.timeInterval)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    async getCachedPowerControls() {
      try {
        const powerControls = localStorage.getItem(
          'local-storage-power-controls'
        )
        let filteredPowerControls = []

        if (powerControls) {
          const powerControlsArray = JSON.parse(powerControls)
          const currentTime = Math.floor(Date.now() / 1000)

          filteredPowerControls = powerControlsArray.filter((powerControl) => {
            const timeDiff = currentTime - powerControl.updatedAt
            return timeDiff < 300
          })
        }

        const { id } = this.defaultSite
        const powerControlsLatestUsage = await API.getPowerControlLatestUsage({
          id,
        })

        if (filteredPowerControls.length) {
          filteredPowerControls.forEach((filteredPowerControl) => {
            powerControlsLatestUsage.forEach((responsePowerControl) => {
              if (filteredPowerControl.id === responsePowerControl.controlId) {
                Object.assign(responsePowerControl, filteredPowerControl)
              }
            })
          })
        } else {
          localStorage.removeItem('local-storage-power-controls')
        }
        return powerControlsLatestUsage
      } catch (error) {
        console.error('Error getting power controls:', error)
        return null
      }
    },

    async handleDataChange() {
      this.isLoading = true
      try {
        this.powerControlsList = await this.getCachedPowerControls()
        this.mapPowerControlsList()
      } catch (error) {
        console.error(error)
      }
    },
    mapPowerControlsList() {
      this.powerControlsList.forEach((powerControl) => {
        this.selectedSiteControls.forEach((control) => {
          if (powerControl.controlId === control.id) {
            powerControl.controlInfo = Object.assign({}, control)
          }
        })
      })
      this.powerControlsList = this.powerControlsList.filter(
        (powerControl) => powerControl.controlInfo
      )

      this.isLoading = false
    },
  },
}
</script>
