//Portfolio
    //
//Site Details
    //Site Slicer
    //Date Slicer
//Heatmap
    //Site Slicer
    //Date Slicer
    import cons from "../constants";

    const setSlicers = async function (reportParameters, visuals) {
      await setSiteSlicerVisual(reportParameters, visuals);
      await setDateSlicerVisual(reportParameters, visuals);
    },
      
    setSiteSlicerVisual = async function(reportParameters, visuals) {
      const siteSlicerFilter = {
          $schema: cons.schemaUrlAdv,
          target: {
          table: "vwSite",
          column: "SiteName",
          },
          operator: "In",
          values: [reportParameters.SiteName],
      };
    
      let siteSlicerVisual = visuals.filter(function (visual) {
          return visual.type === "slicer" && visual.title === "Site Slicer";
      })[0];
    
      if (siteSlicerVisual) {
          // Set the slicer state which contains the slicer filters.
          await siteSlicerVisual.setSlicerState({
              filters: [siteSlicerFilter],
          });
      }
    },
      
    setDateSlicerVisual = async function(reportParameters, visuals) {
      const dateSlicerFilter = {
        $schema: cons.schemaUrlAdv,
        target: {
          table: "vwReportingDateTime",
          column: "Date",
        },
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: reportParameters.StartDate,
          },
          {
            operator: "LessThanOrEqual",
            value: reportParameters.EndDate,
          },
        ],
      };
    
      let dateSlicerVisual = visuals.filter(function (visual) {
        return visual.type === "slicer" && visual.title === "Date Slicer";
      })[0];
    
      if (dateSlicerVisual) {
        await dateSlicerVisual.setSlicerState({
          filters: [dateSlicerFilter],
        });
        console.log("Date slicer was set.");
      }
    }
    
    export default {
      setSlicers
    }