import { updateSiteContactMethodResourceModel } from "./updateSiteContactMethodResourceModel";

export class updateSiteContactResourceModel {
  constructor(siteContactName = '', siteContactTitle = '', updateSiteContactMethodResourceModels = [new updateSiteContactMethodResourceModel()]) {
    this.siteContactName = siteContactName;
    this.siteContactTitle = siteContactTitle;
    this.updateSiteContactMethodResourceModels = updateSiteContactMethodResourceModels;
  }
}

