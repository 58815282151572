import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
import API from '@/Core.Service.Domain/Monitors/Aginova/API';

const getAginovaMonitorsBySiteId = async (context, { selectedSite }) => {
  const { id } = RemapObjectKeysAdapter(selectedSite, ['siteId'], ['id']);

  context.commit('SELECTED_SITE_AGINOVA_MONITORS_IS_LOADING', true);

  const selectedSiteAginovaMonitors = await API.getAginovaMonitorsBySiteId(id);

  context.commit('SELECTED_SITE_AGINOVA_MONITORS', selectedSiteAginovaMonitors);
  context.commit('SELECTED_SITE_AGINOVA_MONITORS_IS_LOADING', false);
};

const getSitesForAginova = async (context) => {
  const sitesForAginovaMonitors = await API.getSitesForAginova();
  context.commit('SITES_FOR_AGINOVA_MONITORS', sitesForAginovaMonitors);
};

const aginovaMonitorByDeviceId = async (context, deviceId) => {
  context.commit('SELECTED_AGINOVA_MONITOR_IS_LOADING', true);

  try {
    const aginovaMonitor = await API.getAginovaMonitorByDeviceId(deviceId);
    context.commit('SELECTED_AGINOVA_MONITOR', aginovaMonitor);
    return aginovaMonitor;
  } finally {
    context.commit('SELECTED_AGINOVA_MONITOR_IS_LOADING', false);
  }
};

const getSiteLocationsForAginovaMonitor = async (context, siteId) => {
  context.commit('SELECTED_AGINOVA_MONITOR_IS_LOADING', true);

  const siteLocationsForAginovaMonitor = await API.getSiteLocationsForAginovaMonitor(
    siteId
  );
  context.commit(
    'SITE_LOCATIONS_FOR_AGINOVA_MONITOR',
    siteLocationsForAginovaMonitor
  );
  context.commit('SELECTED_AGINOVA_MONITOR_IS_LOADING', false);
};

export default {
  getAginovaMonitorsBySiteId,
  getSitesForAginova,
  aginovaMonitorByDeviceId,
  getSiteLocationsForAginovaMonitor,
};
