const mutations = {
    setLayout(state, layout) {
        state.layout = layout;
    },

    setActivePortfolio(state, portfolio) {
        state.activePortfolio = portfolio;
    }
};

const actions = {
    setLayout({ commit }, layout) {
        commit('setLayout', layout);
    },

    setActivePortfolio({ commit }, portfolio) {
        commit('setActivePortfolio', portfolio);
    }
};

const getters = {
    layout(state) {
        return state.layout;
    },

    getActivePortfolio(state) {
        return state.activePortfolio;
    }
};

const state = {
    layout: "layout",
    activePortfolio: null,
};

export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}