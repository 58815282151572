import getters from '@/Core.Service.Domain/ViewSupport/Reports/Store/getters.js';
import mutations from '@/Core.Service.Domain/ViewSupport/Reports/Store/mutations.js';

export default {
  namespaced: true,
  state: {
    reportSearchTerm: '',
  },
  getters,
  mutations,
};
