<template>
  <v-card class="rounded-lg" height="100%" :width="cardWidth" elevation="24">
    <!--Card Header-->
    <v-app-bar color="primary" height="88" flat>
      <v-container>
        <v-row>
          <v-col class="d-flex ml-n3 mb-n2" cols="10">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title
                  class="aliceBlue--text font-weight-bold mr-auto text-h5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="white" class="mr-n1 mt-n2" left>
                    mdi-lightbulb-multiple-outline
                  </v-icon>
                  {{ StrategyInfo.name }}
                </v-toolbar-title>
              </template>
              <span>{{ StrategyInfo.name }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex ml-n3 mt-n2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="aliceBlue--text text-caption text-truncate"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ StrategyInfo.description }}
                </span>
              </template>
              <span>{{ StrategyInfo.description }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <!--Card Body-->
    <StrategyTemplateCardItemsList :StrategyInfo="StrategyInfo" />
    <!--Card Footer-->
    <v-divider />
    <v-card-actions v-if="userCanUpdate">
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteStrategyTemplate
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :strategyInfo="StrategyInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-card>
</template>

<script>
const ActionButtonsVue = () =>
  import('@/Core.UI.Domain/Components/ActionButtons')
const StrategyTemplateCardItemsList = () =>
  import(
    '@/Core.Service.Domain/Strategies/StrategyTemplates/Components/StrategyTemplateCardItemsList'
  )
const ConfirmDialogDeleteStrategyTemplate = () =>
  import(
    '@/Core.Service.Domain/Strategies/StrategyTemplates/Components/ConfirmDialogDeleteStrategyTemplate.vue'
  )

export default {
  name: 'StrategyTemplateCard',

  components: {
    ActionButtonsVue,
    StrategyTemplateCardItemsList,
    ConfirmDialogDeleteStrategyTemplate,
  },

  props: {
    StrategyInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    userCanUpdate() {
      return this.$can('update', 'controls')
    },
  },

  data(instance) {
    return {
      showConfirmDialog: false,
      cardWidth: 360,
      isDirty: {
        isActive: false,
      },

      StrategyStatus: {
        isActive: instance.StrategyInfo.isActive,
      },

      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: () => {
            // this route will be replaced when the new edit page is ready
            instance.$router.push({
              name: 'StrategyTemplatesEdit',
              params: {
                strategyTemplateId: instance.StrategyInfo.id,
              },
            })
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          action: () => {
            instance.showConfirmDialog = true
          },
        },
      ],
    }
  },
}
</script>
