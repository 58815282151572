<template>
  <validation-observer
    ref="obs"
    v-slot="{ invalid }"
  >
    <v-container grid-list-xl>
      <h1>Measure Detail</h1>
      <form @submit.prevent="submit">
        <v-sheet
          elevation="2"
          class="pa-4"
        >
          <validation-summary :messages="server_errors" />
          <validated-text-field
            rules="required"
            v-model="measure.measureName"
            label="Name"
          ></validated-text-field>
          <validated-text-field
            v-model="measure.measureCode"
            label="Code"
          ></validated-text-field>
          <v-row>
            <v-col>
              <validated-text-field
                v-model="measure.eul"
                type="number"
                step="0.01"
                min="0.00"
                label="EUL"
              ></validated-text-field>
            </v-col>
            <v-col>
              <validated-text-field
                v-model="measure.nTGValue"
                type="number"
                step="0.01"
                min="0.00"
                label="NTG Value"
              ></validated-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validated-select-field
                :items="measureTypes"
                v-model="measure.measureType"
                label="Measure Type"
                hint="select a type for this measure"
                persistent-hint
                item-text="name"
                item-value="name"
                :loading="loading"
              ></validated-select-field>
            </v-col>

            <v-col>
              <validated-select-field
                :items="installTypes"
                v-model="measure.installType"
                label="Install Type"
                hint="select an install type for this measure"
                persistent-hint
                item-text="name"
                item-value="name"
                :loading="loading"
              ></validated-select-field>
            </v-col>

            <v-col>
              <validated-select-field
                :items="marketSectors"
                v-model="measure.marketSector"
                label="Market Sector"
                hint="select a market sector for this measure"
                persistent-hint
                item-text="name"
                item-value="name"
                :loading="loading"
              ></validated-select-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                v-if="$can('create', 'analytics')"
                type="submit"
                color="primary"
                :disabled="invalid"
              >Save</v-btn>
              <v-btn
                @click="handleCancelCrud"
                class="ml-5"
              >Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </form>
      <span class="px-4 text-caption primary--text">
        {{ $appOldVersion }}
      </span>
    </v-container>
  </validation-observer>
</template>

<script>
import ValidationSummary from "@/components/Dialogs/ValidationSummary";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import { ValidationObserver, Validator } from "vee-validate";
import api from "../_api";
import errorSummary from "../../../_mixins/error_utilities";

export default {
  components: {
    "validation-summary": ValidationSummary,
    "validation-observer": ValidationObserver,
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField
  },

  mixins: [errorSummary],

  data() {
    return {
      mode: {
        EDIT: "edit",
        NEW: "new"
      },
      server_errors: [],
      currentMode: {},
      measure: {},
      measures: [],
      measureTypes: [],
      installTypes: [],
      marketSectors: [],
      loading: false
    };
  },

  created() {
    this.registerCustomValidators();
  },

  async mounted() {
    this.setCurrentMode();
    await this.getMeasureTypes();
    await this.getInstallTypes();
    await this.getMarketSectors();

    // await this.getMeasures();
    if (this.currentMode === this.mode.EDIT) {
      this.getMeasure();
    } else {
      this.clearErrors();
    }
  },

  methods: {
    clearErrors() {
      requestAnimationFrame(() => {
        this.$refs.obs.reset();
      });
    },

    async getMeasure() {
      let self = this;
      api
        .getMeasureById(self.$route.params.measuresId)
        .then(response => {
          self.measure = response;
        })
        .catch(error => {
          self.server_errors = self.errorSummary(error);
        });
    },

    getMeasures() {
      // let self = this;
      // api.getMeasures()
      //   .then(response => {
      //     self.measureTypes = [
      //       ...new Set(
      //         response.map(m => {
      //           return { text: m.name, value: m.name };
      //         })
      //       )
      //     ];
      //   })
      //   .catch(error => {
      //     self.server_errors = self.errorSummary(error);
      //   })
      //   .finally(() => (self.loading = false));
    },

    getMeasureTypes() {
      var self = this;
      api
        .getMeasureTypes()
        .then(response => {
          self.measureTypes = response;
        });
    },

    getInstallTypes() {
      var self = this;
      api
        .getMeasureInstallTypes()
        .then(response => {
          self.installTypes = response;
        });

    },

    getMarketSectors() {
      var self = this;
      api
        .getMeasureMarketSectors()
        .then(response => {
          self.marketSectors = response;
        });
    },

    async submit() {
      switch (this.currentMode) {
        case this.mode.EDIT:
          this.update();
          break;
        default:
          this.create();
          break;
      }
    },

    async create() {
      try {
        let result = await api.addMeasure(this.measure);
        this.$toast.show(`Measure added with id: ${result.id}`, null, "success");
        this.$router.push({ name: "AnalyticsMandVMeasuresIndex" });
      } catch (error) {
        this.$toast.show(error, null, "error");
      }
    },

    async update() {
      try {
        let result = await api.updateMeasure(this.measure);
        this.$toast.show(`Measure with id ${result.id} updated`, null, "success");
        this.$router.push({ name: "AnalyticsMandVMeasuresIndex" });
      } catch (error) {
        this.$toast.show(error, null, "error");
      }
    },

    registerCustomValidators() {
      let self = this;
      Validator.extend("duplicate_name", {
        // Custom validation message
        getMessage: field =>
          `${field} is already used by another measure.  Enter a different name.`,
        // Custom validation rule
        validate: value =>
          new Promise(resolve => {
            let valid = true;
            if (self.projects) {
              let existingNames = self.projects.map(a => a.projectName);
              //filter project name out of list if in edit
              if (self.currentMode === self.mode.EDIT) {
                existingNames = existingNames.filter(n => {
                  return n !== self.projectName;
                });
              }
              valid = existingNames.indexOf(value) === -1;
            }

            resolve({
              valid: valid
            });
          })
      });
    },

    setCurrentMode() {
      this.currentMode =
        this.$route.params.measuresId === "new" || !this.$route.params.measuresId
          ? this.mode.NEW
          : this.mode.EDIT;
    },

    handleCancelCrud() {
      this.$router.push({ name: "AnalyticsMandVMeasuresIndex" });
    }

  }
};
</script>