<template>
  <v-progress-linear :indeterminate="true" />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'CallbackView',
  created() {
    this.handleAuthentication()
      .then(() => {
        const redirect = localStorage.getItem('redirect');
        if (redirect) {
          localStorage.removeItem('redirect');
          this.$router.push({ path: redirect });
        } else {
          this.$router.push('/')
        }

      })
      .catch(() => this.logout());
  },

  methods: mapActions("session", ["handleAuthentication", "logout"]),
};
</script>