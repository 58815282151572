import actions from '@/Core.Service.Domain/Analytics/Measures/Store/actions';
import getters from '@/Core.Service.Domain/Analytics/Measures/Store/getters';
import mutations from '@/Core.Service.Domain/Analytics/Measures/Store/mutations';

const state = {
  measureSearchTerm: '',
  analyticsMeasuresList: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
