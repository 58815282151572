const apiVersion = 'v1';

const updateMonitorSiteLocation = async (siteLocation) => {
  try {
    const result = await window.axios.put(
      `${apiVersion}/SiteLocation/update`,
      siteLocation
    );
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createMonitorSiteLocation = async (siteLocation) => {
  try {
    const result = await window.axios.post(
      `${apiVersion}/SiteLocation/create`,
      siteLocation
    );
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  updateMonitorSiteLocation,
  createMonitorSiteLocation,
};
