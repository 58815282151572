import embed from '../embedReports/dashboardSubmeterEmbed.js';
import slicer from '../slicerReports/dashboardSubmeterSlicer.js';

const embedAndSlice = async function(container, embedConfiguration) {
    console.log('EMBED AND SLICE');
    var pbiEmbed = await embed.getEmbed(container, embedConfiguration);
    try {
      var visuals = await getVisuals(pbiEmbed);
      // Retrieve the target visuals.
      await slicer.setSlicers(embedConfiguration.reportParameters, visuals);
    } catch (error) {
      console.error(error);
    }
  },
  getVisuals = async function(pbiEmbed) {
    var pages = await pbiEmbed.getPages();
    // Retrieve the active page.
    var page = pages.filter(function(page) {
      return page.isActive;
    })[0];
    return await page.getVisuals();
  };

export default {
  embedAndSlice,
};
