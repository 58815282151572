import AnalyticsModelsStore from '@/Core.Service.Domain/Analytics/Models/Store';
import AnalyticsMeasuresStore from '@/Core.Service.Domain/Analytics/Measures/Store';
import AnalyticsProjectsStore from '@/Core.Service.Domain/Analytics/Projects/Store';
import AnalyticsNonRoutineEventsStore from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Store';

export default {
  namespaced: true,
  modules: {
    AnalyticsModelsStore,
    AnalyticsMeasuresStore,
    AnalyticsProjectsStore,
    AnalyticsNonRoutineEventsStore,
  },
};
