<template>
  <validation-observer ref="obs" v-slot="{ invalid }">
    <v-container grid-list-xl>
      <h1>Project Detail</h1>
      <form @submit.prevent="submit">
        <v-sheet elevation="2" class="pa-4">
          <validated-text-field
            rules="required|duplicate_name"
            v-model="project.projectName"
            label="Name"
          ></validated-text-field>

          <validated-text-field
            rules="required"
            v-model="project.description"
            label="Description"
          ></validated-text-field>
          <validated-select-field
            rules="required"
            :items="analyticModels"
            item-text="modelName"
            v-model="selectedModel"
            label="Associated Model"
            hint="select an analysis model for this project"
            persistent-hint
            return-object
            @change-value="setDefaultDateRange"
          ></validated-select-field>
          <v-row>
            <v-col cols="auto" md="6" sm="12">
              <v-autocomplete
                :items="measureOptions"
                item-text="name"
                item-value="id"
                v-model="project.measures"
                label="Select Measure(s)"
                multiple
                persistent-hint
                hint="Select Measure(s) for project"
                ref="measureSelect"
                return-object
                @input="handleSetMeasures"
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
          <start-end-date-picker
            name="date range"
            v-model="dateRange"
            date-format="yyyy-MM-dd"
            rules="required|valid_date_range"
          ></start-end-date-picker>
          <v-row>
            <label class="pa-3">Comments</label>
            <v-textarea
              rows="2"
              clearable
              clear-icon="mdi-close-circle"
              outlined
              auto-grow
              v-model="project.comment"
              class="pr-3"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="invalid"
                >Save</v-btn
              >
              <v-btn
                @click="handleMeasureAssociations"
                color="secondary"
                class="ml-5"
                >Manage Measure Associations</v-btn
              >
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </form>
    </v-container>
    <span class="px-4 text-caption primary--text">
      {{ $appOldVersion }}
    </span>
  </validation-observer>
</template>

<script>
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker'
import ValidatedTextField from '@/components/Fields/ValidatedTextField'
import ValidatedSelectField from '@/components/Fields/ValidatedSelectField'
import { ValidationObserver, Validator } from 'vee-validate'
import api from '../../projects/_api'

export default {
  name: 'EditProject',
  components: {
    'start-end-date-picker': StartEndDatePicker,
    'validation-observer': ValidationObserver,
    'validated-text-field': ValidatedTextField,
    'validated-select-field': ValidatedSelectField,
  },

  data() {
    return {
      currentMode: {},
      dateRange: {},
      project: {
        projectName: '',
        description: '',
        analyticModel: {},
        startDateTime: '',
        endDateTime: '',
        site: {},
        measures: [],
      },
      projectName: '',
      analyticModels: [],
      measureOptions: [],
      selectedModel: {},
      siteOptions: [],
      siteId: null,
      loading: false,
    }
  },

  beforeCreate() {},

  created() {
    //this.getProjects();
    this.registerCustomValidators()
  },

  async mounted() {
    await this.getProject()
    this.clearErrors()
  },

  computed: {},

  methods: {
    clearErrors() {
      requestAnimationFrame(() => {
        this.$refs.obs.reset()
      })
    },

    //PROJECT - START
    getProject() {
      const self = this
      this.loading = true
      api
        .getAnalyticProject(self.$route.params.id)
        .then(async (response) => {
          self.project = this.mapProject(response)
          self.projectName = response.projectName
          self.siteId = response.siteId
          await self.getModels(response.modelId)
          await self.getMeasures()
          await self.getProjects(response.siteId)
          self.selectedMeasures = self.currentMeasure?.projectMeasures
          self.dateRange = {
            startDateTime: response.projectStartDateTime.substring(0, 10),
            endDateTime: response.projectEndDateTime.substring(0, 10),
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (self.loading = false))
    },

    async getProjects(siteId) {
      this.projects = await api.getAnalyticProjects(siteId)
    },

    mapProject(proj) {
      return {
        id: proj.id,
        projectName: proj.projectName,
        description: proj.description,
        analyticModel: { name: proj.modelName, id: proj.modelId },
        startDateTime: new Date(proj.projectStartDateTime)
          .toISOString()
          .substring(0, 10),
        endDateTime: new Date(proj.projectEndDateTime)
          .toISOString()
          .substring(0, 10),
        comment: proj.comment,
        site: proj.site,
        measures: proj.measures,
        nonRoutineEvents: proj.nonRoutineEvents,
      }
    },
    //PROJECT - END

    //MODEL - START
    async getModels(analyticModelId) {
      let self = this
      let siteId = self.siteId
      api
        .getAnalyticModels(siteId)
        .then((response) => {
          self.analyticModels = response
          self.setSelectedModel(analyticModelId)
        })
        .catch((error) => {
          console.error(error)
        })
    },

    mapModelLookup(models) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.name,
        }
      }
      let ml = models.map(makeOptionItems)
      return ml
    },

    setSelectedModel(id) {
      let cm = {}
      try {
        cm = this.analyticModels.find((m) => {
          return m.id === id
        })
      } catch (error) {
        console.error(error)
      }
      if (cm) {
        this.selectedModel = cm
      }
    },
    //MODEL - END

    //MEASURES - START
    async getMeasures() {
      let self = this
      api
        .getMeasures()
        .then((response) => {
          self.measureOptions = this.mapMeasures(response)
        })
        .catch((error) => {
          console.error(error)
        })
    },

    mapMeasures(measures) {
      return measures.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      })
    },

    handleSetMeasures(selectedMeasures) {
      let self = this
      self.project.measures = selectedMeasures.map((x) => {
        return { ...x, selected: true }
      })
    },
    //MEASURES - END

    async submit() {
      this.project.projectStartDateTime = this.dateRange.startDateTime
      this.project.projectEndDateTime = this.dateRange.endDateTime
      this.project.site = {
        id: this.siteId,
      }

      this.project.modelName = this.selectedModel.modelName
      this.project.modelId = this.selectedModel.id

      this.update()
    },

    async update() {
      let valid = await this.$refs.obs.validate()

      if (valid) {
        await api.updateAnalyticProject(this.project)

        this.$toast.show('Project updated.', null, 'success')
        this.$router.push({ name: 'AnalyticsMandVProjectIndex' })
      }
    },

    registerCustomValidators() {
      let self = this
      Validator.extend('duplicate_name', {
        // Custom validation message
        getMessage: (field) =>
          `${field} is already used by another project.  Enter a different name.`,
        // Custom validation rule
        validate: (value) =>
          new Promise((resolve) => {
            let valid = true
            if (self.projects) {
              let existingNames = self.projects.map((a) => a.projectName)
              //filter project name out of list if in edit

              existingNames = existingNames.filter((n) => {
                return n !== self.projectName
              })

              valid = existingNames.indexOf(value) === -1
            }

            resolve({
              valid: valid,
            })
          }),
      })
    },

    setDefaultDateRange(id) {
      if (this.loading !== true) {
        this.setSelectedModel(id)
      }
    },

    handleMeasureAssociations() {
      this.$router.push({
        name: 'AnalyticsMandVProjectMeasureIndex',
        id: this.$route.params.id,
      })
    },

    handleCancelCrud() {
      this.$router.push({ name: 'AnalyticsMandVProjectIndex' })
    },
  },
}
</script>
