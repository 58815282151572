import actions from '@/Core.Service.Domain/Sites/ROIPredictions/Store/actions.js'
import getters from '@/Core.Service.Domain/Sites/ROIPredictions/Store/getters.js'
import mutations from '@/Core.Service.Domain/Sites/ROIPredictions/Store/mutations.js'

const state = {
  roiPredictionsBySite: [],
  roiPredictionsBySiteIsLoading: false,
  roiPredictionBySiteBySummaryId: {},
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
