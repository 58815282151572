<template>
  <v-card class="rounded-lg mt-4">
    <v-container fluid>
      <v-tabs>
        <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab.title }}</v-tab>
        <v-tab-item>
          <ROIPredictionSummaryList :summary="mappedSummary" />
        </v-tab-item>
        <v-tab-item>
          <ROIPredictionUtilityBills :utilityBills="RoiUtilityBills" />
        </v-tab-item>
        <v-tab-item>
          <ROIPredictionCbecs :siteCbecs="SiteCbecs" />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  dateTimeToUSFormatFactory,
  formatNumberToUS,
} from '@/Core.Patterns/Factory';

const ROIPredictionSummaryList = () =>
  import(
    '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionSummaryList.vue'
  );

const ROIPredictionUtilityBills = () =>
  import(
    '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionUtilityBills.vue'
  );

const ROIPredictionCbecs = () =>
  import(
    '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionCbecs.vue'
  );

export default {
  name: 'ROIPredictionSummary',

  components: {
    ROIPredictionSummaryList,
    ROIPredictionUtilityBills,
    ROIPredictionCbecs,
  },

  computed: {
    ...mapGetters({
      roiPredictionBySiteBySummaryId:
        'SitesStore/ROIPredictionsStore/roiPredictionBySiteBySummaryId',
    }),
  },

  created() {
    this.mapROIPredictionBySiteBySummaryId();
  },

  data() {
    return {
      isTherms: false,
      tabs: [
        {
          title: 'ROI Prediction Summary',
          id: 'summary',
        },
        {
          title: 'Utility Bill Details',
          id: 'bills',
        },
        {
          title: 'CBECS',
          id: 'cbecs',
        },
      ],

      mappedSummary: {},
      RoiUtilityBills: [],
      SiteCbecs: [],

      labelMapping: {
        unitOfMeasure: { label: 'Unit Of Measure', format: 'value', group: 0 },
        state: { label: 'State', format: 'value', group: 0 },
        siteType: { label: 'Site Type', format: 'value', group: 0 },
        siteSquareFootage: {
          label: 'Site Square Footage',
          format: 'sq ft',
          group: 0,
        },
        utilityBillsTotalVolume: {
          label: 'Total Volume',
          format: '',
          group: 1,
        },
        utilityBillsTotalCost: {
          label: 'Total Cost',
          format: 'currency',
          group: 1,
          truncDecimals: true,
        },
        utilityBillsTotalUsagePerSquareFoot: {
          label: 'Total Usage Per Square Foot',
          format: '',
          group: 1,
        },
        utilityBillsTotalCostPerSquareFoot: {
          label: 'Total Cost Per Square Foot',
          format: 'currency',
          group: 1,
        },
        utilityBillsMonthlyUsageUtilityRateAverage: {
          label: 'Monthly Usage Utility Rate Average',
          format: '',
          group: 1,
        },
        aggregateUsage: {
          label: 'Aggregate Usage',
          format: '',
          group: 2,
        },
        aggregateCost: {
          label: 'Aggregate Cost',
          format: 'currency',
          group: 2,
        },
        medianUsage: { label: 'Median Usage', format: '', group: 2 },
        euiVsMedianEuiPercentage: {
          label: 'EUI Vs Median EUI',
          format: 'percentage',
          group: 3,
          roundPercentage: true,
        },
        savingsToAchieveMedianEuiPercentage: {
          label: 'Savings To Achieve Median EUI',
          format: 'percentage',
          group: 3,
          roundPercentage: true,
        },
        savingsToAchieveMedianEuiUsagePerYear: {
          label: 'Savings To Achieve Median EUI Usage Per Year',
          format: '',
          group: 3,
          truncDecimals: true,
        },
        savingsVsMedianEuiCostSavingsPerYear: {
          label: 'Savings Vs Median EUI Cost Savings Per Year',
          format: 'currency',
          group: 3,
          truncDecimals: true,
        },
        euiVsAverageEuiPercentage: {
          label: 'EUI Vs Average EUI',
          format: 'percentage',
          group: 4,
          roundPercentage: true,
        },
        archiveAverageEuiPercentage: {
          label: 'Achieve Average EUI',
          format: 'percentage',
          group: 4,
          roundPercentage: true,
        },
        archiveAverageEuiUsagePerYear: {
          label: 'Achieve Average EUI Usage Per Year',
          format: '',
          group: 4,
          truncDecimals: true,
        },
        savingsVsSimilarBuildingsCostSavingsPerYear: {
          label: 'Savings Vs Similar Buildings Cost Savings Per Year',
          format: 'currency',
          group: 4,
          truncDecimals: true,
        },
        benchmarkPercentile: {
          label: 'Benchmark Percentile',
          format: 'percentage',
          group: 5,
          roundPercentage: true,
        },
        aggressiveSavingsUsage: {
          label: 'Aggressive Savings Usage',
          format: '',
          group: 5,
          truncDecimals: true,
        },
        aggressiveSavingsCost: {
          label: 'Aggressive Savings Cost',
          format: 'currency',
          group: 5,
          truncDecimals: true,
        },
        conservativeSavingsUsage: {
          label: 'Conservative Savings Usage',
          format: '',
          group: 5,
          truncDecimals: true,
        },
        conservativeSavingsCost: {
          label: 'Conservative Savings Cost',
          format: 'currency',
          group: 5,
          truncDecimals: true,
        },
        roiPredictionLastModifiedDateTime: {
          label: 'Last Modified',
          format: 'datetime',
          group: 0,
        },
      },
    };
  },

  methods: {
    dateTimeToUSFormatFactory,
    formatNumberToUS,

    mapROIPredictionBySiteBySummaryId() {
      this.RoiUtilityBills = this.roiPredictionBySiteBySummaryId.utilityBills;
      this.SiteCbecs = this.roiPredictionBySiteBySummaryId.cbecs;

      this.mappedSummary = { ...{}, ...this.roiPredictionBySiteBySummaryId };

      this.labelMapping.savingsToAchieveMedianEuiUsagePerYear.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      this.labelMapping.utilityBillsTotalVolume.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      this.labelMapping.utilityBillsTotalUsagePerSquareFoot.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      this.labelMapping.utilityBillsMonthlyUsageUtilityRateAverage.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      this.labelMapping.aggregateUsage.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      this.labelMapping.medianUsage.format = this.formatSavingsByUnitOfMeasure(
        this.mappedSummary.unitOfMeasure
      );

      this.labelMapping.aggressiveSavingsUsage.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      this.labelMapping.conservativeSavingsUsage.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      this.labelMapping.archiveAverageEuiUsagePerYear.format =
        this.formatSavingsByUnitOfMeasure(this.mappedSummary.unitOfMeasure);

      delete this.mappedSummary.utilityBills;
      delete this.mappedSummary.cbecs;

      this.mappedSummary = this.formatObject(this.mappedSummary);
    },

    formatObject(originalObj) {
      const formattedObj = {};

      for (const key in originalObj) {
        if (this.labelMapping[key]) {
          formattedObj[key] = {
            label: this.labelMapping[key].label,
            value: this.formatValue(
              originalObj[key],
              this.labelMapping[key].format,
              this.labelMapping[key].truncDecimals,
              this.labelMapping[key].roundPercentage
            ),
            group: this.labelMapping[key].group,
          };
        }
      }

      return formattedObj;
    },

    formatValue(value, format, truncDecimals = false, roundPercentage = false) {
      switch (format) {
        case 'datetime':
          return this.dateTimeToUSFormatFactory(value, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });
        case 'currency':
          return `$${this.formatNumberToUS(
            truncDecimals ? Math.trunc(value) : value
          )}`;
        case 'percentage':
          return `${roundPercentage ? value.toFixed(1) : value}%`;
        case 'value':
          return value;
        default:
          return `${this.formatNumberToUS(
            truncDecimals ? Math.trunc(value) : value
          )} ${format}`;
      }
    },

    formatSavingsByUnitOfMeasure(unitOfMeasure) {
      switch (unitOfMeasure) {
        case 'Therms':
          return 'therm/yr';
        case 'KWh':
          return 'kWh/yr';
        default:
          return unitOfMeasure;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.v-tab
    text-transform: none !important
</style>
