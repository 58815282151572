<template>
  <v-card class="rounded-lg" height="100%" :width="cardWidth" elevation="24">
    <!--Card Header-->
    <v-app-bar color="primary" height="88" flat>
      <v-container>
        <v-row>
          <v-col class="d-flex ml-n3 mb-n5" cols="10">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title
                  class="aliceBlue--text font-weight-bold mr-auto text-h5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="white" class="mr-n1 mt-n2" left>
                    mdi-strategy
                  </v-icon>
                  {{ StrategyInfo.name }}
                </v-toolbar-title>
              </template>
              <span>{{ StrategyInfo.name }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-8 mb-n1"
                  color="accentOrange"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ isStrategyDirty ? 'error' : null }}
                </v-icon>
              </template>
              <span>
                {{
                  `You've made changes on ${this.$route.meta.toolTip}'s settings`
                }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex ml-n3 mt-n2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="aliceBlue--text text-caption text-truncate"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ StrategyInfo.description }}
                </span>
              </template>
              <span>{{ StrategyInfo.description }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <!--Card Body-->
    <v-container class="pa-4 rounded-md">
      <!--Settings Card-->
      <v-row>
        <v-col>
          <v-card outlined height="200px" class="pa-4 mb-n2">
            <v-row>
              <v-col>
                <v-alert
                  :color="isActiveObject.color"
                  border="left"
                  elevation="2"
                  colored-border
                  :icon="isActiveObject.icon"
                  class="mb-n2"
                >
                  <span class="primary--text font-weight-bold">
                    {{ isActiveMessage }}
                  </span>
                </v-alert>
              </v-col>
            </v-row>
            <!--Settings-->
            <v-row>
              <v-col>
                <span class="primary--text text-subtitle-1 font-weight-bold">
                  Toggle Active:
                </span>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col>
                <v-switch
                  class="ml-1"
                  :disabled="!userCanUpdate"
                  v-model="StrategyStatus.isActive"
                  :label="isActiveToggleModes[StrategyStatus.isActive]"
                  inset
                />
              </v-col>
            </v-row>
          </v-card>
          <!--Information-->
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <StrategyCardItemsList
                    class="mx-n5 mb-n5"
                    :StrategyInfo="StrategyInfo"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--Set Button-->
      <v-row>
        <v-col cols="6" />
        <v-col cols="6">
          <v-btn
            v-if="userCanUpdate"
            :disabled="!isStrategyDirty"
            :loading="loadingSetButton"
            class="rounded-pill"
            color="accentOrange"
            depressed
            block
            large
            @click="handleSet"
          >
            <div class="subtitle-2 white--text text-truncate font-weight-bold">
              Set
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!--Card Footer-->
    <v-divider />
    <v-card-actions v-if="userCanUpdate">
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteStrategy
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :strategyInfo="StrategyInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-card>
</template>

<script>
import API from '@/Core.Service.Domain/Strategies/Strategies/API/index.js'
import { mapActions, mapGetters } from 'vuex'

const ActionButtonsVue = () =>
  import('@/Core.UI.Domain/Components/ActionButtons')
const ConfirmDialogDeleteStrategy = () =>
  import(
    '@/Core.Service.Domain/Strategies/Strategies/Components/ConfirmDialogDeleteStrategy'
  )
const StrategyCardItemsList = () =>
  import(
    '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyCardItemsList'
  )

export default {
  name: 'StrategyCard',

  components: {
    ActionButtonsVue,
    ConfirmDialogDeleteStrategy,
    StrategyCardItemsList,
  },

  props: {
    StrategyInfo: {
      type: Object,
      required: true,
    },
  },

  data(instance) {
    return {
      loadingSetButton: false,
      showConfirmDialog: false,
      cardWidth: 360,
      isDirty: {
        isActive: false,
      },

      StrategyStatus: {
        isActive: instance.StrategyInfo.isActive,
      },

      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: () => {
            // this route will be replaced when the new edit page is ready
            instance.$router.push({
              name: 'StrategiesEdit',
              params: {
                siteId: instance.StrategyInfo.siteId,
                strategyId: instance.StrategyInfo.id,
              },
            })
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          action: () => {
            instance.showConfirmDialog = true
          },
        },
      ],

      isActiveToggleModes: {
        true: 'Active',
        false: 'Inactive',
      },
    }
  },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
    }),

    isStrategyDirty() {
      return Object.values(this.isDirty).some((value) => value)
    },

    isActiveObject() {
      if (this.StrategyInfo.isActive) {
        return {
          color: 'greenGraph',
          icon: 'mdi-check-circle',
        }
      }
      return {
        color: 'warning',
        icon: 'mdi-alert-octagon',
      }
    },

    isActiveMessage() {
      return `Strategy is currently ${
        this.isActiveToggleModes[this.StrategyInfo.isActive]
      }`
    },

    userCanUpdate() {
      return this.$can('update', 'controls')
    },
  },

  watch: {
    'StrategyStatus.isActive': function (newVal) {
      if (newVal !== this.StrategyInfo.isActive) {
        this.isDirty.isActive = true
      } else this.isDirty.isActive = false
    },
  },

  methods: {
    ...mapActions({
      getStrategiesBySiteId:
        'StrategiesStore/StrategiesStore/getStrategiesBySiteId',
    }),

    async handleSet() {
      if (!this.userCanUpdate) return

      const strategyPayload = {
        name: this.StrategyInfo.name,
        siteId: this.StrategyInfo.siteId,
        isActive: this.StrategyStatus.isActive,
      }
      try {
        this.loadingSetButton = true
        await API.toggleActivateStrategy(strategyPayload)
        this.getStrategiesBySiteId({
          selectedSite: this.defaultSite,
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingSetButton = false
      }
    },
  },
}
</script>
