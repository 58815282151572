<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="analytics-bar d-flex flex-row"
    class="analytics-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <!-- Add Button -->
      <v-btn
        class="rounded-pill mr-3"
        color="primary"
        depressed
        large
        @click="handleAddAnalyticsProject"
      >
        <div class="subtitle-2 white--text text-truncate font-weight-bold">
          {{ $route.meta.buttonLabel }}
        </div>
      </v-btn>
      <!-- Select Site -->
      <v-toolbar dense :width="toolBarWidth">
        <v-autocomplete
          dense
          :items="sitesWithAnalyticsProjects"
          :item-text="item => item.name"
          v-model="selectedSite"
          class="mt-6 mr-3"
          return-object
          @change="setDefaultSiteFromAnalyticsDomain"
        >
          <template v-slot:label>
            <span class="primary--text text-caption font-weight-bold">
              Site
            </span>
          </template>
        </v-autocomplete>
        <v-text-field
          v-model="searchTerm"
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ProjectBar",

  data() {
    return {
      searchTerm: "",
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null
    };
  },

  async created() {
    await this.getSitesWithAnalyticsProjects();
    this.setDefaultSiteFromAnalyticsDomain(this.defaultSite);
  },

  beforeDestroy() {
    this.setAnalyticsProjectsCurrentRoute("");
  },

  computed: {
    ...mapGetters({
      sitesWithAnalyticsProjects:
        "AnalyticsStore/AnalyticsProjectsStore/sitesWithAnalyticsProjects",
      defaultSite: "session/defaultSite"
    }),

    placeholder() {
      return this.$route.meta.placeholder || "Search for a Project";
    }
  },

  watch: {
    $route() {
      this.setDefaultSiteFromAnalyticsDomain(this.defaultSite);
      this.clearSearchTerm();
    }
  },

  methods: {
    ...mapActions({
      getSitesWithAnalyticsProjects:
        "AnalyticsStore/AnalyticsProjectsStore/getSitesWithAnalyticsProjects",
      getAnalyticsProjectsBySiteId:
        "AnalyticsStore/AnalyticsProjectsStore/getAnalyticsProjectsBySiteId",
      setAnalyticsProjectsCurrentRoute:
        "AnalyticsStore/AnalyticsProjectsStore/setAnalyticsProjectsCurrentRoute",
      setDefaultSite: "session/setDefaultSite"
    }),

    ...mapMutations({
      setAnalyticsProjectSearchTerm:
        "AnalyticsStore/AnalyticsProjectsStore/ANALYTICS_PROJECT_SEARCH_TERM"
    }),

    setDefaultSiteFromAnalyticsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesWithAnalyticsProjects[0];
      this.setDefaultSite(this.selectedSite);

      this.getAnalyticsProjectsBySiteId({
        selectedSite: this.selectedSite
      }).then(() => {
        this.setAnalyticsProjectsCurrentRoute(this.$route.name);
        this.clearSearchTerm();
      });
    },

    handleAddAnalyticsProject() {
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({
        name: "AnalyticsMandVProjectNew",
        params: { siteId: this.selectedSite.siteId || this.selectedSite.id }
      });
    },

    handleSearchInput(value) {
      this.setAnalyticsProjectSearchTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = "";
      this.setAnalyticsProjectSearchTerm("");
    }
  }
};
</script>
<style lang="sass" scoped>
.analytics-bar
  position: fixed
  top: 100px
  z-index: 1
</style>