import Kiosk from '@/components/Kiosk/Kiosk.vue';

const routesList = [];

const KioskRoute = {
  path: '/report/:id/:loadTimer?/:kiosk',
  name: 'Kiosk',
  component: Kiosk,
  meta: { allowAnonymous: true },
};

routesList.push(KioskRoute);

export default routesList;