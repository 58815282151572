const MEASURE_SEARCH_TERM = (state, measureSearchTerm) => {
  state.measureSearchTerm = measureSearchTerm;
};

const ANALYTICS_MEASURES_LIST = (state, analyticsMeasuresList) => {
  state.analyticsMeasuresList = analyticsMeasuresList;
};

export default {
  MEASURE_SEARCH_TERM,
  ANALYTICS_MEASURES_LIST,
};
