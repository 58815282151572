import StrategiesPortfolio from '@/Core.Service.Domain/Strategies/Strategies/Views';
import StrategiesListVue from '@/Core.Service.Domain/Strategies/Strategies/Views/StrategiesListView.vue';

// These are old views. They should be refactored to be part of the new Strategies Domain.
const StrategiesCreateRoute = {
  path: 'create-strategy/site/:siteId',
  name: 'StrategiesCreate',
  component: () => import('@/modules/strategies/Create'),
};

const StrategiesEditRoute = {
  path: 'edit-strategy/:strategyId/site/:siteId',
  name: 'StrategiesEdit',
  component: () => import('@/modules/strategies/Edit'),
};
// End of old views.

const StrategiesListRoute = {
  path: 'all',
  name: 'StrategiesList',
  component: StrategiesListVue,
  children: [
    {
      path: 'delete-strategy/:name',
      name: 'DeleteStrategy',
    },
  ],
  meta: {
    label: 'Strategies',
    toolTip: 'Strategy',
    permission: 'strategies:read',
  },
};

export const StrategiesRoute = {
  path: 'strategies',
  name: 'Strategies',
  component: StrategiesPortfolio,
  children: [StrategiesListRoute, StrategiesCreateRoute, StrategiesEditRoute],
  meta: {
    label: 'Strategies',
    toolTip: 'Strategy',
  },
  redirect: { name: 'StrategiesList' },
};
