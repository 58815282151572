<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="notifications-bar d-flex flex-row"
    class="notifications-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <v-btn
        class="rounded-pill mr-3"
        color="primary"
        depressed
        large
        @click="handleBackToPortfolio"
      >
        <div class="subtitle-2 white--text text-truncate font-weight-bold">
          Back to Portfolio
        </div>
      </v-btn>
      <v-toolbar dense :width="toolBarWidth">
        <v-autocomplete
          dense
          :items="sitesList"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          class="mt-6 mr-3"
          return-object
          @change="setDefaultSiteFromNotificationsDomain"
        >
          <template v-slot:label>
            <span class="primary--text text-caption font-weight-bold">
              Site
            </span>
          </template>
        </v-autocomplete>
        <v-text-field
          v-model="searchTerm"
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import API from '@/Core.Service.Domain/ViewSupport/Notifications/API'

export default {
  name: 'NotificationsBar',

  data() {
    return {
      searchTerm: '',
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null,
      sitesList: [],
      siteId: null,
    }
  },

  async created() {
    try {
      this.sitesList = await API.getPortfolioSites()
      if (!this.defaultSite) {
        await this.setDefaultSite(this.sitesList[0])
      }
      this.setDefaultSiteFromNotificationsDomain(this.defaultSite)
    } catch (error) {
      console.error(error)
    }
  },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
    }),

    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Notification'
    },
  },

  watch: {
    $route() {
      this.setDefaultSiteFromNotificationsDomain(this.defaultSite)
      this.clearSearchTerm()
    },
  },

  methods: {
    ...mapActions({
      getNotificationsBySiteId:
        'ViewSupportStore/NotificationsStore/getNotificationsBySiteId',
      setDefaultSite: 'session/setDefaultSite',
    }),

    ...mapMutations({
      setNotificationsSearchTerm:
        'ViewSupportStore/NotificationsStore/NOTIFICATIONS_SEARCH_TERM',
    }),

    setDefaultSiteFromNotificationsDomain(selectedSite) {
      const siteId = selectedSite.siteId || selectedSite.id
      const selectedSiteId = this.$route.params.siteId || siteId

      this.selectedSite = this.sitesList.find(
        (site) => site.siteId === selectedSiteId
      )

      this.getNotificationsBySiteId(siteId).then(() => {
        this.setDefaultSite(this.selectedSite)
        this.clearSearchTerm()
        this.$router
          .push({
            name: 'AllNotifications',
            params: { siteId: this.siteId },
          })
          .catch(() => {})
      })
    },

    handleSearchInput(value) {
      this.setNotificationsSearchTerm(value)
    },

    clearSearchTerm() {
      this.searchTerm = ''
      this.setNotificationsSearchTerm('')
    },

    handleBackToPortfolio() {
      this.$router.push({ name: 'Portfolio' })
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.siteId = to.params.siteId
    next()
  },
}
</script>
<style lang="sass" scoped>
.notifications-bar
  position: fixed
  top: 100px
  z-index: 1
</style>
