import reportGuids from './reportGuids';
import DefaultStrategy from './strategies/defaultStrategy.js';
import CO2Strategy from './strategies/cO2Strategy.js';
import DashboardUtilityDataStrategy from './strategies/dashboardUtilityDataStrategy.js';
import DashboardUtilityElectricityStrategy from './strategies/dashboardUtilityElectricityStrategy.js';
import DashboardSubmeterStrategy from './strategies/dashboardSubmeterStrategy.js';
import EndUseStrategy from './strategies/endUseStrategy';
import HourlyEndUseStrategy from './strategies/hourlyEndUseStrategy.js';
import IAQStrategy from './strategies/IAQStrategy.js';
import MonthlyUtilityStrategy from './strategies/monthlyUtilityStrategy.js';
import PowerQualityStrategy from './strategies/powerQualityStrategy.js';
import SensorStrategy from './strategies/sensorStrategy.js';

const getPowerBiReportStrategy = async function(
  reportName,
  container,
  embedConfiguration
) {
  switch (reportName) {
    case reportGuids.CO2:
      await CO2Strategy.embedAndSlice(container, embedConfiguration);
      break;
    case reportGuids.DashboardUtilityData:
      await DashboardUtilityDataStrategy.embedAndSlice(
        container,
        embedConfiguration,
        reportGuids
      );
      break;
    case reportGuids.DashboardUtilityElectricity:
      await DashboardUtilityElectricityStrategy.embedAndSlice(
        container,
        embedConfiguration,
        reportGuids
      );
      break;
    case reportGuids.DashboardSubmeterV2:
      await DashboardSubmeterStrategy.embedAndSlice(
        container,
        embedConfiguration,
        reportGuids
      );
      break;
    case reportGuids.EndUse:
      await EndUseStrategy.embedAndSlice(container, embedConfiguration);
      break;
    case reportGuids.HourlyEndUse:
      await HourlyEndUseStrategy.embedAndSlice(container, embedConfiguration);
      break;
    case reportGuids.IndoorAirQuality:
      await IAQStrategy.embedAndSlice(container, embedConfiguration);
      break;
    case reportGuids.MonthlyUtility:
      await MonthlyUtilityStrategy.embedAndSlice(container, embedConfiguration);
      break;
    case reportGuids.PowerQuality:
      await PowerQualityStrategy.embedAndSlice(container, embedConfiguration);
      break;
    case reportGuids.Sensor:
      await SensorStrategy.embedAndSlice(container, embedConfiguration);
      break;
    case reportGuids.Benchmark:
    case reportGuids.LoadUsage:
    case reportGuids.NOC:
    case reportGuids.SiteStartTimeAnalytics:
    case reportGuids.TenentBilling:
    case reportGuids.TenantBillingWideFormat:
    default:
      await DefaultStrategy.embedAndSlice(container, embedConfiguration);
      break;
  }
};

export default {
  getPowerBiReportStrategy,
};
