<template>
  <div>
    <v-row
      v-for="row in Math.ceil(getSites.length / cardsPerRow)"
      :key="row"
    >
      <v-col
        v-for="site in getSites.slice((row - 1) * cardsPerRow, row * cardsPerRow)"
        :key="site.siteId"
      >
        <SiteCardVue :siteInfo="site" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

const SiteCardVue = () => import('@/Core.Service.Domain/ViewSupport/Portfolio/Components/SiteCard')

export default {
  name: "PortfolioList",

  components: {
    SiteCardVue,
  },

  props: {
    portfolioList: { type: Array },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'ViewSupportStore/PortfolioStore/getSiteSearchTerm',
    }),

    getSites() {
      return this.filteredItems.length ? sortAlphabetically(this.filteredItems) : sortAlphabetically(this.portfolioList)
    }
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        [
          'streetAddress',
          'name',
        ],
        this.portfolioList
      );
    },
  },

  data() {
    return {
      cardsPerRow: 3,
      filteredItems: [],
    };
  },
};
</script>

<style lang="sass" scoped>
.col
  flex-grow: 0
</style>
