<template>
  <v-container fluid>
    <v-row
      v-for="row in Math.ceil(strategiesTemplatesList.length / cardsPerRow)"
      :key="row"
    >
      <v-col
        v-for="strategy in strategiesTemplatesList.slice((row - 1) * cardsPerRow, row * cardsPerRow)"
        :key="strategy.id"
      >
        <StrategyTemplateCardVue :StrategyInfo="strategy" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StrategyTemplateCardVue from '@/Core.Service.Domain/Strategies/StrategyTemplates/Components/StrategyTemplateCard';
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

export default {
  name: "StrategyTemplatesList",

  components: {
    StrategyTemplateCardVue,
  },

  props: {
    StrategyTemplatesList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'StrategiesStore/StrategyTemplatesStore/getStrategyTemplateSearchTerm',
    }),

    strategiesTemplatesList() {
      return this.filteredItems.length ? sortAlphabetically(this.filteredItems) : sortAlphabetically(this.StrategyTemplatesList);
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        [
          'description',
          'name',
          'executionFrequency',
        ],
        this.StrategyTemplatesList
      );
    },
  },

  data() {
    return {
      filteredItems: [],
      cardsPerRow: 3,
    };
  },
};
</script>

<style lang="sass" scoped>
.col
  flex-grow: 0
</style>
