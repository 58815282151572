// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.

const SubMeterManualmonitorIndexRoute = {
  path: '/monitors/manual-utility-monitors',
  name: 'SubMeterManualMonitorIndex',
  component: () =>
    import('@/modules/monitors/manual_monitors/_components/Index'),
  meta: { permission: 'meters:read' },
};

const SubMeterManualmonitorCreateRoute = {
  path: '/monitors/manual-utility-monitors/create/:id',
  name: 'SubMeterManualMonitorCreate',
  component: () =>
    import('@/modules/monitors/manual_monitors/_components/Create'),
  meta: { permission: 'meters:create' },
};

const SubMeterManualmonitorEditRoute = {
  path: '/monitors/manual-utility-monitors/site/:siteId/edit/:manualMonitorId',
  name: 'SubMeterManualMonitorEdit',
  component: () =>
    import('@/modules/monitors/manual_monitors/_components/Edit'),
  meta: { permission: 'meters:update' },
};

const SubMeterManualmonitorUploadRoute = {
  path: '/manual-utility-monitor/site/:siteId/manual-utility-upload',
  name: 'SubMeterManualmonitorUpload',
  component: () => import('@/modules/ManualUtilityUpload'),
  meta: { permission: 'meters:update' },
};

export default [
  SubMeterManualmonitorIndexRoute,
  SubMeterManualmonitorCreateRoute,
  SubMeterManualmonitorEditRoute,
  SubMeterManualmonitorUploadRoute,
];
